import { filter } from 'lodash'
import {
  LegalMatterNoteFragment,
  NoteConnection,
} from '../@types/generated/graphql'
import {
  CreateNoteMutation,
  DeleteNoteMutation,
  UpdateNoteMutation,
} from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { edgesInclude, getCacheId, makeEmptyData } from 'utils/useApolloClient'
import { AsStoreObject } from '@apollo/client/utilities'

const createNoteMutation = gql(/* GraphQL */ `
  mutation createNote(
    $legalMatterKey: Key!
    $text: String!
    $createdByKey: Key!
  ) {
    noteCreate(
      legalMatterKey: $legalMatterKey
      text: $text
      createdByKey: $createdByKey
    ) {
      ... on NoteMutationResponse {
        code
        message
        success
        note {
          ...LegalMatterNote
        }
      }
      ... on MutationError {
        code
        message
        success
        code
        userErrors {
          fieldErrors {
            name
            errors {
              message
              code
            }
          }
        }
      }
    }
  }
`)

const updateNoteMutation = gql(/* GraphQL */ `
  mutation updateNote($noteKey: Key!, $text: String!) {
    noteUpdate(noteKey: $noteKey, text: $text) {
      ... on NoteMutationResponse {
        code
        success
        message
        note {
          ...LegalMatterNote
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          fieldErrors {
            errors {
              code
              message
            }
            name
          }
          code
        }
      }
    }
  }
`)

const deleteNoteMutation = gql(`
  mutation deleteNote($noteKey: Key!) {
    noteDelete(noteKey: $noteKey) {
      code
      message
      objectRef
      success
    }
  }
`)

interface NotesHook {
  createNote: (
    mutationOpts:
      | MutationFunctionOptions<
          CreateNoteMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CreateNoteMutation>>
  deleteNote: (
    mutationOpts:
      | MutationFunctionOptions<
          DeleteNoteMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<DeleteNoteMutation>>
  updateNote: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateNoteMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateNoteMutation>>
  noteLoading: boolean
  noteError: any
}

const useNotes = (legalMatterKey: string | undefined): NotesHook => {
  const [createNote, { loading: creating, error: createError }] =
    useMutation<CreateNoteMutation>(createNoteMutation, {
      update(cache, { data }) {
        if (
          legalMatterKey != null &&
          data?.noteCreate?.__typename === 'NoteMutationResponse'
        ) {
          const note: LegalMatterNoteFragment = data.noteCreate.note
          cache.modify({
            id: getCacheId('LegalMatter', legalMatterKey),
            fields: {
              notes(existingNoteConnection = makeEmptyData(), { toReference }) {
                const newNoteRef = toReference(note)
                if (!edgesInclude(existingNoteConnection.edges, newNoteRef)) {
                  return {
                    ...existingNoteConnection,
                    edges: [
                      ...existingNoteConnection.edges,
                      { __typename: 'NoteEdge', node: newNoteRef },
                    ],
                  } as AsStoreObject<NoteConnection>
                } else {
                  return existingNoteConnection
                }
              },
            },
          })
        }
      },
    })

  const [updateNote, { loading: updating, error: updateError }] =
    useMutation<UpdateNoteMutation>(updateNoteMutation)

  const [deleteNote, { loading: deleting, error: deleteError }] =
    useMutation<DeleteNoteMutation>(deleteNoteMutation, {
      update(cache, { data }) {
        if (legalMatterKey != null && data?.noteDelete.success) {
          cache.modify({
            id: getCacheId('LegalMatter', legalMatterKey),
            fields: {
              notes(existingNoteConnection = {}) {
                return {
                  ...existingNoteConnection,
                  edges: filter(
                    existingNoteConnection.edges,
                    (edge) =>
                      edge.node.__ref !=
                      getCacheId('Note', data.noteDelete.objectRef),
                  ),
                } as NoteConnection
              },
            },
          })
          cache.evict({ id: getCacheId('Note', data.noteDelete.objectRef) })
        }
      },
    })

  const noteLoading = creating || deleting || updating
  const noteError = createError ?? deleteError ?? updateError

  return {
    createNote,
    deleteNote,
    updateNote,
    noteLoading,
    noteError,
  }
}

export default useNotes
