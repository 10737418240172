import React from 'react'
import Grid from '@mui/material/Grid'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import ScheduleIcon from '@mui/icons-material/Schedule'
import styles from './EventDateTime.module.css'

interface Event {
  startTime?: Date
  endTime?: Date
  onChangeStartTime?: (value: Date) => void
  onChangeEndTime?: (value: Date) => void
}

const EventDateTime = ({
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
}: Event): JSX.Element => {
  const readOnly =
    onChangeStartTime === undefined && onChangeEndTime === undefined
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        data-testid="datetimepicker-box"
        sx={{ marginBottom: '8px' }}
      >
        <Grid item className={styles.timePicker}>
          <ScheduleIcon aria-label="Schedule" />
          <MobileDateTimePicker
            value={startTime}
            minutesStep={5}
            readOnly={readOnly}
            onChange={(value) => value != null && onChangeStartTime?.(value)}
            timeIcon={<ScheduleIcon />}
            data-testid="datetimepicker-starttime-wrapper"
            renderInput={({ inputRef, inputProps }) => (
              <input
                id="startTime"
                ref={inputRef}
                {...inputProps}
                className={styles.timeInput}
                role="input"
                aria-label="Start Time Input"
                data-testid="datetimepicker-starttime-picker"
              />
            )}
          />
        </Grid>
        {endTime !== undefined && (
          <>
            <Grid item mx={1}>
              {' '}
              -{' '}
            </Grid>
            <Grid item className={styles.timePicker}>
              <ScheduleIcon aria-label="Schedule" />
              <MobileDateTimePicker
                value={endTime}
                minutesStep={5}
                readOnly={readOnly}
                onChange={(value) => {
                  value != null && onChangeEndTime?.(value)
                }}
                renderInput={({ inputRef, inputProps }) => (
                  <input
                    id="endTime"
                    ref={inputRef}
                    {...inputProps}
                    className={styles.timeInput}
                    role="input"
                    aria-label="End Time Input"
                    data-testid="datetimepicker-endtime-picker"
                  />
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default EventDateTime
