import { useContext, useState, useEffect } from 'react'
import log from 'loglevel'
import isEqual from 'lodash/isEqual'
import { enqueueSnackbar } from 'notistack'
import { routes } from 'routes'
import {
  useCreateCheckoutSession,
  useExtraProductCheckout,
} from 'utils/useCheckout'
import useCart from 'utils/useCart'
import {
  useProducts,
  sortedByParent,
  orderProducts,
  type Product,
} from 'utils/useProducts'
import { useSubscribedProducts } from 'utils/useSubscribedProducts'
import {
  type Price,
  type ProductInterval,
} from '../../../@types/generated/graphql'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import { CheckoutPageSkeleton } from 'view/components/molecules/LoadingSkeletons'
import CheckoutBar from 'view/components/organisms/CheckoutBar'
import ContentAside from 'view/components/organisms/ContentAside'
import SelectableListItem from 'view/components/molecules/SelectableListItem'
import {
  StoryblokComponent,
  StoryblokRichText,
} from 'view/components/storyblok/StoryblokRenderers'
import { priceCaption } from 'utils/StringFormatter'
import Grid from '@mui/material/Grid'
import { gql, useQuery } from '@apollo/client'

const supportedStatesQuery = gql(/* GraphQL */ `
  query checkoutSupportedStates {
    supportedStates {
      stateCode
      stateName
    }
  }
`)

const OtherCheckoutPage = (): JSX.Element => {
  const { user } = useContext(UserProfileContext)
  const [isUserOnSupportedState, setIsUserOnSupportedState] =
    useState<boolean>(true)

  const { data, loading: statesLoading } = useQuery(supportedStatesQuery)
  useEffect(() => {
    if (!statesLoading && data != null) {
      const isSupportedState = data.supportedStates.some(
        (state) => state.stateCode === user.profile.location.state.code,
      )
      setIsUserOnSupportedState(isSupportedState)
    }
  }, [statesLoading, data, user])

  const [checkoutSession, { loading: creatingCheckoutSession }] =
    useCreateCheckoutSession()
  const [checkoutExtraProducts, { loading: processingExtraProducts }] =
    useExtraProductCheckout()
  const { products: productCatalog, productsLoading } = useProducts()
  const [asideProduct, setAsideProduct] = useState<Product | null>(null)
  const [asideSection, setAsideSection] = useState<string>('Service Terms')

  const { subscribedProductsLoading, subscribedProducts } =
    useSubscribedProducts(user.key)
  const {
    cartProducts,
    cartTotals,
    termsToAccept,
    isItemChecked,
    isItemDisabled,
    acceptProductTerms,
    rejectProductTerms,
    toggleCartItem,
  } = useCart({
    productCatalog,
    subscribedProducts,
  })

  const handleItemClick = (productId: string): void => {
    toggleCartItem(productId)
  }

  const handleLinkItemClick = (linkName: string, productId: string): void => {
    setAsideProduct(productCatalog.find((p) => p.id === productId) ?? null)
    setAsideSection(linkName)
  }

  const handleAcceptTerms = (productId: string | undefined): void => {
    if (productId == null) return
    acceptProductTerms(productId)
    setAsideProduct(null) // Close the aside
  }

  const handleCloseTerms = (productId: string | undefined): void => {
    if (productId == null) return
    if (termsToAccept.length > 0) {
      if (termsToAccept.includes(productId)) {
        rejectProductTerms(productId)
        enqueueSnackbar(
          'You must accept the terms of service to add a service to the cart',
          { variant: 'error' },
        )
      }
      setAsideProduct(null) // Close the aside
    }
  }

  const handleCheckout = (interval: ProductInterval): void => {
    const prices: Price[] = []
    const stripePrices: string[] = []
    cartProducts.forEach((product) => {
      const price = product.prices.find((p) => p.recurringInterval === interval)
      if (price != null) {
        prices.push(price)
        stripePrices.push(price.id)
      }
    })

    const totalPrice = prices.reduce((acc, price) => acc + price.unitAmount, 0)

    if (
      totalPrice !== cartTotals[interval] ||
      stripePrices.length !== cartProducts.length
    ) {
      enqueueSnackbar(
        'An error occurred, please reload and add your products  to the cart again.',
        {
          variant: 'error',
        },
      )
      return
    }

    // Two kinds of checkout are possible:
    // 1. If the user has a subscription, we can add the products to the subscription
    // 2. If the user does not have a subscription, we can create a new subscription redirecting
    //   to the Stripe portal using a billing portal session

    if (subscribedProducts.length > 0) {
      // Add the products to the subscription
      void checkoutExtraProducts({
        variables: { stripePrices },
        onCompleted: (data) => {
          if (
            data.subscriptionProductsAdd.__typename ===
            'SubscriptionProductAddMutationResponse'
          ) {
            window.location.href = routes.checkoutSuccess.path
            return
          }
          enqueueSnackbar(
            'An error has occured checking out the products. Please try again in a few seconds.',
            { variant: 'error' },
          )
          log.error('Error processing checkout', data.subscriptionProductsAdd)
        },
        onError: (error) => {
          enqueueSnackbar(
            'An error has occured checking out the products. Please try again in a few seconds.',
            { variant: 'error' },
          )
          log.error('Error processing checkout', error)
        },
      })
    } else {
      const successUrl = new URL(
        routes.checkoutSuccess.path,
        window.location.href,
      )
      const cancelUrl = new URL(
        routes.checkoutFailure.path,
        window.location.href,
      )
      void checkoutSession({
        variables: {
          successUrl: successUrl.toString(),
          cancelUrl: cancelUrl.toString(),
          stripePrices,
        },
        onCompleted: (data) => {
          if (
            data.checkoutSessionCreate.__typename === 'SessionUrlResponse' &&
            data.checkoutSessionCreate.sessionUrl != null
          ) {
            window.location.href = data.checkoutSessionCreate.sessionUrl
          }
        },
        onError: () => {
          enqueueSnackbar(
            'An error occurred while creating the billing portal session. Please try again.',
            {
              variant: 'error',
            },
          )
        },
      })
    }
  }

  const loading = productsLoading || subscribedProductsLoading

  if (loading) {
    return <CheckoutPageSkeleton />
  }

  if (processingExtraProducts) {
    ;<Alert severity="info">
      You will be redirected to Stripe. Processing your checkout...
    </Alert>
  }

  if (termsToAccept.length > 0 && asideProduct == null) {
    // Open the aside with the product first added
    const prod = productCatalog.find((p) => p.id === termsToAccept[0]) ?? null
    if (!isEqual(asideProduct, prod)) {
      setAsideProduct(prod)
    }
  }

  const orderedProducts = orderProducts(sortedByParent(productCatalog))

  return (
    <Box>
      <Box>
        <Alert severity="info">
          <Typography variant="h5" gutterBottom>
            Important Information
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please review the Terms of Service for each legal plan or supplement
            when adding it to your cart. You must accept the Terms of Service to
            complete the purchase of a legal plan or supplement.
          </Typography>
        </Alert>
        {isUserOnSupportedState != null && !isUserOnSupportedState && (
          <>
            <Alert severity="warning" icon={false} sx={{ mb: 3 }} role="alert">
              <Typography variant="h5" gutterBottom>
                LegalFix is currently available in only a limited number of
                states. It appears that you are located in a state where we do
                not yet offer our products. As a result, you will not be able to
                make a purchase at this time. Please reach out to the LegalFix
                team if you have any questions about when we might be available
                in your state.
              </Typography>
            </Alert>
          </>
        )}
      </Box>
      <Stack
        spacing={0}
        direction={{ xs: 'column', md: 'column' }}
        sx={{ width: '100%' }}
      >
        <Box sx={{ minWidth: '50%' }}>
          {orderedProducts.map((product) => (
            <SelectableListItem
              key={product.id}
              uuid={product.id}
              title={product.name}
              caption={priceCaption(product.prices)}
              selected={isItemChecked(product.id)}
              disabled={isItemDisabled(product.id)}
              onItemClick={handleItemClick}
              onLinkItemClick={handleLinkItemClick}
              linkItems={['Benefit Overview', 'Service Terms', 'Services']}
              children={product.children.map((subProduct) => (
                <SelectableListItem
                  key={subProduct.id}
                  uuid={subProduct.id}
                  title={subProduct.name}
                  caption={priceCaption(subProduct.prices)}
                  selected={isItemChecked(subProduct.id)}
                  disabled={isItemDisabled(subProduct.id)}
                  onItemClick={handleItemClick}
                  onLinkItemClick={handleLinkItemClick}
                  titleTypographyProps={{ variant: 'h6' }}
                  linkItems={['Benefit Overview', 'Service Terms', 'Services']}
                />
              ))}
            />
          ))}
        </Box>
        <Grid item sx={{ width: '100%' }}>
          <CheckoutBar
            cartTotals={cartTotals}
            onOptionSelected={handleCheckout}
            isUserOnSupportedState={isUserOnSupportedState}
            requestInProgress={
              creatingCheckoutSession || processingExtraProducts
            }
          />
        </Grid>
      </Stack>
      <ContentAside
        open={asideProduct != null}
        onClose={() => {
          setAsideProduct(null)
        }}
        openSection={asideSection}
        onSectionMenuClick={(section) => {
          setAsideSection(section)
        }}
        sections={{
          'Benefit Overview': {
            content: (
              <StoryblokRichText
                document={asideProduct?.storyblok?.content?.summary}
              />
            ),
            actionButtons:
              asideProduct?.id != null &&
              termsToAccept.includes(asideProduct.id)
                ? [
                    <Typography
                      key="text"
                      variant="h6"
                      gutterBottom
                      sx={{ color: 'white' }}
                    >
                      {asideProduct?.name}
                    </Typography>,
                    <Button
                      key="go-back"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setAsideSection('Service Terms')
                      }}
                    >
                      Go back to Terms
                    </Button>,
                  ]
                : undefined,
          },
          'Service Terms': {
            content: (
              <StoryblokRichText
                document={asideProduct?.storyblok?.content?.full_document}
              />
            ),
            actionButtons:
              asideProduct?.id != null &&
              termsToAccept.includes(asideProduct.id)
                ? [
                    <Typography
                      key="text"
                      variant="h6"
                      gutterBottom
                      sx={{
                        color: 'white',
                        display: {
                          xs: 'none',
                          md: 'block',
                        },
                      }}
                    >
                      {asideProduct?.name}
                    </Typography>,
                    <Button
                      key="accept"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        handleAcceptTerms(asideProduct?.id)
                      }}
                    >
                      Accept Terms
                    </Button>,
                    <Button
                      key="close"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        handleCloseTerms(asideProduct?.id)
                      }}
                    >
                      Close
                    </Button>,
                  ]
                : undefined,
          },
          Services: {
            content: (
              <StoryblokComponent
                props={asideProduct?.storyblok?.content?.services}
              />
            ),
            actionButtons:
              asideProduct?.id != null &&
              termsToAccept.includes(asideProduct.id)
                ? [
                    <Typography
                      key="text"
                      variant="h6"
                      gutterBottom
                      sx={{ color: 'white' }}
                    >
                      {asideProduct?.name}
                    </Typography>,
                    <Button
                      key="go-back"
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setAsideSection('Service Terms')
                      }}
                    >
                      Go back to Terms
                    </Button>,
                  ]
                : undefined,
          },
        }}
        tasks={termsToAccept
          .filter((productId) => productId !== asideProduct?.id)
          .map((productId) => {
            const product = productCatalog.find((p) => p.id === productId)
            return (
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="h5" gutterBottom sx={{ color: 'white' }}>
                  Please accept the terms of the {product?.name}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    if (product != null) {
                      setAsideProduct(product)
                      setAsideSection('Service Terms')
                    }
                  }}
                >
                  Go to the terms
                </Button>
              </Stack>
            )
          })}
      />
    </Box>
  )
}

export default OtherCheckoutPage
