import React from 'react'
import useFirebaseAuth from 'utils/useFirebaseAuth'
import useUserProfile from 'utils/useUserProfile'
import { type CurrentUser, defaultUser } from 'utils/useUserProfile'

export const UserProfileContext = React.createContext<CurrentUser>({
  user: defaultUser,
  loading: true,
})

const UserProfileProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const auth = useFirebaseAuth()
  const currentUser = useUserProfile({ auth })
  return (
    <UserProfileContext.Provider value={currentUser}>
      {children}
    </UserProfileContext.Provider>
  )
}

export default UserProfileProvider
