import React, { Children, isValidElement } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { type PageInfo } from '../../../../src/@types/generated/graphql'

export const paginationLimits = {
  legalMatters: 10,
  tasks: 5,
  calendarEvents: 5,
  documents: 20,
  notes: 10,
}

interface PaginateItemsProps {
  children: React.ReactNode
  pageInfo: PageInfo
  onClickPrev: () => void
  onClickNext: () => void
}
const PaginateItems = ({
  children,
  pageInfo,
  onClickPrev,
  onClickNext,
}: PaginateItemsProps): JSX.Element => {
  return (
    <div>
      {Children.map(children, (item, idx) => {
        if (!isValidElement(item)) {
          return <div key={idx}>{item}</div>
        }

        if (item.props !== undefined) {
          return <div key={idx}>{item}</div>
        }
      })}

      <Grid container justifyContent="space-between">
        <Grid item>
          <Button
            variant="contained"
            onClick={onClickPrev}
            disabled={!pageInfo.hasPreviousPage}
          >
            Previous
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={onClickNext}
            disabled={!pageInfo.hasNextPage}
          >
            Next
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default PaginateItems
