import React, { useState } from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
interface LawyerNotesFormProps {
  noteMaxLenght: number
  onSubmit: (noteData: { noteContent: string; creationDate: string }) => void
  isReadOnly: boolean
}

const LawyerNotesForm = ({
  onSubmit,
  noteMaxLenght,
  isReadOnly,
}: LawyerNotesFormProps): JSX.Element => {
  const [noteTextValue, setNoteTextValue] = useState('')
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [error, setError] = useState(false)

  const handleNoteChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newText = event.target.value
    setNoteTextValue(newText)
    setError(newText.length > noteMaxLenght)
    setIsButtonDisabled(newText.length > noteMaxLenght)
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    onSubmit({
      noteContent: noteTextValue,
      creationDate: new Date().toISOString(),
    })
    setNoteTextValue('')
    setIsButtonDisabled(true)
  }

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h5" mb={2} data-testid="lawyerNotesForm-title">
        Notes
      </Typography>
      <Typography
        mb={2}
        data-testid="lawyerNotesForm-description"
        variant="subtitle2"
      >
        These notes are not visible to the client
      </Typography>
      <TextField
        value={noteTextValue}
        onChange={handleNoteChange}
        disabled={isReadOnly}
        error={error}
        helperText={
          error ? `Maximum length is ${noteMaxLenght} characters` : ''
        }
        variant="outlined"
        multiline
        rows={3}
        fullWidth
        data-testid="lawyerNotesForm-input"
        aria-label="Note Content Input"
      />
      <Button
        variant="contained"
        disabled={isButtonDisabled || error}
        color="primary"
        type="submit"
        role="button"
        data-testid="lawyerNotesForm-submitBtn"
        sx={{ marginBottom: 2, marginTop: 2 }}
      >
        Submit
      </Button>
    </form>
  )
}

export default LawyerNotesForm
