import {
  CreateSubscriberUserMutation,
  UpdateSubscriberUserMutation,
} from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'

const createSubscriberUserMutation = gql(/* GraphQL */ `
  mutation createSubscriberUser($data: [JSON!]!) {
    subscriberCreate(data: $data) {
      ... on SubscriberUserMutationResponse {
        code
        message
        success
        user {
          key
          firstName
          middleName
          lastName
          gender
          dateOfBirth
          email
          location {
            state {
              code
              name
            }
            city
            zipCode
          }
          phone
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const updateSubscriberUserMutation = gql(/* GraphQL */ `
  mutation updateSubscriberUser($subscriberKey: Key!, $data: [JSON!]!) {
    subscriberUpdate(subscriberKey: $subscriberKey, data: $data) {
      ... on SubscriberUserMutationResponse {
        code
        success
        message
        user {
          email
          firstName
          gender
          key
          lastName
          location {
            state {
              name
            }
            city
            street
            zipCode
          }
          middleName
          phone
          subscriptionActive
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

interface SubscriberUserHook {
  createSubscriberUser: (
    mutationOpts:
      | MutationFunctionOptions<
          CreateSubscriberUserMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CreateSubscriberUserMutation>>
  updateSubscriberUser: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateSubscriberUserMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateSubscriberUserMutation>>
  subscriberUserLoading: boolean
  subscriberUserError: any
}

const useSubscriberUser = (): SubscriberUserHook => {
  const [createSubscriberUser, { loading: createLoading, error: createError }] =
    useMutation<CreateSubscriberUserMutation>(createSubscriberUserMutation, {
      awaitRefetchQueries: true,
      refetchQueries: ['Me'],
    })

  const [updateSubscriberUser, { loading: updateLoading, error: updateError }] =
    useMutation<UpdateSubscriberUserMutation>(updateSubscriberUserMutation, {
      awaitRefetchQueries: true,
      refetchQueries: ['Me'],
    })

  const subscriberUserLoading = createLoading || updateLoading
  const subscriberUserError = createError ?? updateError

  return {
    createSubscriberUser,
    updateSubscriberUser,
    subscriberUserLoading,
    subscriberUserError,
  }
}

export default useSubscriberUser
