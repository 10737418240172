import { useApolloClient } from '@apollo/client'
import { type NotificationType } from '../../@types/Notification'
import { type DocumentNode } from 'graphql'
import React, { useContext, useEffect } from 'react'
import { UserProfileContext } from './UserProfileProvider'
import { set, has, unset } from 'lodash'
import {
  Timestamp,
  collection,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { firestoreDb, refreshUserToken } from 'firebaseConfig'
import log from 'loglevel'

interface Subscription {
  componentIdentifier: string
  events: NotificationType[]
  queries: DocumentNode[]
  eventHandler: (message: string) => void
}

type SubscriptionMap = {
  [key in NotificationType]?: Subscription
}

interface EventProviderContract {
  registerEvents: (
    componentIdentifier: string,
    events: NotificationType[],
    queries: DocumentNode[],
    eventHandler: (message: string) => void,
  ) => void
  unregisterEvents: (componentIdentifier: string) => void
}

export const EventProviderContext = React.createContext<EventProviderContract>({
  registerEvents: () => undefined,
  unregisterEvents: () => undefined,
})

const subscriptionRegistry: SubscriptionMap = {}

const EventRegistryProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const apolloClient = useApolloClient()
  const { user } = useContext(UserProfileContext)

  const registerEvents = (
    componentIdentifier: string,
    events: NotificationType[],
    queries: DocumentNode[],
    eventHandler: (message: string) => void,
  ): void => {
    if (has(subscriptionRegistry, componentIdentifier)) return

    set(subscriptionRegistry, componentIdentifier, {
      componentIdentifier,
      events,
      queries,
      eventHandler,
    })
  }

  const unregisterEvents = (componentIdentifier: string): void => {
    unset(subscriptionRegistry, componentIdentifier)
  }

  useEffect(() => {
    if (user.profile?.aclIdentities == null) return
    const timestamp = Timestamp.fromDate(new Date())
    const fbQuery = query(
      collection(firestoreDb, 'notifications'),
      where(
        'acl.entity_list',
        'array-contains-any',
        user.profile.aclIdentities,
      ),
      where('created_timestamp', '>=', timestamp),
    )

    const unsubscribeFromFirestoreEvents = onSnapshot(
      fbQuery,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const data = change.doc.data()
            const eventType = data.type
            Object.values(subscriptionRegistry).forEach((subscription) => {
              if (eventType === 'UserClaimsUpdated') {
                void refreshUserToken().then(() => {
                  log.info('User claims updated')
                })
              }
              if (subscription?.events.includes(eventType)) {
                void apolloClient
                  .refetchQueries({
                    include: subscription.queries,
                  })
                  .then(() => {
                    subscription.eventHandler(eventType)
                  })
              }
            })
          }
        })
      },
      (error) => {
        log.error('firestore database fetch error: ', error)
      },
    )
    return () => {
      unsubscribeFromFirestoreEvents()
    }
  }, [apolloClient, user.profile?.aclIdentities])

  return (
    <EventProviderContext.Provider value={{ registerEvents, unregisterEvents }}>
      {children}
    </EventProviderContext.Provider>
  )
}

export default EventRegistryProvider
