import { DeleteDocumentMutation } from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'

const deleteDocumentMutation = gql(`
  mutation deleteDocument($documentKey: Key!) {
    legalMatterDocumentDelete(documentKey: $documentKey) {
      ... on DeleteResponse {
        code
        success
        message
        objectRef
      }
    }
  }
`)

interface DocumentsHook {
  deleteDocument: (
    mutationOpts:
      | MutationFunctionOptions<
          DeleteDocumentMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<DeleteDocumentMutation>>
  documentLoading: boolean
  documentError: any
}

const useDocuments = (): DocumentsHook => {
  const [deleteDocument, { loading: deleting, error: deleteError }] =
    useMutation<DeleteDocumentMutation>(deleteDocumentMutation)

  const documentLoading = deleting
  const documentError = deleteError

  return {
    deleteDocument,
    documentLoading,
    documentError,
  }
}

export default useDocuments
