import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import { useState, useEffect } from 'react'

export interface SelectedFirmUser {
  key: string
  fullName?: string
  firstName: string
  lastName: string
  checked: boolean
  disabled: boolean
}

export interface updatedUsersIdentity {
  key: string
  roles: string[]
}

interface UserPickerProps {
  users: SelectedFirmUser[]
  onSelectionChange: (updatedUsers: updatedUsersIdentity[]) => void
}

const UserPicker = ({
  users,
  onSelectionChange,
}: UserPickerProps): JSX.Element => {
  const [selectedUserKeys, setSelectedUserKeys] = useState<string[]>(() =>
    users.filter((user) => user.checked).map((user) => user.key),
  )

  const addedUsers = users
    .filter((user) => selectedUserKeys.includes(user.key) && !user.checked)
    .map((user) => ({
      key: user.key,
      roles: ['legalMatters.collaborator'],
    }))

  const removedUsers = users
    .filter((user) => !selectedUserKeys.includes(user.key) && user.checked)
    .map((user) => ({
      key: user.key,
      roles: [],
    }))

  const updatedUsers: updatedUsersIdentity[] = addedUsers.concat(removedUsers)

  useEffect(() => {
    onSelectionChange(updatedUsers)
  }, [selectedUserKeys, updatedUsers, onSelectionChange])

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    setSelectedUserKeys(value as string[])
  }

  return (
    <div>
      <FormControl sx={{ my: 2, width: '100%' }}>
        <InputLabel id="userselect-multiple-checkbox-label">Lawyer</InputLabel>
        <Select
          labelId="userselect-multiple-checkbox-label"
          id="userselect-multiple-checkbox"
          multiple
          value={selectedUserKeys}
          onChange={handleChange}
          input={<OutlinedInput label="User" />}
          renderValue={(selected) =>
            selected
              .map((key) => {
                const lawyer = users.find((lawyer) => lawyer.key === key)
                let lawyerName
                if (lawyer != undefined) {
                  lawyerName = `${lawyer.firstName} ${lawyer.lastName}`
                  if (lawyer?.fullName) {
                    lawyerName = lawyer?.fullName
                  }
                }
                return lawyerName
              })
              .filter((name) => name !== undefined)
              .join(', ')
          }
        >
          {users.map((user) => (
            <MenuItem key={user.key} value={user.key} disabled={user.disabled}>
              <Checkbox
                checked={selectedUserKeys.includes(user.key)}
                disabled={user.disabled}
              />
              <ListItemText
                primary={
                  user.fullName
                    ? user.fullName
                    : `${user.firstName} ${user.lastName}`
                }
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default UserPicker
