import { SaveIntakeDataMutation } from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'

const saveIntakeDataMutation = gql(/* GraphQL */ `
  mutation saveIntakeData($formType: JSONFormType!, $data: [JSON!]!) {
    jsonFormSave(formType: $formType, data: $data) {
      ... on JsonFormMutationResponse {
        code
        success
        message
        formData {
          createdTimestamp
          formVersion
          key
          lastUpdatedTimestamp
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

interface IntakeHook {
  saveIntakeData: (
    mutationOpts:
      | MutationFunctionOptions<
          SaveIntakeDataMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<SaveIntakeDataMutation>>
  intakeLoading: boolean
  intakeError: any
}

const useIntakeForm = (): IntakeHook => {
  const [saveIntakeData, { loading: intakeLoading, error: intakeError }] =
    useMutation<SaveIntakeDataMutation>(saveIntakeDataMutation)

  return {
    saveIntakeData,
    intakeLoading,
    intakeError,
  }
}

export default useIntakeForm
