export enum AuthResponseStatus {
  successful,
  emailAlreadyExists,
  wrongPassword,
  invalidEmail,
  userNotFound,
  userDisabled,
  operationNotAllowed,
  tooManyRequests,
  popUpBlocked,
  popUpClosedByUser,
  authTimeOut,
  userTokenExpired,
  undefined,
  invalidCredentials,
}

export interface AuthResponse {
  status: AuthResponseStatus
  message: string
}
