import { gql, useQuery } from '@apollo/client'
import {
  type LegalPlansProductItemsQueryVariables,
  type UseProductsProductFragment,
} from '../@types/generated/graphql'
import keyBy from 'lodash/keyBy'
import log from 'loglevel'

const legalPlansProductsQuery = gql(/* GraphQL */ `
  fragment UseProductsProduct on Product {
    id
    name
    kind
    livemode
    metadata
    subproducts
    prices {
      id
      lookupKey
      recurringInterval
      unitAmount
    }
    name
    storyblok {
      content {
        full_document
        services
        summary
      }
    }
  }

  query LegalPlansProductItems {
    products {
      ...UseProductsProduct
    }
  }
`)

export type Product = UseProductsProductFragment

export type ProductWithSubproducts = Product & {
  children: Product[]
}

export const sortedByParent = (
  products: Product[],
): ProductWithSubproducts[] => {
  const productMap = keyBy(products, 'id')
  const childProducts = {}
  const rootProducts: ProductWithSubproducts[] = []
  products.forEach((product) => {
    const children =
      product?.subproducts != null
        ? product.subproducts.map((subProductId: string) => {
            childProducts[subProductId] = true
            return {
              ...productMap[subProductId],
            } satisfies Product
          })
        : []
    rootProducts.push({ ...product, children })
  })
  return rootProducts.filter((product) => childProducts[product.id] == null)
}

export const orderProducts = (
  products: Product[],
): ProductWithSubproducts[] => {
  const sortedProducts = products
    .map((product) => {
      const productWithChildren = product as ProductWithSubproducts
      if (!productWithChildren.children) {
        productWithChildren.children = []
      }
      return productWithChildren
    })
    .sort((a, b) => {
      const aOrder = JSON.parse(a.metadata).order
      const bOrder = JSON.parse(b.metadata).order

      if (aOrder === null || aOrder === undefined) return 1
      if (bOrder === null || bOrder === undefined) return -1

      return parseInt(aOrder, 10) - parseInt(bOrder, 10)
    })
  return sortedProducts
}

interface UseProductsReturnType {
  products: Product[]
  productsLoading: boolean
}

export const useProducts = (
  options?: LegalPlansProductItemsQueryVariables | undefined,
): UseProductsReturnType => {
  const { data, loading: productsLoading } = useQuery(legalPlansProductsQuery, {
    ...options,
    fetchPolicy: 'no-cache',
  })

  const products = data?.products ?? []

  return {
    productsLoading,
    products,
  }
}
