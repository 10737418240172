import React from 'react'
import Grid from '@mui/material/Grid'

interface Props {
  children: React.ReactNode[]
}

const TwoColumnLayout = ({ children }: Props): JSX.Element => {
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        {children[0]}
      </Grid>
      <Grid item md={9}>
        {children[1]}
      </Grid>
    </Grid>
  )
}

export default TwoColumnLayout
