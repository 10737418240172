import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'

interface Props {
  open: boolean
  content: string
  onCloseAlert: () => void
}

const ConfirmationAlert = ({
  open,
  content,
  onCloseAlert,
}: Props): JSX.Element => {
  return (
    <Collapse in={open}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onCloseAlert}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
        data-testid="confirmation-alert"
      >
        {content}
      </Alert>
    </Collapse>
  )
}

export default ConfirmationAlert
