import { filter } from 'lodash'
import {
  CalendarEventConnection,
  CreateEventMutation,
  DeleteEventMutation,
  LegalMatterCalendarEventFragment,
  UpdateEventMutation,
} from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import { edgesInclude, getCacheId, makeEmptyData } from './useApolloClient'
import { AsStoreObject } from '@apollo/client/utilities'

const createCalendarEventMutation = gql(/* GraphQL */ `
  mutation createEvent(
    $legalMatterKey: Key!
    $participants: [Key!]!
    $name: String!
    $description: String
    $startTime: DateTime!
    $endTime: DateTime
  ) {
    calendarEventCreate(
      legalMatterKey: $legalMatterKey
      participants: $participants
      name: $name
      description: $description
      startTime: $startTime
      endTime: $endTime
    ) {
      ... on CalendarEventResponse {
        code
        message
        success
        calendarEvent {
          ...LegalMatterCalendarEvent
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const updateCalendarEventMutation = gql(/* GraphQL */ `
  mutation updateEvent(
    $calendarEventKey: Key!
    $legalMatterKey: Key!
    $participants: [Key!]!
    $name: String!
    $description: String
    $startTime: DateTime!
    $endTime: DateTime
  ) {
    calendarEventUpdate(
      legalMatterKey: $legalMatterKey
      calendarEventKey: $calendarEventKey
      participants: $participants
      name: $name
      description: $description
      startTime: $startTime
      endTime: $endTime
    ) {
      ... on CalendarEventResponse {
        code
        message
        success
        calendarEvent {
          ...LegalMatterCalendarEvent
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const deleteCalendarEventMutation = gql(`
  mutation DeleteEvent($calendarEventKey: Key!) {
    calendarEventDelete(calendarEventKey: $calendarEventKey) {
      code
      message
      objectRef
      success
    }
  }
`)

interface CalendarEventsHook {
  createEvent: (
    mutationOpts:
      | MutationFunctionOptions<
          CreateEventMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CreateEventMutation>>
  updateEvent: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateEventMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateEventMutation>>
  deleteEvent: (
    mutationOpts:
      | MutationFunctionOptions<
          DeleteEventMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<DeleteEventMutation>>
  eventLoading: boolean
  eventError: any
}

const useCalendarEvents = (
  legalMatterKey: string | undefined,
): CalendarEventsHook => {
  const [createEvent, { loading: creatingEvent, error: createEventError }] =
    useMutation<CreateEventMutation>(createCalendarEventMutation, {
      update(cache, { data }) {
        if (
          legalMatterKey != null &&
          data?.calendarEventCreate.__typename === 'CalendarEventResponse'
        ) {
          const calendarEvent: LegalMatterCalendarEventFragment =
            data.calendarEventCreate.calendarEvent
          cache.modify({
            id: getCacheId('LegalMatter', legalMatterKey),
            fields: {
              calendarEvents(
                existingEventsConnection = makeEmptyData(),
                { toReference },
              ) {
                const newEventRef = toReference(calendarEvent)
                if (
                  !edgesInclude(existingEventsConnection.edges, newEventRef)
                ) {
                  return {
                    ...existingEventsConnection,
                    edges: [
                      ...existingEventsConnection.edges,
                      {
                        __typename: 'CalendarEventEdge',
                        node: newEventRef,
                      },
                    ],
                  } as AsStoreObject<CalendarEventConnection>
                } else {
                  return existingEventsConnection
                }
              },
            },
          })
        }
      },
    })

  const [updateEvent, { loading: updatingEvent, error: updateEventError }] =
    useMutation<UpdateEventMutation>(updateCalendarEventMutation)

  const [deleteEvent, { loading: deletingEvent, error: deleteEventError }] =
    useMutation<DeleteEventMutation>(deleteCalendarEventMutation, {
      update(cache, { data }) {
        if (
          legalMatterKey != null &&
          data?.calendarEventDelete.__typename === 'DeleteResponse'
        ) {
          cache.modify({
            id: getCacheId('LegalMatter', legalMatterKey),
            fields: {
              calendarEvents(existingEventsConnection = {}) {
                return {
                  ...existingEventsConnection,
                  edges: filter(
                    existingEventsConnection.edges,
                    (edge) =>
                      edge.node.__ref !=
                      getCacheId(
                        'CalendarEvent',
                        data.calendarEventDelete.objectRef,
                      ),
                  ),
                } as CalendarEventConnection
              },
            },
          })
        }
      },
    })

  const eventLoading = creatingEvent || updatingEvent || deletingEvent
  const eventError = createEventError ?? updateEventError ?? deleteEventError

  return {
    createEvent,
    updateEvent,
    deleteEvent,
    eventLoading,
    eventError,
  }
}

export default useCalendarEvents
