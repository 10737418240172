import {
  type AclRule as GqlAclRule,
  type Permission,
} from '../@types/generated/graphql'
import AclIdentity from './AclIdentity'

export type AclRuleArgs = Pick<GqlAclRule, 'identity' | 'permissions'>

export default class AclRule {
  identity: AclIdentity
  permissions: Permission[]

  constructor(identity: AclIdentity, permissions: Permission[]) {
    this.identity = identity
    this.permissions = permissions
  }

  allows(identity: AclIdentity, permission: Permission): boolean {
    return (
      this.identity.equals(identity) && this.permissions.includes(permission)
    )
  }

  static fromData(aclRule: AclRuleArgs): AclRule {
    const identity = new AclIdentity(
      aclRule.identity.kind,
      aclRule.identity.uid,
    )
    return new AclRule(identity, aclRule.permissions)
  }
}
