import type { ServerError, ServerParseError, ApolloError } from '@apollo/client'
import { type GraphQLError } from 'graphql'
import { GqlError } from '../@types/GqlError'

function isNetworkError(
  error: unknown,
): error is { networkError: Error | ServerError | ServerParseError } {
  return hasNetworkError(error) && !hasGqlErrors(error)
}

function getGqlErrors(error: unknown): string {
  let result
  if (hasPopulatedGqlErrors(error)) {
    result = error.graphQLErrors[0].message
  }

  if (hasUnpopulatedGqlErrors(error)) {
    result = error.networkError.result.errors[0].message
  }

  if (isNetworkError(error)) {
    result = GqlError.NetworkError
  }

  return result
}

function hasGqlErrors(error: unknown): boolean {
  return hasPopulatedGqlErrors(error) || hasUnpopulatedGqlErrors(error)
}

function hasPopulatedGqlErrors(
  error: unknown,
): error is { graphQLErrors: readonly GraphQLError[] } {
  return Boolean(
    Boolean(error) &&
      typeof error === 'object' &&
      Array.isArray((error as Partial<ApolloError>).graphQLErrors) &&
      (error as ApolloError).graphQLErrors.length,
  )
}

function hasUnpopulatedGqlErrors(error: unknown): error is {
  networkError: { result: { errors: readonly GraphQLError[] } }
} {
  return Boolean(
    hasNetworkError(error) &&
      'result' in error.networkError &&
      Array.isArray(error.networkError.result) &&
      error.networkError.result.length,
  )
}

function hasNetworkError(
  error: unknown,
): error is { networkError: Error | ServerError | ServerParseError } {
  return Boolean(
    error !== null &&
      typeof error === 'object' &&
      (error as ApolloError).networkError,
  )
}

export { getGqlErrors, isNetworkError }
