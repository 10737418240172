import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const formatPhoneNumber = (phoneNumber: string): string => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned)
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return phoneNumber
}

const FormattedPhoneNumber = ({
  phoneNumber,
  icon,
}: {
  phoneNumber?: string | null
  icon?: React.ReactNode
}): JSX.Element => {
  if (phoneNumber == null) return <Typography>No phone number</Typography>
  return (
    <Stack alignItems="center" direction="row" gap={1}>
      {icon}
      <Typography component="div">
        <Link variant="overline" href={`tel:${String(phoneNumber)}`}>
          {formatPhoneNumber(phoneNumber)}
        </Link>
      </Typography>
    </Stack>
  )
}

export default FormattedPhoneNumber
