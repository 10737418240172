import React from 'react'
import Grid from '@mui/material/Grid'
import styles from './SeparationGridBox.module.css'

interface Props {
  children: React.ReactNode
}

const SeparationGridBox = ({ children }: Props): JSX.Element => {
  return (
    <Grid
      container
      justifyContent="space-between"
      direction="column"
      alignItems="left"
      className={styles.gridSeparation}
    >
      {children}
    </Grid>
  )
}

export default SeparationGridBox
