import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import ErrorScreen from './ErrorScreen'

const ErrorHandler = (): JSX.Element | null => {
  let errorMessage = ''
  const error = useRouteError()

  if (error == null) return null

  if (isRouteErrorResponse(error)) {
    errorMessage = error.data
  } else if (error instanceof Error) {
    errorMessage = error.message
  } else {
    errorMessage = 'An unknown error occurred'
  }

  return (
    <Alert severity="error" icon={false} sx={{ my: 2 }}>
      <Typography variant="h2">An error occurred</Typography>
      <ErrorScreen content={errorMessage} />
    </Alert>
  )
}

export default ErrorHandler
