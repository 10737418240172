import SignInWithGoogle from '../organisms/SignInWithGoogle'
import SignInWithEmailLink from '../organisms/SignInWithEmailLink'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const AuthPage = (): JSX.Element => {
  return (
    <Box
      sx={{
        margin: 'auto',
      }}
      maxWidth="sm"
    >
      <Stack direction="column" spacing={2}>
        <Alert severity="info" icon={false} sx={{ justifyContent: 'center' }}>
          <Typography variant="h2">Welcome to LegalFix!</Typography>
          <Typography variant="h4">Please sign in to continue.</Typography>
        </Alert>
        <SignInWithEmailLink />
        <Divider sx={{ py: 2 }}>OR</Divider>
        <SignInWithGoogle />
      </Stack>
    </Box>
  )
}

export default AuthPage
