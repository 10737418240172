import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { NetworkStatus, useQuery } from '@apollo/client'
import { routes } from 'routes'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import LegalMatterSidebar from 'view/components/organisms/LegalMatterSidebar'
import LawFirmContact from 'view/components/molecules/LawFirmContact'
import { labelSlugs } from 'constants/index'
import TwoColumnLayout from 'view/components/templates/TwoColumnLayout'
import CalendarEventListItem from '../organisms/CalendarEventListItem'
import AnimatedList from '../molecules/AnimatedList'
import FilesList from 'view/components/molecules/FilesList'
import {
  type AttachedFile,
  useAttachmentGroups,
} from 'utils/useAttachmentGroups'
import config from 'config'
import { decodeKey } from 'utils/KeyFormatter'
import {
  type CloudStorageFile,
  useFirebaseStorage,
} from 'utils/useFirebaseStorage'
import { getPastDate } from 'utils/dateUtils'
import DropdownPeriodSelector from 'view/components/atoms/DropdownPeriodSelector'
import IntakeFormStep from '../forms/IntakeFormStep'
import {
  type DialogFiles,
  type Errors,
} from '../../../@types/DialogControlType'
import ConfirmationDialog, {
  type ConfirmationDialogProps,
} from 'view/components/molecules/ConfirmationDialog'
import { SubscriberLegalMatterStatusLabels } from 'utils/StatusMapper'
import SubscriberTaskListing from 'view/components/data/SubscriberTaskListing'
import { EventProviderContext } from 'view/providers/EventProvider'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import useCurrentUser from 'utils/useCurrentUser'
import {
  CursorDirection,
  Permission,
  SubscriberLegalMatterDetailDocument,
  LegalMatterTasksDocument,
  SubscriberLegalMatterDetailQuery,
  SubscriberLegalMatterDetailQueryVariables,
} from '../../../@types/generated/graphql'
import model from 'models'
import SubscriberUser from 'models/SubscriberUser'
import useLegalMatter from 'utils/useLegalMatter'
import useDocuments from 'utils/useDocuments'
import ApplicationError from '../molecules/ApplicationError'
import { SubscriberLegalMatterLoading } from '../molecules/LoadingSkeletons'
import log from 'loglevel'
import { paginationLimits } from '../organisms/PaginateItems'

interface DialogControlProps extends ConfirmationDialogProps {
  files?: DialogFiles
  errors?: Errors
  textBoxContent?: string
  documentKey?: string
}

type TabPanelType = 'INTAKEFORM' | 'LEGALMATTER'

const SubscriberLegalMatter = (): JSX.Element | null => {
  const componentIdentifier = SubscriberLegalMatter.name
  const { enqueueSnackbar } = useSnackbar()
  const { legalMatterKey } = useParams()
  const { user, loading: userLoading } = useCurrentUser()
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()
  const [selectedTab, setSelectedTab] = useState<TabPanelType>('LEGALMATTER')
  const { prepareStorageObject, resolveDocumentKey } = useAttachmentGroups()
  const navigate = useNavigate()

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      [
        'CalendarEventCreated',
        'CalendarEventDeleted',
        'CalendarEventUpdated',
        'LegalMatterAssigned',
        'LegalMatterClaimed',
        'LegalMatterClosed',
        'LegalMatterDocumentDeleted',
        'LegalMatterDocumentUpdated',
        'LegalMatterRejected',
        'LegalMatterWithdrawn',
        'TaskCreated',
      ],
      [SubscriberLegalMatterDetailDocument, LegalMatterTasksDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const dialogControlIntialState: DialogControlProps = {
    open: false,
    title: <></>,
    content: <></>,
    actions: <></>,
    documentKey: '',
    textBoxContent: '',
    errors: {
      message: undefined,
    },
  }

  const [dialogControlProps, setDialogControlProps] = useState(
    dialogControlIntialState,
  )

  const downloadRef = useRef<HTMLAnchorElement>(null)
  const lastDownloadedDocUrl = useRef<string | null>(null)

  const basePath = useMemo(
    () =>
      legalMatterKey != null
        ? `${config.legalMatterDocumentsBasePath}/${decodeKey(legalMatterKey).id}`
        : null,
    [config.legalMatterDocumentsBasePath, legalMatterKey],
  )

  const [fileToDownload, setFileToDownload] = useState<CloudStorageFile | null>(
    null,
  )
  const [fileToPersistInCloudStorage, setFileToPersistInCloudStorage] =
    useState<CloudStorageFile | null>(null)

  const {
    key: createdKey,
    error: createdError,
    storagePath: createdStoragePath,
    progress,
  } = useFirebaseStorage(fileToPersistInCloudStorage)

  const { downloadUrl, error: downloadError } =
    useFirebaseStorage(fileToDownload)

  const [eventsPeriod, setEventsPeriod] = useState<number>(7)
  const [eventsPeriodDateTime, setEventsPeriodDateTime] = useState<
    string | Date | null
  >(getPastDate(eventsPeriod, 'UTC'))
  const [tasksPeriod, setTasksPeriod] = useState<number>(7)
  const [tasksPeriodDateTime, setTasksPeriodDateTime] = useState<
    string | Date | null
  >(getPastDate(tasksPeriod, 'UTC'))

  const createdFile =
    createdStoragePath !== null &&
    createdKey !== null &&
    fileToPersistInCloudStorage !== undefined
      ? {
          key: createdKey,
          name: fileToPersistInCloudStorage?.file?.name ?? '',
          url: createdStoragePath,
        }
      : undefined

  if (
    createdFile !== undefined &&
    fileToPersistInCloudStorage?.correlationKey !== undefined
  )
    setFileToPersistInCloudStorage(null)

  useEffect(() => {
    if (createdError != null) {
      enqueueSnackbar(createdError.message, { variant: 'error' })
    }
  }, [createdError, enqueueSnackbar])

  useEffect(() => {
    if (downloadError !== null)
      enqueueSnackbar(downloadError.message, {
        variant: 'error',
      })
  }, [downloadError, enqueueSnackbar])

  if (
    downloadUrl !== null &&
    downloadRef.current !== null &&
    lastDownloadedDocUrl.current !== downloadUrl
  ) {
    lastDownloadedDocUrl.current = downloadUrl
    downloadRef.current.href = downloadUrl
    downloadRef.current?.click()
    setFileToDownload(null)
  }

  const { data, networkStatus } = useQuery<
    SubscriberLegalMatterDetailQuery,
    SubscriberLegalMatterDetailQueryVariables
  >(SubscriberLegalMatterDetailDocument, {
    variables: {
      key: legalMatterKey ?? null,
      since: eventsPeriodDateTime,
      calendarLimit: paginationLimits.calendarEvents,
      calendarCursor: null,
      calendarCursorDirection: CursorDirection.Next,
      documentsLimit: paginationLimits.documents,
      documentsCursor: null,
      documentsCursorDirection: CursorDirection.Next,
    },
    notifyOnNetworkStatusChange: true,
    skip: userLoading,
  })

  const { deleteDocument } = useDocuments()
  const { cancelLegalMatter } = useLegalMatter()

  if (userLoading || networkStatus === NetworkStatus.loading) {
    return <SubscriberLegalMatterLoading />
  }

  if (user instanceof SubscriberUser === false) {
    return (
      <ApplicationError
        message={
          <Typography>
            No valid user could be resolved. Please reload in a few seconds.
          </Typography>
        }
      />
    )
  }

  const legalMatter = data?.legalMatters?.edges?.[0]?.node

  if (!data || legalMatter == null) {
    return (
      <ApplicationError
        message={
          <Typography>
            This legal matter does not exist.{' '}
            <Link
              onClick={() => {
                navigate(routes.subscriberLegalMatterListing.path)
              }}
            >
              Go back to My Legal Matters.
            </Link>
          </Typography>
        }
      />
    )
  }

  const legalMatterModel = new model.LegalMatter(
    data.legalMatters.edges[0].node,
  )
  const assignmentInProgress = legalMatterModel.assignmentInProgress()

  if (assignmentInProgress && selectedTab !== 'INTAKEFORM') {
    setSelectedTab('INTAKEFORM')
  }

  const handleCancelThisMatter = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm to cancel this legal matter
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            Are you sure you want to cancel this legal matter? This is a non
            recoverable operation.
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Close this window
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('cancelLegalMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm cancelation
          </Button>
        </>
      ),
    })
  }

  const handleDocumentUpload = (
    files: AttachedFile[],
    taskId?: string,
    legalMatterId?: string,
  ): void => {
    if (basePath == null) {
      throw new Error('Base path is not defined')
    }
    const storageObject =
      legalMatterId !== undefined
        ? prepareStorageObject(
            'LEGALMATTER',
            user.key,
            legalMatterId,
            files,
            basePath,
          )
        : taskId !== undefined
          ? prepareStorageObject('TASK', user.key, taskId, files, basePath)
          : null
    if (storageObject !== null) setFileToPersistInCloudStorage(storageObject[0])
  }

  const handleDocumentDownload = (unresolvedUrl?: string): void => {
    const resolvedKey = resolveDocumentKey(unresolvedUrl)
    if (basePath == null) {
      throw new Error('Base path is not defined')
    }
    if (resolvedKey !== null)
      setFileToDownload({
        key: resolvedKey,
        correlationKey: null as unknown as string,
        basePath,
        metadata: null,
      })
  }

  const handleDocumentDelete = (documentKey: string): void => {
    const document = legalMatterModel
      .get('documents')
      .edges.find((d) => d.node.key === documentKey)
    if (document !== undefined) {
      setDialogControlProps({
        open: true,
        title: (
          <>
            <Typography variant="h1" gutterBottom>
              Confirm deletion of document
            </Typography>
          </>
        ),
        content: (
          <>
            <Typography>
              Are you sure you want to delete the document{' '}
              <strong>{document.node.name}</strong> ?
            </Typography>
          </>
        ),
        actions: (
          <>
            <Button
              onClick={() => {
                setDialogControlProps(dialogControlIntialState)
              }}
              autoFocus
              variant="contained"
              color="error"
              data-testid="confirm-dialog-btn-cancel"
            >
              Cancel deletion
            </Button>
            <Button
              onClick={() => {
                handleDialogActionConfirm('deleteAttachedDocument', documentKey)
              }}
              autoFocus
              variant="contained"
              color="primary"
              data-testid="confirm-dialog-btn-accept"
            >
              Confirm deletion
            </Button>
          </>
        ),
      })
    }
  }

  const onFileUploadPopoverClick = (
    attachedFiles?: AttachedFile[],
    taskId?: string,
  ): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      content: (
        <>
          <FilesList
            files={
              taskId && attachedFiles
                ? attachedFiles
                : legalMatter?.documents?.edges?.map((edge) => edge.node)
            }
            maxFilesToDisplay={0}
            onFileClick={handleDocumentDownload}
            user={user}
            onRemoveFile={(key) => {
              handleDocumentDelete(key)
            }}
          />
        </>
      ),
    })
  }

  const handleTabChange = (
    event: React.SyntheticEvent,
    newSelectedTab: TabPanelType,
  ): void => {
    setSelectedTab(newSelectedTab)
  }

  const handleEventPeriodChange = (value: number): void => {
    setEventsPeriod(value)
    setEventsPeriodDateTime(getPastDate(value, 'UTC'))
  }

  const handleTasksPeriodChange = (value: number): void => {
    setTasksPeriod(value)
    setTasksPeriodDateTime(getPastDate(value, 'UTC'))
  }

  const handleDialogActionConfirm = (action: string, itemKey = ''): void => {
    switch (action) {
      case 'deleteAttachedDocument':
        if (itemKey !== '') {
          void deleteDocument({
            variables: {
              documentKey: itemKey,
            },
            onCompleted: (data) => {
              if (!data.legalMatterDocumentDelete.success) {
                enqueueSnackbar(data.legalMatterDocumentDelete.message, {
                  variant: 'error',
                })
              }

              setDialogControlProps(dialogControlIntialState)
            },
            onError: () => {
              setDialogControlProps(dialogControlIntialState)
              enqueueSnackbar(
                'There was an error in removing the attached document, try again in a few seconds',
                {
                  variant: 'error',
                },
              )
            },
          })
        }
        break
      case 'cancelLegalMatter':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Canceling this legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void cancelLegalMatter({
          variables: {
            legalMatterKey,
          },
          onCompleted: () => {
            setDialogControlProps(dialogControlIntialState)
          },
          onError: (error) => {
            setDialogControlProps((prevState) => ({
              ...prevState,
              errors: { message: error.message },
            }))
            enqueueSnackbar(
              'There was a problem in processing the request, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
    }
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="Subscriber tabs">
            <Tab
              label={labelSlugs.LEGAL_MATTER_INFO_TAB}
              value="LEGALMATTER"
              data-testid="LEGALMATTER-tab"
              aria-label="LEGALMATTER tab"
              disabled={assignmentInProgress}
            />
            <Tab
              label={labelSlugs.LEGAL_MATTER_INTAKE_TAB}
              data-testid="INTAKEFORM-tab"
              aria-label="INTAKEFORM tab"
              value="INTAKEFORM"
            />
          </TabList>
        </Box>
        <TabPanel value="INTAKEFORM">
          <IntakeFormStep
            readonly
            intakeDataRef={legalMatter.intakeDataRefs?.[0]}
          ></IntakeFormStep>
        </TabPanel>
        <TabPanel value="LEGALMATTER">
          <TwoColumnLayout>
            <LegalMatterSidebar
              legalMatterFragment={legalMatter}
              legalMatterStatusLabels={SubscriberLegalMatterStatusLabels}
              onDocumentClick={(file) => {
                handleDocumentDownload(file)
              }}
              onDocumentListPopoverClick={() => {
                onFileUploadPopoverClick()
              }}
              onDocumentDelete={handleDocumentDelete}
              user={user}
            >
              <LawFirmContact
                lawyerName={legalMatter.assignedLawyer?.firstName}
                lawyerLastName={legalMatter.assignedLawyer?.lastName}
                lawyerEmail={legalMatter.assignedLawyer?.email ?? undefined}
                lawyerPhone={legalMatter.assignedLawyer?.phone ?? undefined}
                firmName={legalMatter.assignedLawyer?.firm?.name ?? undefined}
                firmEmail={legalMatter.assignedLawyer?.firm?.email ?? undefined}
                firmPhone={
                  legalMatter?.assignedLawyer?.firm?.phone ?? undefined
                }
              />
              {legalMatterModel.acl.allows(
                user.aclIdentities,
                Permission.LegalMattersCancel,
              ) &&
                legalMatterModel.canBeCanceled() && (
                  <>
                    <hr />
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleCancelThisMatter}
                      sx={{ width: '100%' }}
                      data-testid="cancel-legalmatter-button"
                    >
                      Cancel this request
                    </Button>
                  </>
                )}
            </LegalMatterSidebar>
            <Box my={2}>
              {assignmentInProgress ? (
                <Alert severity="info">
                  This legal matter hasn't been assigned yet
                </Alert>
              ) : (
                <>
                  <Typography variant="h2" gutterBottom>
                    Consultations
                  </Typography>
                  <Divider sx={{ mb: 1 }} />
                  <Grid
                    item
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    xs={12}
                    spacing={2}
                    mb={1}
                  >
                    <Grid item>Showing Events from:</Grid>
                    <Grid item>
                      <DropdownPeriodSelector
                        value={eventsPeriod.toString()}
                        onChangeValue={handleEventPeriodChange}
                      ></DropdownPeriodSelector>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                  {legalMatter.calendarEvents?.edges?.length === 0 && (
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="info" icon={false}>
                        <Typography variant="h5" gutterBottom>
                          No Scheduled Consultations
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          The attorney has not scheduled any consultations for
                          this legal matter.
                        </Typography>
                      </Alert>
                    </Box>
                  )}
                  {legalMatter.calendarEvents != null ? (
                    <AnimatedList animateItemKey={null}>
                      {legalMatter.calendarEvents.edges.map((event) => (
                        <CalendarEventListItem
                          disableMenu={true}
                          key={event.node.key}
                          calendarEvent={event.node}
                        />
                      ))}
                    </AnimatedList>
                  ) : (
                    <Typography variant="h4">
                      {labelSlugs.NO_COSULTATIONS_AVAILABLE}
                    </Typography>
                  )}
                  <Box my={2}>
                    <Divider sx={{ mb: 2 }} />
                    <SubscriberTaskListing
                      legalMatterKey={legalMatterKey}
                      isReadOnly={
                        legalMatterModel.isReadOnly() ||
                        !user.hasAnActiveSubscription()
                      }
                      fileUploadProgress={progress}
                      onDocumentListPopoverClick={(attachedFiles, taskId) => {
                        onFileUploadPopoverClick(attachedFiles, taskId)
                      }}
                      onFilesSelected={handleDocumentUpload}
                      handleDocumentDownload={handleDocumentDownload}
                      handleDocumentDelete={handleDocumentDelete}
                    />
                  </Box>
                </>
              )}
            </Box>
          </TwoColumnLayout>
        </TabPanel>
      </TabContext>
      <ConfirmationDialog
        open={dialogControlProps.open}
        onClose={() => {
          setDialogControlProps(dialogControlIntialState)
        }}
        title={dialogControlProps.title}
        content={dialogControlProps.content}
        actions={dialogControlProps.actions}
        errorMessage={dialogControlProps?.errorMessage}
      />
      <Box style={{ display: 'none' }}>
        <a ref={downloadRef} style={{ display: 'none' }} target="_blank" />
      </Box>
    </Box>
  )
}

export default SubscriberLegalMatter
