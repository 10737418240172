import { useEffect, RefObject } from 'react'

interface UseScrollIntoViewOptions {
  offset?: number
  behavior?: ScrollBehavior
  block?: ScrollLogicalPosition
  inline?: ScrollLogicalPosition
  delay?: number
  headerSelector?: string
}

function useScrollIntoView<T extends HTMLElement>(
  ref: RefObject<T>,
  options: UseScrollIntoViewOptions = {},
): void {
  useEffect(() => {
    if (ref.current) {
      const {
        offset = 0,
        behavior = 'smooth',
        block = 'center',
        inline = 'nearest',
        delay = 0,
        headerSelector = null,
      } = options

      const scrollToElement = () => {
        let headerHeight = 0

        if (headerSelector) {
          const headerElement = document.querySelector(headerSelector)
          headerHeight =
            headerElement instanceof HTMLElement
              ? headerElement.offsetHeight
              : 0
        }

        ref?.current?.scrollIntoView({
          behavior,
          block,
          inline,
        })

        if (headerHeight || offset) {
          window.scrollBy({
            top: -(headerHeight + offset),
            behavior,
          })
        }
      }

      if (delay > 0) {
        const timeoutId = setTimeout(scrollToElement, delay)
        return () => clearTimeout(timeoutId)
      } else {
        scrollToElement()
      }
    }
  }, [ref, options])
}

export default useScrollIntoView
