import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
export interface DotMenuAnchor {
  element: Element
  menuItems: MenuItems[]
}
export interface MenuItems {
  action: string
  label: string
  icon: React.ReactElement
  visible?: boolean
  disabled?: boolean
}
interface Props {
  anchor: DotMenuAnchor | null
  open: boolean
  onItemSelected: (itemKey: string) => void
  onClose: () => void
}

const DotMenu = ({
  anchor,
  open,
  onItemSelected,
  onClose,
}: Props): JSX.Element | null => {
  if (anchor == null) {
    return null
  }

  return (
    <Menu
      anchorEl={anchor.element}
      open={open}
      onClose={onClose}
      data-testid="dot-menu"
    >
      {anchor.menuItems?.map((item) =>
        item.visible === false ? null : (
          <MenuItem
            key={`dotmenu-${item.action}`}
            disabled={item.disabled}
            onClick={() => {
              onItemSelected(item.action)
            }}
          >
            {item.icon} {item.label}
          </MenuItem>
        ),
      )}
    </Menu>
  )
}

export default DotMenu
