import { type FieldProps } from '@rjsf/utils'
import { type FC } from 'react'
import Markdown from 'markdown-to-jsx'

const RJSFMarkdownField: FC<FieldProps<string>> = ({ schema }) => {
  const { markdown } = schema

  return <Markdown>{markdown ?? ''}</Markdown>
}

export default RJSFMarkdownField
