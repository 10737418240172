import { enqueueSnackbar } from 'notistack'
import Button from '@mui/material/Button'
import GoogleIcon from '@mui/icons-material/Google'
import { signInWithGoogle } from 'firebaseConfig'
import { AuthResponseStatus } from '../../../@types/Auth'

const SignInWithGoogle = (): JSX.Element => {
  const handleAuth = (): void => {
    void signInWithGoogle().then((res) => {
      if (res.status !== AuthResponseStatus.successful) {
        enqueueSnackbar(res.message, { variant: 'error' })
      } else {
        enqueueSnackbar('You have been signed in successfully ...', {
          variant: 'success',
        })
      }
    })
  }

  return (
    <Button
      startIcon={<GoogleIcon color="info" />}
      variant="outlined"
      onClick={handleAuth}
      data-testid="btn-googleSignIn"
      sx={{ width: '100%' }}
    >
      {' '}
      Sign in with Google{' '}
    </Button>
  )
}

export default SignInWithGoogle
