/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { type FieldProps } from '@rjsf/utils'
import { type FC, type SyntheticEvent, useCallback } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { gql } from '../../../@types/generated'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'

const filter = createFilterOptions<string>()

export const usStatesQuery = gql(/* GraphQL */ `
  query UsStateDatabase($operationalStatesOnly: Boolean) {
    usLocations(operationalStatesOnly: $operationalStatesOnly) {
      states {
        stateCode
        stateName
      }
    }
  }
`)

export const usCitiesQuery = gql(/* GraphQL */ `
  query UsCityDatabase($stateCode: String, $cityKeyword: String) {
    usLocations(stateCode: $stateCode, cityKeyword: $cityKeyword) {
      cities {
        city
        countyName
      }
    }
  }
`)

const RJSFAddressField: FC<FieldProps<Record<string, any>>> = ({
  schema,
  hideError,
  readonly,
  classNames,
  id,
  onChange,
  formData,
  required,
}) => {
  const state = formData?.state
  const city = formData?.city
  const [stateOptionsOpen, setStateOptionsOpen] = React.useState(false)
  const [cityOptionsOpen, setCityOptionsOpen] = React.useState(false)
  const [getStates, { loading: statesQueryLoading, data: statesData }] =
    useLazyQuery(usStatesQuery)
  const [getCities, { loading: citiesQueryLoading, data: citiesData }] =
    useLazyQuery(usCitiesQuery)

  const { stateFieldTitle, cityFieldTitle, operationalStatesOnly } = schema
  const showErrors = hideError !== undefined && !hideError
  const isRequired = required !== undefined && required
  const showStateError = showErrors && isRequired && state === ''
  const showCityError = showErrors && isRequired && city === ''

  const states =
    statesData?.usLocations?.states?.map(
      (state: { stateCode: string }) => state.stateCode,
    ) ?? []
  const cities =
    state !== ''
      ? (citiesData?.usLocations?.cities?.map(
          (city: { city: string; countyName: string }) =>
            `${city.city}, ${city.countyName}`,
        ) ?? [])
      : ['Please select State']

  const handleStateChange = (
    _event: SyntheticEvent,
    value: string | null,
  ): void => {
    if (value === undefined || value === null) return

    void (async () => {
      await debouncedFetch(value, '')
    })()
    onChange(
      formData !== undefined
        ? { ...formData, state: value, city: '' }
        : { state: value, city: '' },
    )
  }

  const handleCityChange = (
    _event: SyntheticEvent,
    value: string | null,
  ): void => {
    const newCity = value?.replace(/^"?|"?$/g, '') ?? ''
    onChange(
      formData !== undefined
        ? { ...formData, city: newCity }
        : { city: newCity },
    )
  }

  const handleCityTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const cityKeyword = event.target.value
    if (cityKeyword !== undefined && cityKeyword.length > 0) {
      void (async () => {
        await debouncedFetch(state, cityKeyword)
      })()
    }
  }

  const debouncedFetch = useCallback(
    debounce(async (state: string, cityKeyword: string) => {
      await getCities({
        variables: { stateCode: state ?? '', cityKeyword },
      })
    }, 500),
    [],
  )

  return (
    <Box id={id} className={classNames} sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <FormControl
            required={required}
            fullWidth
            error={showStateError ?? false}
          >
            <Autocomplete
              id="state"
              open={stateOptionsOpen}
              disabled={readonly}
              onOpen={() => {
                setStateOptionsOpen(true)
                void (async () => {
                  await getStates({
                    variables: {
                      operationalStatesOnly: operationalStatesOnly ?? false,
                    },
                  })
                })()
              }}
              onClose={() => {
                setStateOptionsOpen(false)
              }}
              onChange={handleStateChange}
              value={state ?? ''}
              options={states}
              loading={stateOptionsOpen || statesQueryLoading}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  disabled={readonly}
                  error={showStateError ?? false}
                  variant="outlined"
                  label={stateFieldTitle ?? 'State'}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {stateOptionsOpen && statesQueryLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            required={required}
            fullWidth
            error={showCityError ?? false}
          >
            <Autocomplete
              id="city"
              open={cityOptionsOpen}
              disabled={readonly}
              onOpen={() => {
                setCityOptionsOpen(true)
              }}
              onClose={() => {
                setCityOptionsOpen(false)
              }}
              onChange={handleCityChange}
              value={city ?? ''}
              options={cities}
              loading={cityOptionsOpen || citiesQueryLoading}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option,
                )
                if (inputValue !== '' && !isExisting) {
                  filtered.unshift(`"${inputValue}"`)
                }

                return filtered
              }}
              loadingText="Type to search..."
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  disabled={readonly}
                  error={showCityError ?? false}
                  variant="outlined"
                  label={cityFieldTitle ?? 'City'}
                  onChange={handleCityTextChange}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {cityOptionsOpen && citiesQueryLoading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RJSFAddressField
