import React from 'react'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NameValueLabel from 'view/components/atoms/NameValueLabel'
import styles from './ListItemBoxHeading.module.css'

interface Props {
  title?: React.ReactNode | string
  midContent?: React.ReactNode | string
  subtitle?: React.ReactNode | string
  disableMenu?: boolean
  infoPill?: React.ReactNode | string
  onClickMenu?: (event: React.MouseEvent) => void
}

const ListItemBoxHeading = ({
  title,
  subtitle,
  midContent,
  disableMenu,
  infoPill,
  onClickMenu,
}: Props): JSX.Element => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={11}>
        <Grid
          container
          data-testid="listitemboxheading-title"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            lg={6}
            data-testid="listitemboxheading-title-container"
          >
            {typeof title === 'string' ? (
              <NameValueLabel
                typographyProps={{ variant: 'h5' }}
                value={title != null ? String(title) : '&nbsp;'}
              />
            ) : (
              title
            )}
          </Grid>
          {infoPill !== undefined && infoPill}
          <Grid item xs={12} lg="auto">
            {midContent != null ? midContent : null}
          </Grid>
          <Grid item xs={12} lg={4} className={styles.subtitle}>
            <Typography paragraph data-testid="createdOn" my={0}>
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!(disableMenu ?? false) && (
        <Grid item xs={1} sx={{ textAlign: 'right' }}>
          <IconButton
            data-testid="listitemboxheading-dotmenu"
            aria-label="Open Options Menu Button"
            onClick={(event) => {
              if (onClickMenu != null) {
                onClickMenu(event)
              }
            }}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

export default ListItemBoxHeading
