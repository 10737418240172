import type User from 'models/User'

export type UserTypes = (new (...args: any[]) => User)[]

const useAccessRules = (
  user: User,
): {
  userIsOneOf: (allowedTypes: UserTypes) => boolean
  userHasRole: (role: string) => boolean
} => {
  return {
    userIsOneOf: (allowedTypes: UserTypes): boolean =>
      allowedTypes.some((userClass) => user instanceof userClass),
    userHasRole: (role: string): boolean => user.roles.includes(role),
  }
}

export default useAccessRules
