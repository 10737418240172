import React, { type FormEvent, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BackupIcon from '@mui/icons-material/BackupTwoTone'
import styles from './FileUploader.module.css'
import { type AttachedFile } from 'utils/useAttachmentGroups'
import { type Scalars } from '../../../@types/generated/graphql'
import type { FileProgress, UploadError } from '../../../@types/Upload'

export interface FileUploaderProps {
  correlationKey?: Scalars['Key']
  textAttachButton?: string
  fileUploadProgress?: FileProgress | null
  onFilesSelected?: (files: AttachedFile[]) => void
  errors?: UploadError | null
}

const FileUploader = ({
  correlationKey,
  onFilesSelected,
  fileUploadProgress = null,
  textAttachButton = 'Attach files',
  errors = null,
}: FileUploaderProps): JSX.Element => {
  const inputFile = useRef<HTMLInputElement>(null)
  const [displayError, setDisplayError] = useState(Boolean)

  const handleAttachButtonClick = (): void => {
    setDisplayError(false)
    inputFile?.current?.click()
  }

  const onSelected = (e: FormEvent<HTMLInputElement>): void => {
    const inputFiles: FileList | null = e.currentTarget.files
    if (inputFiles !== null) {
      const updatedFiles = Array.from(inputFiles).map((file) => ({
        key: nanoid(),
        fileObject: file,
        name: file.name,
        fullPath: file.name,
      }))
      onFilesSelected?.(updatedFiles)
    }
  }

  return (
    <Grid
      container
      direction={'row'}
      key={correlationKey}
      aria-labelledby="fileuploader-attach-btn"
    >
      <Grid item xs={6}>
        <Button
          id="fileuploader-attach-btn"
          data-testid="fileuploader-attach-btn"
          variant="text"
          role="button"
          aria-label="Attach File Button"
          startIcon={<BackupIcon color="secondary" />}
          style={{ textTransform: 'none' }}
          onClick={handleAttachButtonClick}
        >
          {textAttachButton}
        </Button>
        {displayError ||
          (errors !== null && (
            <Box component={'span'} ml={1} mb={1}>
              <Tooltip
                title={errors?.message}
                arrow
                placement="right-start"
                leaveDelay={200}
                data-testid={'fileuploader-tooltip'}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </Box>
          ))}
        {fileUploadProgress?.progress != null &&
          fileUploadProgress.key === correlationKey &&
          fileUploadProgress.progress > 0 && (
            <LinearProgress
              variant="determinate"
              value={fileUploadProgress.progress}
              key={correlationKey}
            />
          )}
      </Grid>
      {displayError ||
        (errors !== null && (
          <Grid container direction={'row'} my={1}>
            <Grid item>
              <Typography
                variant="subtitle2"
                sx={{ color: 'error.main' }}
                data-testid="fileUploader-error"
              >
                {errors?.message}
              </Typography>
            </Grid>
          </Grid>
        ))}
      <input
        type="file"
        id="file"
        className={styles.inputFile}
        ref={inputFile}
        onChange={onSelected}
        data-testid="fileuploader-file-input"
        aria-label="File Uploader Input"
      />
    </Grid>
  )
}

export default FileUploader
