import { type Note } from '../../../@types/generated/graphql'
import { DateFormatter } from 'utils/dateUtils'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import NoteEditForm, { type serverError } from './NoteEditForm'

interface LawyerNoteItemProps {
  note: Note | null | undefined
  lawyerName?: string
  isEditing?: boolean
  isSubmitting?: boolean
  serverError?: serverError
  disableMenu?: boolean
  onClickMenu?: (event: React.MouseEvent) => void
  onClickSaveButton?: (Note) => void
  onClickCancelEditButton?: (value?: string) => void
}

const NoteItem = ({
  note,
  lawyerName,
  isEditing,
  isSubmitting = false,
  serverError,
  disableMenu,
  onClickMenu,
  onClickSaveButton,
  onClickCancelEditButton,
}: LawyerNoteItemProps): JSX.Element => {
  const date = DateFormatter(note?.createdTimestamp, 'MMM d, yyyy hh:mm a')
  const noteText = note?.note
  return (
    <>
      {isEditing === true && noteText !== null && (
        <Box>
          <NoteEditForm
            noteKey={note?.key}
            noteText={noteText}
            serverError={serverError}
            isSubmitting={isSubmitting}
            onClickButton={
              onClickSaveButton !== undefined
                ? (data) => {
                    onClickSaveButton(data)
                  }
                : () => undefined
            }
            onClickCancelButton={onClickCancelEditButton ?? (() => undefined)}
          ></NoteEditForm>
        </Box>
      )}
      {isEditing !== true && noteText !== null && lawyerName !== undefined && (
        <Box data-testid="noteItem-item">
          <Box my={3}>
            <Grid container>
              <Grid container item flexWrap="nowrap" alignItems="baseline">
                <Grid item xs={6}>
                  <Typography variant="caption" data-testid="noteItem-lawyer">
                    {lawyerName}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography
                    mb={1}
                    variant="subtitle2"
                    gutterBottom={true}
                    data-testid="noteItem-date"
                  >
                    {date}
                  </Typography>
                </Grid>
                {disableMenu !== true && (
                  <Grid item xs={2} sx={{ textAlign: 'right' }}>
                    <IconButton
                      data-testid="listitemboxheading-dotmenu"
                      aria-label="Open Note Options Menu"
                      onClick={onClickMenu}
                    >
                      <MoreVertIcon color="primary" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider sx={{ mb: 1 }} />
                <Typography
                  variant="subtitle2"
                  mb={1}
                  data-testid="noteItem-content"
                >
                  {noteText}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  )
}

export default NoteItem
