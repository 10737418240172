import { render } from 'storyblok-rich-text-react-renderer'
import InlineTableBlok from 'view/components/storyblok/InlineTableBlok'
import ServiceComponent from 'view/components/storyblok/ServiceComponent'
import log from 'loglevel'

const renderConfig = {
  blokResolvers: {
    InlineTable: (props: any) => <InlineTableBlok {...props} />,
    Service: (props: any) => <ServiceComponent {...props} />,
    defaultBlokResolver: (
      props: Record<string, unknown>,
    ): JSX.Element | null => {
      log.warn('Unknown blok resolver. Block props:', props)
      return null
    },
  },
}

interface StoryblokRichTextProps {
  type: string
  content: StoryblokRichTextProps[]
  attrs?: any
}

// Storyblok document block renderer
export const StoryblokRichText = ({
  document,
}: {
  document: StoryblokRichTextProps
}): JSX.Element => {
  const renderedContent = render(document, renderConfig)

  return (
    <>
      {renderedContent != null
        ? renderedContent
        : 'No content is available for this section. Please try again in a few seconds.'}
    </>
  )
}

export const StoryblokComponent = (props: any): JSX.Element | null => {
  if (Array.isArray(props?.props)) {
    return (
      <>
        {props.props.map((prop) => (
          <StoryblokComponent key={prop._uid} {...prop} />
        ))}
      </>
    )
  }

  if (props.component === 'Service') {
    return (
      <ServiceComponent title={props.title} description={props.description} />
    )
  }
  return null
}
