import { StandardTextFieldProps } from '@mui/material'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Controller } from 'react-hook-form'

interface optionItemType {
  label: string
  value: string
}

interface FormInputProps extends StandardTextFieldProps {
  name: string
  control: any
  optionItems: optionItemType[]
  selectedItem?: string
  'data-testid'?: string
}

const FormInputSelect = ({
  name,
  control,
  label,
  optionItems,
  selectedItem,
  'data-testid': dataTestId,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={selectedItem || ''}
      render={({ field }) => (
        <Select
          {...field}
          label={label}
          value={field.value}
          fullWidth
          data-testid={dataTestId}
        >
          {optionItems?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  )
}

export default FormInputSelect
