import { type UserInfo } from 'firebase/auth'
import User from './User'

export default class AuthProviderUser extends User {
  profile: UserInfo

  constructor(profile: UserInfo) {
    super([], [])
    this.profile = profile
  }
}
