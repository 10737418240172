import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

const InlineTableBlok = ({ InlineTable: { thead, tbody } }): JSX.Element => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            {thead?.map((th) => (
              <TableCell sx={{ color: 'common.black' }} key={th._uid}>
                {th.value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tbody?.map((row) => (
            <TableRow
              key={row._uid}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row?.body?.map((tcell) => (
                <TableCell
                  sx={{ color: 'common.black' }}
                  key={tcell._uid}
                  align="left"
                >
                  {tcell.value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default InlineTableBlok
