interface FirebaseConfig {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
  useEmulator: boolean
}

export interface FileUploadConfig {
  maxFileSize: number
  allowedFileTypes: string
  helpText: string
}

export interface Config {
  legalPlansApiBaseUrl: string
  legalPlansMarketingUrl: string
  version: string
  firebaseConfig: FirebaseConfig
  legalMatterDocumentsBasePath: string
  fileUploadConfig: FileUploadConfig
  sentryDSN: string
  sentryEnvironment: string
  legalPlansContactEmail: string
  logLevel: string
}

const readVariable = (
  name: string,
  defaultValue: string | undefined = undefined,
): string => {
  const prefixedName = `VITE_${name}`
  if (import.meta.env[prefixedName] == null) {
    if (defaultValue !== undefined) {
      return defaultValue
    }
    throw new Error(`Variable '${prefixedName}' is not defined`)
  }
  return import.meta.env[prefixedName] as string
}

const firebaseConfig: FirebaseConfig = {
  apiKey: readVariable('FIREBASE_API_KEY'),
  authDomain: readVariable('FIREBASE_AUTH_DOMAIN'),
  databaseURL: readVariable('FIREBASE_DATABASE_URL'),
  projectId: readVariable('FIREBASE_PROJECT_ID'),
  storageBucket: readVariable('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: readVariable('FIREBASE_MESSAGING_SENDER_ID'),
  appId: readVariable('FIREBASE_APP_ID'),
  measurementId: readVariable('FIREBASE_MEASUREMENT_ID'),
  useEmulator: readVariable('USE_FIREBASE_EMULATOR', 'false') === 'true',
}

const fileUploadConfig = (): FileUploadConfig => {
  const maxFileSizeValue = parseInt(
    readVariable('FILE_UPLOAD_MAX_FILE_SIZE_BYTES'),
  )
  const allowedFileTypesValue = readVariable('FILE_UPLOAD_ALLOWED_EXTENSIONS')
  const helpTextTemplate = readVariable('FILE_UPLOAD_HELP_TEXT')
  return {
    maxFileSize: maxFileSizeValue,
    allowedFileTypes: allowedFileTypesValue,
    helpText: helpTextTemplate
      .replace('{maxSize}', (maxFileSizeValue / 1024 / 1024).toString())
      .replace(
        '{allowedFileTypes}',
        allowedFileTypesValue
          .replace('(', '')
          .replace(')', '')
          .replaceAll('\\', ' ')
          .replaceAll('|', ','),
      ),
  }
}

const config: Config = {
  legalPlansApiBaseUrl: readVariable('LEGAL_PLANS_API_BASE_URL'),
  legalPlansMarketingUrl: readVariable('LEGAL_PLANS_MARKETING_URL'),
  version: readVariable('APP_VERSION'),
  firebaseConfig,
  legalMatterDocumentsBasePath: readVariable(
    'LEGAL_MATTER_DOCUMENTS_BASE_PATH',
  ),
  fileUploadConfig: fileUploadConfig(),
  sentryDSN: readVariable('SENTRY_DSN'),
  sentryEnvironment: readVariable('SENTRY_ENVIRONMENT'),
  legalPlansContactEmail: readVariable('LEGAL_PLANS_CONTACT_EMAIL'),
  logLevel: readVariable('LOG_LEVEL', 'warn'),
}

export default config
