import Select, { type SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

interface DropdownPeriodSelectorProps {
  value: string
  onChangeValue: (string) => void
}

const DropdownPeriodSelector: React.FC<DropdownPeriodSelectorProps> = ({
  value,
  onChangeValue,
}) => {
  const handlePerdiodChange = (event: SelectChangeEvent): void => {
    const value = event.target.value
    onChangeValue(value)
  }
  return (
    <Select onChange={handlePerdiodChange} value={value} size="small">
      <MenuItem key="7-days" value={7}>
        Last 7 days
      </MenuItem>
      <MenuItem key="14-days" value={14}>
        Last 14 days
      </MenuItem>
      <MenuItem key="30-days" value={30}>
        Last 30 days
      </MenuItem>
      <MenuItem key="all" value={-1}>
        All Time
      </MenuItem>
    </Select>
  )
}

export default DropdownPeriodSelector
