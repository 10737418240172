import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'

interface Props {
  title?: string
  message: JSX.Element
}

const ApplicationError = ({ title, message }: Props): JSX.Element => {
  return (
    <Alert severity="error">
      {title != null && <AlertTitle>{title}</AlertTitle>}
      {message}
    </Alert>
  )
}

export default ApplicationError
