import {
  type LegalMatter as GqlLegalMatter,
  LegalMatterStatus,
} from '../@types/generated/graphql'
import BaseEntity from './BaseEntity'

export default class LegalMatter extends BaseEntity {
  private readonly legalMatter: Pick<GqlLegalMatter, 'acl' | 'status'> &
    Partial<GqlLegalMatter>

  constructor(legalMatter: Pick<GqlLegalMatter, 'acl' | 'status'>) {
    super(legalMatter.acl)
    this.legalMatter = legalMatter
  }

  get<T extends keyof GqlLegalMatter>(attr: T): GqlLegalMatter[T] {
    return this.legalMatter[attr]
  }

  isReadOnly(): boolean {
    return [
      LegalMatterStatus.Unassigned,
      LegalMatterStatus.Assigning,
      LegalMatterStatus.Closed,
      LegalMatterStatus.Canceled,
      LegalMatterStatus.ReferredIn,
      LegalMatterStatus.Withdrawn,
    ].includes(this.legalMatter.status)
  }

  assignmentInProgress(): boolean {
    return [
      LegalMatterStatus.Unassigned,
      LegalMatterStatus.Assigning,
      LegalMatterStatus.Withdrawn,
    ].includes(this.legalMatter.status)
  }

  canBeCanceled(): boolean {
    return [
      LegalMatterStatus.Unassigned,
      LegalMatterStatus.Assigning,
      LegalMatterStatus.Assigned,
    ].includes(this.legalMatter.status)
  }

  canBeClaimed(): boolean {
    return (
      this.legalMatter.status === LegalMatterStatus.Unassigned ||
      this.legalMatter.status === LegalMatterStatus.Withdrawn
    )
  }

  canBeClosed(): boolean {
    return this.legalMatter.status === LegalMatterStatus.Assigned
  }

  canBeWithdrawn(): boolean {
    return this.legalMatter.status === LegalMatterStatus.Assigned
  }

  canBeAccepted(): boolean {
    return this.legalMatter.status === LegalMatterStatus.Assigning
  }

  canBeRejected(): boolean {
    return this.legalMatter.status === LegalMatterStatus.Assigning
  }

  statusMatches(status): boolean {
    return this.legalMatter.status === status
  }

  canRejectShare(): boolean {
    return ![
      LegalMatterStatus.Unassigned,
      LegalMatterStatus.Closed,
      LegalMatterStatus.Canceled,
    ].includes(this.legalMatter.status)
  }
}
