import { useLocation } from 'react-router-dom'
import log from 'loglevel'

const DebugRouter = (): null => {
  const location = useLocation()
  if (import.meta.env.MODE === 'development') {
    log.debug(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(location.state)}`,
    )
  }
  return null
}

export default DebugRouter
