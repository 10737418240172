import React, { useState } from 'react'
import Typography from '@mui/material/Typography'

interface Props {
  text: string
  truncateLengthCharacters?: number
  truncateSymbol?: string
}

const TruncateText = ({
  text,
  truncateLengthCharacters = 300,
  truncateSymbol = '...',
}: Props): JSX.Element => {
  const [showFullText, setShowFullText] = useState(false)
  const toggleShowFullText = (): void => {
    setShowFullText(!showFullText)
  }
  return (
    <>
      {showFullText ? text : text?.slice(0, truncateLengthCharacters)}
      {text.length > truncateLengthCharacters ? (
        <Typography
          onClick={toggleShowFullText}
          component="span"
          variant="body1"
          color="primary"
          sx={{ cursor: 'pointer' }}
        >
          <span data-testid="toggle-description-length">
            {showFullText
              ? ' read less'
              : `${' ' + truncateSymbol + ' ' + 'read more'}`}
          </span>
        </Typography>
      ) : null}
    </>
  )
}

export default TruncateText
