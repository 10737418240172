import AnonymousUser from 'models/AnonymousUser'
import FirmUser from 'models/FirmUser'
import LawyerUser from 'models/LawyerUser'
import SubscriberUser from 'models/SubscriberUser'
import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Route,
} from 'react-router-dom'
import AuthPage from 'view/components/pages/Auth'
import CheckoutFinalized from 'view/components/pages/CheckoutFinalized'
import CreateLegalMatter, {
  createLegalMatterLoader,
} from 'view/components/pages/CreateLegalMatter'
import FirmLegalMatter from 'view/components/pages/FirmLegalMatter'
import FirmLegalMatterListing from 'view/components/pages/FirmLegalMatterListing'
import FirmManagementPage from 'view/components/pages/FirmManagement'
import LawyerLegalMatter from 'view/components/pages/LawyerLegalMatter'
import LawyerLegalMatterListing from 'view/components/pages/LawyerLegalMatterListing'
import FirmInvite from 'view/components/pages/FirmInvite'
import PasswordReset from 'view/components/pages/PasswordReset'
import SubscriptionPage from 'view/components/pages/SubscriptionPage'
import SignedOut from 'view/components/pages/SignedOut'
import SubscriberLegalMatter from 'view/components/pages/SubscriberLegalMatter'
import SubscriberLegalMatterListing from 'view/components/pages/SubscriberLegalMatterListing'
import SubscriberRegistration from 'view/components/pages/SubscriberRegistration'
import FirmUserRegistration, {
  registerFirmUserLoader,
} from 'view/components/pages/FirmUserRegistration'
import { type UserTypes } from 'utils/useAccessRules'
import RedirectHandler from 'view/components/atoms/RedirectHandler'
import UserProfile from 'view/components/pages/UserProfile'
import AuthProviderUser from 'models/AuthProviderUser'
import CheckoutPage from 'view/components/pages/Checkout'
import LegalServiceSelection from 'view/components/pages/LegalServiceSelection'
import SignInWithEmailLink from 'view/components/pages/SignInWithEmailLink'
import ErrorScreen from 'view/components/organisms/ErrorScreen'
import PageLayout from 'view/components/templates/PageLayout'

export interface RouteEntry {
  path: string
  name: string
  navbar?: boolean
  allowedUserTypes?: UserTypes
  requiresRole?: string
}

const _routes: Record<string, RouteEntry> = {
  signedOut: {
    path: '/signed-out',
    name: 'Page visible to users after they sign out',
    allowedUserTypes: [AnonymousUser],
  },
  login: {
    path: '/login',
    name: 'Sign In',
    allowedUserTypes: [AnonymousUser],
    navbar: true,
  },
  signup: {
    path: '/sign-up',
    name: 'Sign Up',
    allowedUserTypes: [AuthProviderUser],
  },
  signInWithEmailLink: {
    path: '/login/email-link',
    name: 'Sign in with email link',
    allowedUserTypes: [AnonymousUser],
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'Forgot Password',
    allowedUserTypes: [AnonymousUser],
  },
  firmUserSignup: {
    path: '/firms/sign-up/:userKind/:inviteKey',
    name: 'Sign Up',
    allowedUserTypes: [AuthProviderUser],
  },
  // @TODO this should go away when legal matter routes for firm
  // users and lawyer users are unified into a common route
  lawyerUserSignup: {
    path: '/lawyers/sign-up/:userKind/:inviteKey',
    name: 'Sign Up',
    allowedUserTypes: [AuthProviderUser],
  },
  firmLegalMatterListing: {
    path: '/firms/legal-matters',
    name: 'Legal Matters',
    allowedUserTypes: [FirmUser],
    navbar: true,
  },
  firmLegalMatterDetail: {
    path: '/firms/legal-matters/:legalMatterKey',
    name: 'Legal Matter details',
    allowedUserTypes: [FirmUser],
  },
  createLegalMatter: {
    path: '/legal-matters/:legalMatterKind/:legalMatterKindKey',
    name: 'Request Legal Service',
    allowedUserTypes: [SubscriberUser],
    requiresRole: 'legalMatters.creator',
  },
  legalServiceSelection: {
    path: '/create-matter-screen',
    name: 'Create Legal Matter',
    allowedUserTypes: [SubscriberUser],
    requiresRole: 'legalMatters.creator',
  },
  lawyerLegalMatterListing: {
    path: '/lawyers/legal-matters',
    name: 'My Legal Matters',
    allowedUserTypes: [LawyerUser],
    navbar: true,
  },
  lawyerLegalMatterDetail: {
    path: '/lawyers/legal-matters/:legalMatterKey',
    name: 'Legal Matter details',
    allowedUserTypes: [LawyerUser],
  },
  firmInvite: {
    path: '/invites/:inviteKey',
    name: 'Invitation to LegalFix',
    allowedUserTypes: [
      AnonymousUser,
      AuthProviderUser,
      FirmUser,
      LawyerUser,
      SubscriberUser,
    ],
  },
  subscriberLegalMatterListing: {
    path: '/subscribers/legal-matters',
    name: 'My Legal Matters',
    allowedUserTypes: [SubscriberUser],
    navbar: true,
  },
  subscriberLegalMatterDetail: {
    path: '/subscribers/legal-matters/:legalMatterKey',
    name: 'Legal Matter details',
    allowedUserTypes: [SubscriberUser],
  },
  checkoutSuccess: {
    path: '/checkout/success/:SESSION_ID',
    name: 'Checkout success',
  },
  checkoutFailure: {
    path: '/checkout/failure/',
    name: 'Checkout failure',
  },
  checkoutManageProducts: {
    path: '/checkout/manage',
    name: 'Manage Plans',
    allowedUserTypes: [SubscriberUser],
  },
  firmManagement: {
    path: '/firms/management',
    name: 'Firm Management',
    requiresRole: 'firms.manager',
    navbar: true,
  },
  userProfile: {
    path: '/profile',
    name: 'Your Profile',
    allowedUserTypes: [FirmUser, LawyerUser, SubscriberUser],
  },
  userSubscriptionStatus: {
    path: '/profile/subscription',
    name: 'Your Subscription',
    allowedUserTypes: [SubscriberUser],
  },
}

interface RouteWithParam extends RouteEntry {
  param: (param: any) => string
}

const param = (path: string) => (param: any) => generatePath(path, param)

export const routes: Record<string, RouteWithParam> = Object.keys(
  _routes,
).reduce((acc, key) => {
  const obj = { ..._routes[key], param: param(_routes[key].path) }
  acc[key] = obj
  return acc
}, {})

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RedirectHandler />} errorElement={<PageLayout />}>
      <Route
        path="/error-not-found"
        element={<ErrorScreen content="Page not found" />}
      />
      <Route path={routes.signedOut.path} element={<SignedOut />} />
      <Route path={routes.login.path} element={<AuthPage />} />
      <Route path={routes.forgotPassword.path} element={<PasswordReset />} />
      <Route path={routes.firmInvite.path} element={<FirmInvite />} />
      <Route path={routes.userProfile.path} element={<UserProfile />} />
      <Route path={routes.signup.path} element={<SubscriberRegistration />} />
      <Route
        path={routes.signInWithEmailLink.path}
        element={<SignInWithEmailLink />}
      />
      <Route
        path={routes.firmUserSignup.path}
        loader={registerFirmUserLoader}
        element={<FirmUserRegistration />}
        errorElement={<ErrorScreen content="Something went wrong." />}
      />
      <Route
        path={routes.lawyerUserSignup.path}
        loader={registerFirmUserLoader}
        element={<FirmUserRegistration />}
        errorElement={<ErrorScreen content="Something went wrong." />}
      />
      <Route
        path={routes.lawyerLegalMatterListing.path}
        element={<LawyerLegalMatterListing />}
      />
      <Route
        path={routes.lawyerLegalMatterDetail.path}
        element={<LawyerLegalMatter />}
      />
      <Route
        path={routes.firmLegalMatterListing.path}
        element={<FirmLegalMatterListing />}
      />
      <Route
        path={routes.firmLegalMatterDetail.path}
        element={<FirmLegalMatter />}
      />
      <Route
        path={routes.userSubscriptionStatus.path}
        element={<SubscriptionPage />}
      />
      <Route
        path={routes.checkoutManageProducts.path}
        element={<CheckoutPage />}
      />
      <Route
        path={routes.checkoutSuccess.path}
        element={<CheckoutFinalized />}
      />
      <Route
        path={routes.checkoutFailure.path}
        element={<CheckoutFinalized />}
      />
      <Route
        path={routes.createLegalMatter.path}
        element={<CreateLegalMatter />}
        loader={createLegalMatterLoader}
        errorElement={<ErrorScreen content="Page not found" />}
      />
      <Route
        path={routes.legalServiceSelection.path}
        element={<LegalServiceSelection />}
        errorElement={<ErrorScreen content="Page not found" />}
      />
      <Route
        path={routes.subscriberLegalMatterListing.path}
        element={<SubscriberLegalMatterListing />}
      />
      <Route
        path={routes.subscriberLegalMatterDetail.path}
        element={<SubscriberLegalMatter />}
      />
      <Route
        path={routes.firmManagement.path}
        element={<FirmManagementPage />}
      />
    </Route>,
  ),
)

export default router
