type Props = Record<string, any>

const useTestId = (props: Props): Record<string, string> => {
  if (props['data-testid'] !== undefined) {
    return { 'data-testid': props['data-testid'] }
  }
  return {}
}

export default useTestId
