import {
  type RJSFValidationError,
  type SubmitButtonProps,
  getSubmitButtonOptions,
} from '@rjsf/utils'
import validator from '@rjsf/validator-ajv8'
import log from 'loglevel'
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  JsonFormOperation,
  type JsonFormResponse,
} from '../../../@types/generated/graphql'
import { transformFormErrorsToArray } from '../../../utils/ErrorTransformer'
import {
  type JsonFormRequest,
  type JsonFormData,
  type JsonFormError,
} from '../../../@types/JsonSchemaForm'
import { JsonFormLoading } from '../molecules/LoadingSkeletons'
import RJSFMarkdownField from '../rjsf/RJSFMarkdownField'
import RJSFAddressField from '../rjsf/RJSFAddressField'
import RJSFPhoneField from '../rjsf/RJSFPhoneField'
import RJSFLabelField from '../rjsf/RJSFLabelField'
import RJSFForm from '../atoms/RJSFForm'

interface JsonFormProps {
  onFetch: (request: JsonFormRequest) => Promise<void>
  submissionErrors?: JsonFormError | undefined
  isSubmitting?: boolean
  isLoading: boolean
  onSubmit: (formData: JsonFormData, totalSteps?: number) => void
  form: JsonFormResponse | undefined
  readonly: boolean
}

const JsonSchemaForm = ({
  form,
  submissionErrors,
  isSubmitting = false,
  isLoading,
  onSubmit,
  onFetch,
  readonly,
}: JsonFormProps): JSX.Element => {
  const [formData, setFormData] = useState<JsonFormData>({})
  const currentStep = form?.step ?? 0

  useEffect(() => {
    // Suppresses the webpack warning when the error is regarding the ResizeObserver
    // This is thrown by the rjsf library and currently there is no workaround for this.
    // This only appears in dev environment
    window.addEventListener('error', (e) => {
      if (
        e.message ===
        'ResizeObserver loop completed with undelivered notifications.'
      ) {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay',
        )
        if (resizeObserverErr != null) {
          resizeObserverErr.setAttribute('style', 'display: none')
        }
        if (resizeObserverErrDiv != null) {
          resizeObserverErrDiv.setAttribute('style', 'display: none')
        }
      }
    })
  }, [])

  const submitStep = (intendedStep: number, data?): void => {
    let intendedOp: JsonFormOperation = JsonFormOperation.Next
    let sendRequest = true
    const formDataSnapshot = { ...formData, [currentStep]: data }
    setFormData({ ...formDataSnapshot })

    if (intendedStep < currentStep) {
      intendedOp = JsonFormOperation.Previous
    } else if (intendedStep === currentStep) {
      sendRequest = false
    }

    if (sendRequest) {
      const currentFormData = formDataSnapshot[currentStep]?.formData
      const nextFormData = formDataSnapshot[intendedStep]?.formData

      void (async function () {
        await onFetch({
          currentStepData:
            currentFormData !== undefined
              ? JSON.parse(JSON.stringify(currentFormData, null, 2))
              : undefined,
          nextStepData:
            nextFormData !== undefined
              ? JSON.parse(JSON.stringify(nextFormData, null, 2))
              : undefined,
          step: intendedStep,
          operation: intendedOp,
        })
      })()
    }
  }

  const formatErrorMessage = (error: string): string => {
    // Replace all underscores and dots with spaces in a single regex
    const cleanedStr = error.replace(/[_.]/g, ' ')

    // Split the string into words
    const words = cleanedStr?.trim().split(' ')

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    )

    // Check if there's a need to add a hyphen
    if (capitalizedWords?.length >= 3) {
      const firstPart = capitalizedWords.slice(0, 2).join(' ')
      const secondPart = capitalizedWords.slice(2).join(' ')
      return `${firstPart} - ${secondPart},`
    } else {
      return capitalizedWords?.join(' ')
    }
  }

  const transformErrors = (errors, uiSchema): RJSFValidationError[] => {
    return errors.map((error) => {
      if (error.name === 'pattern') {
        error.message = 'Please input the value in correct format'
        const fieldNames = error.stack.match(/'(.*?)'/)
        if (fieldNames?.length > 0) {
          error.stack = `${fieldNames[1] as string} must be in correct format`
        }
      } else if (error.name === 'const') {
        const substr = error.property as string
        const completeError = formatErrorMessage(substr)
        error.message = 'Please choose a value'
        error.stack = `${completeError} is a required field.`
      } else if (error.name === 'required') {
        const substr = error.property as string
        const completeError = formatErrorMessage(substr)
        error.message = 'Please choose a value'
        error.stack = `${completeError} is a required field`
      } else if (error.name === 'if') {
        const substr = error.property as string
        const completeError = formatErrorMessage(substr)
        error.message = 'Please review the input'
        error.stack = `${completeError} is a required field`
      } else if (
        error.name === 'oneOf' ||
        error.name === 'allOf' ||
        error.name === 'anyOf'
      ) {
        error.message = 'Please review the input'
        error.stack = 'Please provide the required values'
      }
      return error
    })
  }

  const SubmitButton =
    (readonly = false) =>
    (props: SubmitButtonProps): any => {
      if (form !== undefined) {
        const { uiSchema } = props
        const { norender } = getSubmitButtonOptions(uiSchema)
        const finalStep = currentStep + 1 === form.totalSteps
        const buttonText = finalStep ? 'Submit' : 'Next'
        const hideSubmit = finalStep && readonly

        if (norender === true) return null

        return (
          <Box
            sx={{
              marginBottom: 4,
            }}
          >
            {currentStep > 0 && (
              <Button
                type="button"
                onClick={() => {
                  submitStep(currentStep - 1, formData[currentStep])
                }}
              >
                Prev
              </Button>
            )}
            {!hideSubmit && (
              <Button type="submit" variant="contained">
                {buttonText}
              </Button>
            )}
          </Box>
        )
      }
    }

  if (form === undefined || isSubmitting || isLoading) {
    return <JsonFormLoading />
  }

  const validationErrors = transformFormErrorsToArray(
    form?.validationErrors ?? submissionErrors?.userErrors,
    submissionErrors?.message,
  )

  return (
    <>
      {validationErrors?.map((e, idx) => {
        return (
          <Typography
            key={`error-${idx}`}
            variant="subtitle2"
            sx={{ color: 'error.main' }}
          >
            {e}
          </Typography>
        )
      })}
      <RJSFForm
        readonly={readonly}
        schema={JSON.parse(form.form).data}
        uiSchema={JSON.parse(form.uiSchema).data}
        formData={
          formData[currentStep]?.formData ?? JSON.parse(form.defaultData).data
        }
        validator={validator}
        noHtml5Validate={readonly}
        fields={{
          '/schemas/markdownDescription': RJSFMarkdownField,
          '/schemas/usAddress': RJSFAddressField,
          '/schemas/usPhone': RJSFPhoneField,
          '/schemas/labelField': RJSFLabelField,
        }}
        transformErrors={transformErrors}
        templates={{
          ButtonTemplates: { SubmitButton: SubmitButton(readonly) },
        }}
        idPrefix="bl-forms"
        idSeparator=":"
        onChange={(data) => {
          submitStep(currentStep, data)
        }}
        onSubmit={(data) => {
          const intendedStep =
            currentStep + 1 <= form.totalSteps ? currentStep + 1 : currentStep
          if (intendedStep === form.totalSteps) {
            window.scrollTo({ top: 0 })
            onSubmit(formData, form.totalSteps)
          } else {
            submitStep(intendedStep, data)
          }
        }}
        onError={() => {
          log.info('errors')
          window.scrollTo({ top: 0 })
        }}
      />
    </>
  )
}

export default JsonSchemaForm
