import { useContext, useEffect } from 'react'
import { useQuery, NetworkStatus } from '@apollo/client'
import {
  CursorDirection,
  FirmLegalMatterListingDocument,
} from '../../../@types/generated/graphql'
import { LegalMatterStatus } from '../../../@types/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { routes } from 'routes'

import Alert from '@mui/material/Alert'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import LegalMatterListItem from 'view/components/organisms/LegalMatterListItem'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import FiltersLegalMatters from 'view/components/molecules/FiltersLegalMatters'
import ErrorScreen from 'view/components/organisms/ErrorScreen'
import {
  FirmLegalMatterStatusLabels,
  LegalMatterFilterType,
} from 'utils/StatusMapper'
import { useNotificationHandler } from 'utils/useNotificationHandler'
import { EventProviderContext } from '../../providers/EventProvider'
import { paginationLimits } from '../organisms/PaginateItems'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import { useLegalMatterFilters } from 'utils/useLegalMatterFilters'
import { UserProfileContext } from 'view/providers/UserProfileProvider'

const FirmLegalMatterListing = (): JSX.Element => {
  const componentIdentifier = FirmLegalMatterListing.name
  const navigate = useNavigate()
  const { user } = useContext(UserProfileContext)
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      [
        'LegalMatterCreated',
        'LegalMatterAccepted',
        'LegalMatterRejected',
        'LegalMatterShared',
      ],
      [FirmLegalMatterListingDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const { filters, filterName, statusMapper, handleStatusChange } =
    useLegalMatterFilters(LegalMatterFilterType.FIRM_USER, user?.roles)

  const { data, networkStatus, error, fetchMore } = useQuery(
    FirmLegalMatterListingDocument,
    {
      variables: {
        ...filters,
        limit: paginationLimits.legalMatters,
        cursor: null,
        cursorDirection: CursorDirection.Next,
        calendarEventsLimit: paginationLimits.calendarEvents,
        calendarEventsCursor: null,
        calendarEventsCursorDirection: CursorDirection.Next,
      },
      notifyOnNetworkStatusChange: true,
      pollInterval: 10000,
    },
  )

  if (
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables
  ) {
    return <LegalMatterListLoading numberOfSkeletons={5} />
  }

  if (data === undefined) {
    return <div>No data</div>
  }

  if (error !== undefined) {
    return (
      <ErrorScreen content="Sorry, we can not show results, try again later" />
    )
  }

  const onClickLoadMore = async (): Promise<void> => {
    if (data?.legalMatters.pageInfo.endCursor != null) {
      await fetchMore({
        variables: {
          cursor: data?.legalMatters.pageInfo.endCursor,
        },
      })
    }
  }

  const renderNoResultsMessage = (filter: string): JSX.Element => {
    let message
    switch (filter) {
      case 'Referred':
        message = 'There are no referred legal matters.'
        break
      case 'Unassigned':
        message = 'There are no unassigned legal matters.'
        break
      case 'Assigning':
        message = 'There are no assigning legal matters.'
        break
      case 'Completed':
        message = 'There are no completed legal matters.'
        break
      case 'Withdrawn':
        message = 'There are no withdrawn legal matters.'
        break
      case 'All':
      default:
        message = 'There are no legal matters.'
    }
    return (
      <Alert severity="info" icon={false}>
        <Typography gutterBottom>{message}</Typography>
      </Alert>
    )
  }

  return (
    <Box sx={{ paddingBottom: '24px' }}>
      <Typography variant="h1" gutterBottom>
        Legal Matters
      </Typography>
      <Box mb={2}>
        <FiltersLegalMatters
          selectedStatus={filterName}
          statusList={statusMapper}
          onChange={handleStatusChange}
        />
      </Box>
      <Divider sx={{ mb: 2 }} />
      {data?.legalMatters?.edges?.length === 0 &&
        renderNoResultsMessage(filterName)}
      {data?.legalMatters?.edges?.map((legalMatter, idx: number) => {
        return (
          <LegalMatterListItem
            key={`legal-matter-item-${idx}`}
            data-testid="legal-matter-item"
            legalMatter={legalMatter.node}
            legalMatterStatusLabels={FirmLegalMatterStatusLabels}
            textButton={
              legalMatter?.node.status === LegalMatterStatus.Unassigned
                ? 'Assign an Attorney'
                : undefined
            }
            onClickButton={(legalMatter) => {
              navigate(
                routes.firmLegalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
            onClickViewDetails={(legalMatter) => {
              navigate(
                routes.firmLegalMatterDetail.param({
                  legalMatterKey: legalMatter.key,
                }),
              )
            }}
          />
        )
      })}
      {data != null && (
        <LoadingButton
          loading={networkStatus === NetworkStatus.fetchMore}
          disableElevation
          variant="contained"
          color="primary"
          role="button"
          aria-label="Load More"
          onClick={onClickLoadMore}
          disabled={!data.legalMatters.pageInfo.hasNextPage}
        >
          Load More
        </LoadingButton>
      )}
    </Box>
  )
}

export default FirmLegalMatterListing
