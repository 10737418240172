import {
  CursorDirection,
  LegalMatterTasksDocument,
  TaskStatus,
  type Document,
} from '../../../@types/generated/graphql'
import { useState } from 'react'
import useCurrentUser from 'utils/useCurrentUser'
import { paginationLimits } from '../organisms/PaginateItems'
import { getPastDate } from 'utils/dateUtils'
import { NetworkStatus, useQuery } from '@apollo/client'
import TaskFilter from '../organisms/TaskFilters'
import { Alert, Box, Typography } from '@mui/material'
import TaskLoader from '../molecules/TaskLoader'
import TaskListItem from '../organisms/TaskListItem'
import { AttachedFile } from 'utils/useAttachmentGroups'
import AnimatedList from '../molecules/AnimatedList'
import { makeEmptyData } from 'utils/useApolloClient'

interface TaskFilters {
  daysSinceUpdated: number
  tasksLastUpdatedSince?: Date
  tasksCompletedSince?: Date
  tasksAssignedToIn?: string[]
  tasksAssignedToNotIn?: string[]
  tasksStatus?: TaskStatus
}

interface TaskModuleProps {
  legalMatterKey: string | undefined
}

const defaultPastDays: number = 7

const FirmUserTaskListing = ({ legalMatterKey }: TaskModuleProps) => {
  const { user, loading: userLoading } = useCurrentUser()
  const [noTasksMessage, setNoTasksMessage] = useState<string>(
    'There are no tasks assigned to you.',
  )
  const [filters, setFilters] = useState<TaskFilters>({
    daysSinceUpdated: defaultPastDays,
  })
  const { data, networkStatus, refetch, fetchMore } = useQuery(
    LegalMatterTasksDocument,
    {
      notifyOnNetworkStatusChange: true,
      skip: userLoading || legalMatterKey == null,
      variables: {
        legalMatterKey,
        tasksLimit: paginationLimits.tasks,
        tasksAssignedToIn: [user.key],
        tasksCursorDirection: CursorDirection.Next,
        tasksLastUpdatedSince: getPastDate(defaultPastDays, 'UTC'),
      },
    },
  )

  const legalMatter = data?.legalMatters?.edges[0]?.node
  const tasks = legalMatter?.tasks?.edges?.map((edge) => edge.node) ?? []
  const pageInfo = legalMatter?.tasks?.pageInfo ?? makeEmptyData().pageInfo

  return (
    <>
      <TaskFilter
        daysSinceUpdated={filters.daysSinceUpdated}
        tabs={{
          'my-tasks': 'My Tasks',
          'client-tasks': 'Client Tasks',
          'other-attorney-tasks': 'Other Tasks',
        }}
        onChangeFilters={(newFilters) => {
          const targetDate = getPastDate(newFilters.daysSinceUpdated, 'UTC')
          const taskFilters: TaskFilters = {
            ...filters,
            tasksAssignedToIn: undefined,
            tasksAssignedToNotIn: undefined,
            daysSinceUpdated: newFilters.daysSinceUpdated,
            tasksLastUpdatedSince:
              targetDate instanceof Date ? targetDate : undefined,
          }

          switch (newFilters.context) {
            case 'my-tasks':
              if (user?.key != null) {
                taskFilters.tasksAssignedToIn = [user.key]
              }
              setNoTasksMessage('There are no tasks assigned to you.')
              break
            default:
              taskFilters.tasksAssignedToNotIn = [user.key!]
              setNoTasksMessage(
                'There are no tasks assigned to other attorneys.',
              )
              break
          }

          setFilters({ ...taskFilters })
          const { daysSinceUpdated, ...queryParams } = taskFilters
          refetch({ ...queryParams })
        }}
      />
      <Box data-testid="task-list">
        <TaskLoader
          loading={
            userLoading ||
            [
              NetworkStatus.loading,
              NetworkStatus.refetch,
              NetworkStatus.setVariables,
            ].includes(networkStatus)
          }
        >
          <AnimatedList animateItemKey={undefined}>
            {tasks.length > 0 ? (
              tasks.map((task) => (
                <TaskListItem
                  key={task.key}
                  user={user}
                  task={task}
                  attachedFiles={task.documents}
                />
              ))
            ) : (
              <Box sx={{ mt: 2 }}>
                <Alert severity="info" icon={false}>
                  <Typography variant="h5" gutterBottom>
                    {noTasksMessage}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Your attorney can create a tasks for you to upload documents
                    or complete additional information to address the legal
                    matter.
                  </Typography>
                </Alert>
              </Box>
            )}
          </AnimatedList>
        </TaskLoader>
      </Box>
    </>
  )
}

export default FirmUserTaskListing
