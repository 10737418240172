import User from './User'
import { type SubscriberUserProfileFragment } from '../@types/generated/graphql'

type SubscriberUserProfile = SubscriberUserProfileFragment & {
  photoURL: string | null | undefined
}

export default class SubscriberUser extends User {
  key: string
  profile: SubscriberUserProfile

  constructor(profile: SubscriberUserProfile) {
    super(profile.aclIdentities, profile.roles)
    this.key = profile.key
    this.profile = profile
  }

  hasAnActiveSubscription(): boolean {
    return this.profile.subscriptionActive
  }
}
