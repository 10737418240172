import { format, isValid, parseISO } from 'date-fns'

export type DateTimeType = 'DATETIME' | 'DATE' | 'UTC'

export type DateTimeFormatVariant =
  | 'MMMM d, yyyy hh:mm'
  | 'MMMM d, yyyy'
  | 'MM/dd/yyyy'
  | 'MMMM d, yyyy | hh:mm a'
  | 'yyyy-MM-dd'
  | 'HH:mm'

export type DateTimeFormatMapping = Record<DateTimeFormatVariant, string>

export const DateTimeFormat: DateTimeFormatMapping = {
  'MMMM d, yyyy hh:mm': 'MMMM d, yyyy hh:mm',
  'MMMM d, yyyy': 'MMMM d, yyyy',
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'MMMM d, yyyy | hh:mm a': 'MMMM d, yyyy | hh:mm a',
  'yyyy-MM-dd': 'yyy-MM-dd',
  'HH:mm': 'HH:mm',
}

export const DateFormatter = (
  dateTimeValue: Date | string,
  formatString?: string,
  dateTimetype: DateTimeType = 'DATE',
): string | undefined => {
  const parsedDateTime =
    typeof dateTimeValue === 'string' ? parseISO(dateTimeValue) : dateTimeValue
  if (dateTimetype === 'DATETIME') {
    return parsedDateTime !== undefined
      ? format(
          parsedDateTime,
          formatString ?? DateTimeFormat['MMMM d, yyyy hh:mm'],
        )
      : undefined
  }

  if (dateTimetype === 'UTC') {
    return parsedDateTime !== undefined
      ? format(parsedDateTime, 'P')
      : undefined
  }

  return parsedDateTime !== undefined && isValid(parsedDateTime)
    ? format(parsedDateTime, formatString ?? DateTimeFormat['MMMM d, yyyy'])
    : undefined
}

export const getPastDate = (
  daysAgo: number,
  returnFormat: string,
): string | Date | null => {
  const dateTime = new Date()

  if (daysAgo < 0) {
    return null
  }
  dateTime.setDate(dateTime.getDate() - daysAgo)
  dateTime.setHours(0)
  dateTime.setMinutes(0)
  dateTime.setSeconds(0)
  dateTime.setMilliseconds(0)

  switch (returnFormat) {
    case 'dateObject':
      return dateTime
    case 'dateTime':
      return format(dateTime, "yyyy-MM-dd'T'HH:mm:ss")
    case 'UTC':
      return parseISO(dateTime.toISOString())
  }

  return null
}

// Convert HH:MM format to minutes for internal handling
// HH:MM -> minutes
export const convertToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':')
  return parseInt(hours, 10) * 60 + parseInt(minutes, 10)
}

// Convert minutes to hours and in a HH:MM format
// minutes -> HH:MM
export const convertMinutesToHHMM = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${hours.toString().padStart(2, '0')}:${mins
    .toString()
    .padStart(2, '0')}`
}

export const isDateInThePast = (date: string): boolean => {
  return new Date(date) < new Date()
}
