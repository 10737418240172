import { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Drawer from '@mui/material/Drawer'
import Stack from '@mui/material/Stack'
import log from 'loglevel'
import { isEqual } from 'lodash'

export interface AsideSection {
  content: React.ReactNode
  actionButtons?: React.ReactNode[]
}

interface ContentAsideProps {
  open: boolean
  onClose: () => void
  sections: Record<string, AsideSection> | null
  openSection: string
  onSectionMenuClick?: (section: string) => void
  tasks?: React.ReactNode[]
}

const ContentAside = ({
  open,
  sections,
  openSection,
  onClose,
  onSectionMenuClick = undefined,
  tasks = [],
}: ContentAsideProps): JSX.Element | null => {
  const [currentSectionItem, setCurrentSectionItem] =
    useState<AsideSection | null>(null)

  const [prevSections, setPrevSections] = useState<Record<
    string,
    AsideSection
  > | null>(null)
  const [prevOpenSection, setPrevOpenSection] = useState<string | null>(null)
  if (openSection !== prevOpenSection || !isEqual(sections, prevSections)) {
    setPrevOpenSection(openSection)
    setPrevSections(sections)
    if (sections?.[openSection] == null) {
      setCurrentSectionItem(null)
    } else {
      setCurrentSectionItem(sections[openSection])
    }
  }

  if (currentSectionItem == null) {
    return null
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="demo"
      sx={{
        '& > .MuiDrawer-paper': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: { xs: 0, sm: 4 },
          mb: { xs: 4, sm: 0 },
        }}
      >
        <ButtonGroup
          variant="outlined"
          sx={{
            '& > .MuiButtonBase-root': {
              px: 2,
            },
          }}
        >
          {sections != null &&
            Object.keys(sections).map((sectionKey) => (
              <Button
                key={sectionKey}
                color={sectionKey === openSection ? 'success' : 'primary'}
                onClick={() => {
                  log.warn('Setting current section', sectionKey)
                  onSectionMenuClick?.(sectionKey)
                }}
              >
                {sectionKey}
              </Button>
            ))}
        </ButtonGroup>
      </Box>
      <Box sx={{ width: { sm: 850 }, px: 4, pt: { sm: 4 }, pb: 10 }}>
        {currentSectionItem?.content}
      </Box>
      {currentSectionItem?.actionButtons != null || tasks?.length > 0 ? (
        <Stack
          spacing={2}
          direction="column"
          justifyContent="flex-start"
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: 'primary.main',
            py: 2,
            pl: 4,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            {currentSectionItem?.actionButtons?.map(
              (Component: React.ReactNode) =>
                Component != null ? Component : null,
            )}
          </Stack>
          {tasks?.map((Task: React.ReactNode, idx) => (
            <Stack key={idx} direction="row" spacing={2} alignItems="center">
              {Task}
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Drawer>
  )
}

export default ContentAside
