import { useEffect, useState } from 'react'
import { firebaseDb } from 'firebaseConfig'
import { ref, onValue } from 'firebase/database'

export const useConnectionMonitor = (): { connected: boolean } => {
  const [connected, setConnected] = useState<boolean>(true)

  useEffect(() => {
    const connectedRef = ref(firebaseDb, '.info/connected')
    let lastFirebaseConnect
    return onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        // connection is sussessfull
        lastFirebaseConnect = Date.now()
        setConnected(true)
      } else {
        // This avoids triggering the disconnect on page reloads
        if (lastFirebaseConnect != null) {
          // connection failed
          setConnected(false)
        }
      }
    })
  })

  return { connected }
}
