import { gql } from '../../../@types/generated/gql'
import {
  JsonFormType,
  type MutationError,
} from '../../../@types/generated/graphql'
import IntakeFormStep from '../forms/IntakeFormStep'
import { type IntakeMutationProps } from '../../../@types/JsonSchemaForm'
import { useMutation } from '@apollo/client'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { routes } from 'routes'
import { useContext } from 'react'
import { useFirmInvite } from 'utils/useFirmInvite'
import { signOut, refreshUserToken } from 'firebaseConfig'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'

const userKindFormsMap: Record<string, JsonFormType> = {
  firm_user: JsonFormType.FirmUserRegistration,
  lawyer_user: JsonFormType.LawyerUserRegistration,
}

export const registerFirmUserLoader = ({ params }): Record<string, string> => {
  if (
    params?.userKind != null &&
    params.userKind in userKindFormsMap &&
    params?.inviteKey != null
  ) {
    return {
      userKind: params.userKind,
      inviteKey: params.inviteKey,
    }
  } else {
    throw new Error('Not Found')
  }
}

const firmUserFormMutation = gql(/* GraphQL */ `
  mutation FirmUserCreate(
    $inviteRef: Key!
    $data: [JSON!]!
    $formType: JSONFormType!
  ) {
    firmUserCreate(inviteRef: $inviteRef, data: $data, formType: $formType) {
      ... on FirmUserMutationResponse {
        code
        success
        message
        user {
          ... on LawyerUser {
            email
            firstName
            gender
            key
            lastName
            middleName
            phone
            firm {
              key
              name
            }
            practiceAreas {
              key
              category
              value
            }
          }
          ... on FirmUser {
            email
            firstName
            gender
            key
            lastName
            middleName
            phone
            firm {
              key
              name
            }
          }
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const FirmUserRegistration = (): JSX.Element => {
  const navigate = useNavigate()
  const { userKind, inviteKey }: any = useLoaderData()
  const { user } = useContext(UserProfileContext)
  const { data: inviteData, loading } = useFirmInvite({
    variables: {
      inviteRef: inviteKey,
    },
  })

  const [
    createFirmUserRegForm,
    {
      client,
      data: createFirmUserRegFormResponse,
      loading: createFirmUserRegMutationLoading,
    },
  ] = useMutation(firmUserFormMutation, {
    update: (cache, { data }) => {
      client
        .resetStore()
        .then(() => {
          if (data?.firmUserCreate.success === true)
            void refreshUserToken().then(() => {
              if (
                userKindFormsMap[userKind] === JsonFormType.FirmUserRegistration
              ) {
                navigate(routes.firmLegalMatterListing.path)
              } else {
                navigate(routes.lawyerLegalMatterListing.path)
              }
            })
        })
        .catch(() => {
          throw Error('Cache could not be reset.')
        })
    },
  })

  if (loading) {
    return <LegalMatterListLoading numberOfSkeletons={1} />
  }

  if (!loading && user.profile.email !== inviteData?.firmsUserInvite.email) {
    return (
      <Alert severity="error" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="signup-restricted-state-notice">
          Email doesn't match with the invite. Sign out and use the email from
          your invite.
        </Typography>
        <Button
          color="inherit"
          size="small"
          onClick={() => {
            void signOut().then(() => {
              navigate(`/firms/invite/${inviteKey as string}`)
            })
          }}
        >
          Back to invite
        </Button>
      </Alert>
    )
  }

  const onSubmit = ({ formData }: IntakeMutationProps): void => {
    void createFirmUserRegForm({
      variables: {
        inviteRef: inviteKey,
        formType: userKindFormsMap[userKind],
        data: formData,
      },
    })
  }
  const errors = createFirmUserRegFormResponse?.firmUserCreate as MutationError

  return (
    <>
      <IntakeFormStep
        formType={userKindFormsMap[userKind]}
        onSubmit={onSubmit}
        submissionErrors={errors}
        submissionSuccess={
          createFirmUserRegFormResponse?.firmUserCreate.success
        }
        submitting={createFirmUserRegMutationLoading}
        presetData={{
          userInfo: {
            email: user.profile.email,
          },
        }}
        presetUiSchema={{
          userInfo: {
            email: {
              'ui:readonly': true,
            },
          },
        }}
      />
    </>
  )
}

export default FirmUserRegistration
