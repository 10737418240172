import { useSnackbar } from 'notistack'
import { Notifications } from '../@types/Notification'

export interface NotificationEventHandler {
  enqueueNotification: (message: string) => void
}

export const useNotificationHandler = (): NotificationEventHandler => {
  const { enqueueSnackbar } = useSnackbar()
  const enqueueNotification = (notificationType: string): void => {
    notificationType in Notifications &&
      enqueueSnackbar(Notifications[notificationType].message, {
        variant: 'info',
      })
  }

  return { enqueueNotification }
}
