import React, { useState } from 'react'
import { sendResetPasswordEmail, fetchSignInMethods } from 'firebaseConfig'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import { type AlertColor } from '@mui/material'

interface AlertMessage {
  severity: AlertColor
  message: string
}

const PasswordReset = (): JSX.Element => {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [alert, setAlert] = useState<AlertMessage>()
  const [openAlert, setOpenAlert] = useState(Boolean)

  const handleResetPassword = async (): Promise<any> => {
    // Check if the email exists in Firebase authentication.
    const methods = await fetchSignInMethods(email)
    // handle this conditional in the firebaseconfig instead of here, here just receive a response and display the alert
    if (methods?.length > 0) {
      let response
      try {
        response = await sendResetPasswordEmail(email)
      } catch {
        setAlert({
          severity: 'error',
          message: response.message,
        })
      }
    }
    setAlert({
      severity: 'info',
      message:
        'If the email has an account registered, a password reset email will be sent.',
    })
    setOpenAlert(true)
  }

  const handleEmailChange = (e): void => {
    const enteredEmail = e.target.value
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const isValid = emailRegex.test(enteredEmail)
    setEmail(enteredEmail)
    setIsEmailValid(isValid)
  }

  const onCloseAlert = (): void => {
    setOpenAlert(false)
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      {openAlert && (
        <Box data-testid="passwordReset-message">
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onCloseAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            severity={alert?.severity}
            sx={{ mb: 2 }}
          >
            {alert?.message}
          </Alert>
        </Box>
      )}
      <Grid item>
        <Typography
          justifyContent="center"
          variant="h1"
          align="center"
          gutterBottom
          data-testid="passwordReset-title"
        >
          Forgot your password?
        </Typography>
        <Typography
          justifyContent="center"
          align="center"
          sx={{ marginBottom: '20px' }}
          data-testid="passwordReset-subtitle"
        >
          Enter the email address associated to your account, and we'll send you
          a link to reset your password.
        </Typography>
        <TextField
          type="email"
          label="Enter your email"
          value={email}
          fullWidth
          onChange={handleEmailChange}
          helperText={
            !isEmailValid && email.length > 0 ? 'Invalid email address' : ''
          }
          data-testid="passwordReset-input"
        />
        <LoadingButton
          variant="contained"
          type="submit"
          data-testid="passwordReset-button"
          sx={{ height: '48px', marginTop: '16px' }}
          fullWidth
          disabled={!isEmailValid}
          onClick={handleResetPassword}
          role="button"
        >
          Reset Password
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default PasswordReset
