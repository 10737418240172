import { useState } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import ItemBoundingBox from '../atoms/ItemBoundingBox'
import { type LawyerUser } from '../../../@types/generated/graphql'
import styles from './AssignLawyerCard.module.css'
import LoadingButton from '@mui/lab/LoadingButton'

interface AssignLawyerCardProps {
  lawyer: Pick<
    LawyerUser,
    | 'firstName'
    | 'lastName'
    | 'numberOfAssignedLegalMatters'
    | 'numberOfAssigningLegalMatters'
    | 'practiceAreas'
  >
  isAssigning?: boolean
  assignButtonText?: string
  onClickAssign?: () => void
}

const AssignLawyerCard = ({
  lawyer,
  isAssigning = false,
  assignButtonText = 'Assign',
  onClickAssign,
}: AssignLawyerCardProps): JSX.Element => {
  const numAreasToShow = 5
  const practiceAreas = lawyer.practiceAreas != null ? lawyer.practiceAreas : []
  const numLawyerPracticeAreas = practiceAreas.length
  const numberOfAssignedLegalMatters = lawyer?.numberOfAssignedLegalMatters ?? 0
  const numberOfAssigningLegalMatters =
    lawyer?.numberOfAssigningLegalMatters ?? 0
  const [showPA, setShowPA] = useState(false)
  let visiblePracticeAreas, hiddenPracticeAreas
  if (numLawyerPracticeAreas > numAreasToShow) {
    visiblePracticeAreas = practiceAreas
      .slice(0, numAreasToShow)
      .map((area) => `${area?.value}`)
      .join(', ')
    hiddenPracticeAreas = practiceAreas
      .slice(numAreasToShow)
      .map((area) => `${area?.value}`)
      .join(', ')
  } else {
    visiblePracticeAreas = practiceAreas
      .map((area) => `${area?.value}`)
      .join(', ')
  }

  const handleShowPA = (): void => {
    setShowPA(true)
  }
  const handleHidePA = (): void => {
    setShowPA(false)
  }

  return (
    <ItemBoundingBox>
      <Grid data-testid="assignlawyercard-box">
        <Typography data-testid="assignlawyercard-name">{`${lawyer?.firstName} ${lawyer?.lastName}`}</Typography>
        <Typography data-testid="assignlawyercard-practice-areas" mt={2} mb={2}>
          {numLawyerPracticeAreas > 0 &&
            numLawyerPracticeAreas <= numAreasToShow && (
              <span> {visiblePracticeAreas} </span>
            )}
          {numLawyerPracticeAreas > 0 &&
            numLawyerPracticeAreas > numAreasToShow &&
            !showPA && <span>{visiblePracticeAreas}</span>}
          {numLawyerPracticeAreas > 0 &&
            numLawyerPracticeAreas > numAreasToShow &&
            showPA && (
              <span>
                {visiblePracticeAreas}, {hiddenPracticeAreas}
              </span>
            )}
        </Typography>
        {numLawyerPracticeAreas > 0 &&
          numLawyerPracticeAreas > numAreasToShow &&
          showPA && <span onClick={handleHidePA}>Hide</span>}
        {numLawyerPracticeAreas > 0 &&
          numLawyerPracticeAreas > numAreasToShow &&
          !showPA && <span onClick={handleShowPA}>Show All</span>}
        <Typography
          className={styles.workingOn}
          data-testid="assignlawyercard-working-on-legalMatters"
        >
          Currently working on {numberOfAssignedLegalMatters} legal{' '}
          {numberOfAssignedLegalMatters === 1 ? 'matter' : 'matters'}
          {numberOfAssigningLegalMatters > 0
            ? `, and ${numberOfAssigningLegalMatters} pending for acceptance or rejection`
            : ''}
        </Typography>
        <LoadingButton
          loading={isAssigning}
          disableElevation
          variant="contained"
          color="primary"
          role="button"
          aria-label="Assign"
          data-testid="assignlawyercard-assign-button"
          onClick={onClickAssign}
        >
          {assignButtonText}
        </LoadingButton>
      </Grid>
    </ItemBoundingBox>
  )
}

export default AssignLawyerCard
