import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'

interface Props {
  content: string
  redirectURL?: string | undefined
  textButton?: string | undefined
}

const ErrorScreen = ({
  content,
  redirectURL,
  textButton,
}: Props): JSX.Element => {
  const navigate = useNavigate()
  const handleBack = (): void => {
    navigate(-1)
  }

  return (
    <Box>
      <Typography variant="h1">{content}</Typography>
      <Link onClick={handleBack}>Go Back</Link>
      {textButton !== undefined && (
        <>
          <Button variant="contained" color="primary" href={redirectURL}>
            {textButton}
          </Button>
        </>
      )}
    </Box>
  )
}

export default ErrorScreen
