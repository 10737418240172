import { styled } from '@mui/material/styles'
import Form from '@rjsf/mui'

const RJSFForm = styled(Form)(
  ({ readonly, theme }) =>
    readonly !== undefined &&
    readonly && {
      '& .MuiInputBase-root.Mui-disabled, & .MuiTypography-root.Mui-disabled': {
        '-webkit-text-fill-color': theme.palette.grey[900],
        '& > input, & > div, & > textarea': {
          '-webkit-text-fill-color': theme.palette.grey[900],
        },
        '& > div, & > span': {
          opacity: 1,
        },
      },
    },
)

export default RJSFForm
