import React from 'react'
import {
  type CalendarEvent,
  CalendarEventStatus,
  LegalMatterCalendarEventFragment,
} from '../../../@types/generated/graphql'
import styles from './CalendarEventListItem.module.css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Grid from '@mui/material/Grid'
import ListItemBoxHeading from 'view/components/molecules/ListItemBoxHeading'
import ItemBoundingBox from 'view/components/atoms/ItemBoundingBox'
import EventDateTime from 'view/components/molecules/EventDateTime'
import { DateFormatter } from 'utils/dateUtils'
import EventForm from '../molecules/EventForm'
import NameValueLabel from '../atoms/NameValueLabel'
import TruncateText from '../atoms/TruncateText'

interface Props {
  calendarEvent: LegalMatterCalendarEventFragment
  buttonText?: string
  isEditing?: boolean
  isSubmitting?: boolean
  serverError?: string[]
  disableMenu?: boolean
  onClickMenu?: (event: React.MouseEvent) => void
  onChangeStatus?: (status: CalendarEventStatus) => void
  onChangeEventStartTime?: (value?: Date) => void
  onChangeEventEndTime?: (value?: Date) => void
  onClickSaveButton?: (CalendarEvent) => void
  onClickCancelEditButton?: (value?: string) => void
}

const CalendarEventListItem = ({
  calendarEvent,
  buttonText,
  isEditing,
  isSubmitting = false,
  disableMenu = false,
  serverError,
  onClickMenu,
  onChangeStatus,
  onChangeEventStartTime,
  onChangeEventEndTime,
  onClickSaveButton,
  onClickCancelEditButton,
}: Props): JSX.Element => {
  const { status, lastUpdatedTimestamp } = calendarEvent
  const formattedUpdateTime = lastUpdatedTimestamp
    ? DateFormatter(lastUpdatedTimestamp)
    : 'N/A'

  return (
    <>
      {isEditing === true ? (
        <EventForm
          eventKey={calendarEvent.key}
          eventName={calendarEvent.name}
          eventDescription={calendarEvent.description ?? ''}
          startTime={calendarEvent.startTime ?? undefined}
          endTime={calendarEvent.endTime ?? undefined}
          serverError={serverError}
          isSubmitting={isSubmitting}
          onChangeEventStartTime={onChangeEventStartTime}
          onChangeEventEndTime={onChangeEventEndTime}
          onClickButton={(data) => {
            if (onClickSaveButton != null) {
              onClickSaveButton(data)
            }
          }}
          onClickCancelButton={() => {
            if (onClickCancelEditButton != null) {
              onClickCancelEditButton(calendarEvent.key)
            }
          }}
        ></EventForm>
      ) : (
        <ItemBoundingBox variant="outlineBox" data-testid="calendar-event-item">
          <ListItemBoxHeading
            disableMenu={disableMenu}
            title={calendarEvent.name}
            subtitle={
              formattedUpdateTime != null
                ? `Created: ${formattedUpdateTime}`
                : undefined
            }
            onClickMenu={onClickMenu ?? undefined}
          />
          <hr style={{ marginTop: 16, marginBottom: 16 }} />
          {calendarEvent.description !== null ? (
            <Box
              mb={4}
              data-testid="calendareventlistitem-description-container"
            >
              <NameValueLabel
                data-testid="calendareventlistitem-description"
                typographyProps={{ paragraph: true }}
                displayDefaultValue={false}
              >
                <TruncateText
                  text={calendarEvent.description ?? ''}
                  truncateLengthCharacters={200}
                />
              </NameValueLabel>
            </Box>
          ) : null}
          <Grid container>
            <Grid item sm={12} lg={8}>
              <EventDateTime
                startTime={calendarEvent.startTime ?? undefined}
                endTime={calendarEvent.endTime ?? undefined}
                onChangeStartTime={onChangeEventStartTime}
                onChangeEndTime={onChangeEventEndTime}
              />
            </Grid>
            <Grid item sm={12} lg={4}>
              {status !== CalendarEventStatus.Completed &&
                buttonText !== undefined && (
                  <Button
                    variant="contained"
                    color="primary"
                    data-testid="calendareventlistitem-done-btn"
                    onClick={() => {
                      if (onChangeStatus != null) {
                        onChangeStatus(CalendarEventStatus.Completed)
                      }
                    }}
                  >
                    {buttonText}
                  </Button>
                )}
              {status === CalendarEventStatus.Completed && (
                <div className={styles.taskDone}>
                  <CheckCircleOutlineIcon
                    className={styles.doneIcon}
                    color="success"
                    aria-label="Event was completed"
                    data-testid="calendareventlistitem-done-icon"
                  />{' '}
                  Done
                </div>
              )}
            </Grid>
          </Grid>
        </ItemBoundingBox>
      )}
    </>
  )
}

export default CalendarEventListItem
