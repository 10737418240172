import { gql } from '../../../@types/generated/gql'
import {
  type OnboardingRestartMutation,
  PaymentProcessorAccountsDocument,
  type StripeOnboardingRestartMutationResponse,
  type Scalars,
} from '../../../@types/generated/graphql'
import { useQuery, useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import StripeAccountDetails from 'view/components/molecules/StripeAccountDetails'
import log from 'loglevel'
import useCurrentUser from 'utils/useCurrentUser'
import LoadingButton from '@mui/lab/LoadingButton'
import { useState } from 'react'

const stripeAccountCreateMutation = gql(/* GraphQL */ `
  mutation StripeAccountCreate($firmKey: Key!, $accountType: String!) {
    stripeAccountCreate(firmKey: $firmKey, accountType: $accountType) {
      ... on StripeAccountCreateMutationResponse {
        success
      }
      ... on MutationError {
        success
        message
      }
    }
  }
`)

const stripeOnboardingLinksCreateMutation = gql(/* GraphQL */ `
  mutation OnboardingRestart(
    $accountKey: Key!
    $refreshUrl: String!
    $returnUrl: String!
  ) {
    stripeAccountOnboardingLinksCreate(
      accountKey: $accountKey
      refreshUrl: $refreshUrl
      returnUrl: $returnUrl
    ) {
      ... on StripeOnboardingRestartMutationResponse {
        success
        url
      }
      ... on MutationError {
        success
        message
      }
    }
  }
`)

const FirmManagementPage = (): JSX.Element | null => {
  const { user } = useCurrentUser()
  const location = useLocation()
  const [loadingState, setLoadingState] = useState<boolean>(false)

  const firmKey: Scalars['Key'] = user.profile?.firm?.key

  const {
    data: paymentAccountsQuery,
    loading: paymentAccountsLoading,
    error: paymentAccountsError,
  } = useQuery(PaymentProcessorAccountsDocument, {
    variables: { firmRef: firmKey },
    fetchPolicy: 'no-cache',
    skip: firmKey == null,
  })

  const [createStripeAccount, { loading: createStripeAccountLoading }] =
    useMutation(stripeAccountCreateMutation, {
      refetchQueries: [PaymentProcessorAccountsDocument],
    })
  const currentPath = `${window.location.protocol}//${window.location.host}${location.pathname}`
  const [getOnboardingLink, { loading: getOnboardingLinkLoading }] =
    useMutation(stripeOnboardingLinksCreateMutation, {
      refetchQueries: [PaymentProcessorAccountsDocument],
    })

  if (paymentAccountsError != null) {
    log.error(paymentAccountsError)
    return null
  }

  if (paymentAccountsLoading) {
    return (
      <div>
        <h1>Firm Management</h1>
        <p>Loading...</p>
      </div>
    )
  }

  if (
    paymentAccountsQuery?.paymentProcessorAccounts == null ||
    paymentAccountsQuery?.paymentProcessorAccounts.length === 0
  ) {
    return (
      <div>
        <h1>Payment Accounts configuration</h1>
        <Alert severity="info">
          You haven't linked a Stripe account yet. Choose one of the options and
          click the button to enable payments.
        </Alert>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h1" color="text.primary" gutterBottom>
                  Express Stripe Account
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Use this option if your firm does not currently use Stripe for
                  invoicing.
                </Typography>
              </CardContent>
              <CardActions sx={{ ml: 1, mb: 1 }}>
                <LoadingButton
                  loading={createStripeAccountLoading}
                  disableElevation
                  variant="contained"
                  color="primary"
                  role="button"
                  aria-label="Create an Express Stripe account"
                  onClick={() => {
                    void createStripeAccount({
                      onCompleted: (data) => {
                        log.warn('StripeAccountCreate', data)
                      },
                      onError: (error) => {
                        enqueueSnackbar(
                          `Error creating stripe account: ${error.message}, try again in a few seconds`,
                          { variant: 'error' },
                        )
                      },
                      variables: {
                        firmKey,
                        accountType: 'express',
                      },
                    })
                  }}
                  disabled={createStripeAccountLoading}
                >
                  Create an Express Stripe account
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h1" color="text.primary" gutterBottom>
                  Existing Stripe Account
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Use this option if your firm currently manages payments
                  through Stripe.
                </Typography>
              </CardContent>
              <CardActions sx={{ ml: 1, mb: 1 }}>
                <LoadingButton
                  loading={createStripeAccountLoading}
                  disableElevation
                  variant="contained"
                  color="primary"
                  role="button"
                  aria-label="Link Stripe account"
                  onClick={() => {
                    void createStripeAccount({
                      onCompleted: (data) => {
                        log.warn('StripeAccountCreate', data)
                      },
                      onError: (error) => {
                        enqueueSnackbar(
                          `Error creating stripe account: ${error.message}, try again in a few seconds`,
                          { variant: 'error' },
                        )
                      },
                      variables: {
                        firmKey,
                        accountType: 'standard',
                      },
                    })
                  }}
                  disabled={createStripeAccountLoading}
                >
                  Link Stripe account
                </LoadingButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }

  if (paymentAccountsQuery?.paymentProcessorAccounts.length > 1) {
    return (
      <div>
        <h1>Payment Accounts configuration</h1>
        <Alert severity="info">
          It seems you have configure more than one payment account. Please
          contact support.
        </Alert>
      </div>
    )
  }

  const account = paymentAccountsQuery.paymentProcessorAccounts[0]

  if (paymentAccountsQuery?.paymentProcessorAccounts.length === 1) {
    return (
      <Box maxWidth="600px">
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h3" gutterBottom>
              Stripe account status
            </Typography>
            <StripeAccountDetails
              accountFragment={paymentAccountsQuery.paymentProcessorAccounts[0]}
            />
          </CardContent>
          <CardActions>
            {account.detailsSubmitted ? (
              'All details are up to date'
            ) : (
              <LoadingButton
                loading={
                  getOnboardingLinkLoading
                    ? getOnboardingLinkLoading
                    : loadingState
                }
                disableElevation
                variant="contained"
                color="primary"
                role="button"
                aria-label="Complete details in the Stripe Dashboard"
                onClick={() => {
                  void getOnboardingLink({
                    onCompleted: (data: OnboardingRestartMutation) => {
                      log.warn('OnboardLink', data)
                      if (data.stripeAccountOnboardingLinksCreate.success) {
                        setLoadingState(true)
                        const response =
                          data.stripeAccountOnboardingLinksCreate as StripeOnboardingRestartMutationResponse
                        window.location.href = response.url
                      }
                    },
                    onError: (error) => {
                      log.error('Error getting onboarding link', error)
                    },
                    variables: {
                      accountKey: account.key,
                      refreshUrl: currentPath,
                      returnUrl: currentPath,
                    },
                  })
                }}
                disabled={
                  getOnboardingLinkLoading
                    ? getOnboardingLinkLoading
                    : loadingState
                }
              >
                Complete details in the Stripe Dashboard
              </LoadingButton>
            )}
          </CardActions>
        </Card>
      </Box>
    )
  }

  return null
}

export default FirmManagementPage
