/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  type StorageReference,
  type StorageError,
  type UploadTaskSnapshot,
  type UploadMetadata,
} from 'firebase/storage'
import { cloudStorage } from 'firebaseConfig'
import type { FileProgress, UploadError } from '../@types/Upload'
import config from 'config'

interface FirebaseStorageHook {
  key: string | null
  progress: FileProgress | null
  downloadUrl: string | null
  storagePath: string | null
  error: UploadError | null
}

export interface CloudStorageFile {
  key: string
  correlationKey?: string
  file?: File
  metadata
  basePath: string
}

const initialState: FirebaseStorageHook = {
  key: null,
  progress: null,
  downloadUrl: null,
  storagePath: null,
  error: null,
}

export type FirebaseStorageHookProps = CloudStorageFile | null
export const useFirebaseStorage = (
  props: FirebaseStorageHookProps,
): FirebaseStorageHook => {
  const [{ progress, error, downloadUrl, storagePath }, setState] =
    useState(initialState)

  const handleSuccess = (snapshotRef: StorageReference): void => {
    setState((prevState) => ({
      ...prevState,
      storagePath: snapshotRef.fullPath,
    }))
  }

  const handleError = (error): void => {
    // Perhaps creating and interface compatible with StorageError to use with setError ?
    const customError = { status: 'error', message: error.message }
    setState((prevState) => ({ ...prevState, error: customError }))
  }

  const resolveDownloadURL = (snapshotRef: StorageReference): void => {
    try {
      getDownloadURL(snapshotRef)
        .then((url) => {
          setState((prevState) => ({ ...prevState, downloadUrl: url }))
        })
        .catch((error: StorageError) => {
          handleError(error)
        })
    } catch (e) {
      handleError(e)
    }
  }
  useEffect(() => {
    if (props != null) {
      const storageRef = ref(cloudStorage, `${props.basePath}/${props.key}`)
      const allowedExtensions = new RegExp(
        config.fileUploadConfig.allowedFileTypes,
        'i',
      )
      if (props.file === undefined) {
        resolveDownloadURL(storageRef)
      } else {
        if (
          props.file.size < config.fileUploadConfig.maxFileSize &&
          allowedExtensions.exec(props.file.name) !== null
        ) {
          const requestMetadata: UploadMetadata = props.metadata
          const uploadTask = uploadBytesResumable(
            storageRef,
            props.file,
            requestMetadata,
          )
          uploadTask.on(
            'state_changed',
            (snapshot: UploadTaskSnapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              setState((prevState) => ({
                ...prevState,
                progress: { key: props.correlationKey, progress },
              }))
            },
            (error: StorageError) => {
              handleError(error)
            },
            () => {
              handleSuccess(uploadTask.snapshot.ref)
            },
          )
        } else {
          handleError({ message: config.fileUploadConfig.helpText })
        }
      }
    } else {
      setState(initialState)
    }
  }, [props])

  return {
    key: props?.key ?? null,
    progress,
    downloadUrl,
    storagePath,
    error,
  }
}
