import React from 'react'
import styles from './Navbar.module.css'
import logo from '../../assets/logo.webp'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import Drawer from '@mui/material/Drawer'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Box from '@mui/material/Box'
import config from 'config'

export interface Item {
  /** Text that will appear to the user **/
  name: string
  /** link href **/
  path: string
}

export interface Props {
  /** Items to display in the nav bar **/
  items: Item[]
  /** Display the avatar profile component **/
  avatarMenu?: JSX.Element | null
}

export const NavBar = ({ items = [], avatarMenu }: Props): JSX.Element => {
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const drawerContainer =
    window !== undefined ? () => window.document.body : undefined

  const handleDrawerToggle = (): void => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawerComponent = (
    <div onClick={handleDrawerToggle}>
      <Box sx={{ width: 250 }}>
        <List data-testid="navbar-drawer-menu">
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>
          {avatarMenu &&
            React.cloneElement(avatarMenu, {
              mobileDisplay: true,
            })}
        </List>
        <Divider />
      </Box>

      <List>
        {items.map((item, idx) => (
          <ListItemButton
            component="a"
            href={item.path}
            key={idx}
            data-testid="navbar-drawer-menu-item"
            role="menuitem"
          >
            <ListItemText
              primary={item.name}
              primaryTypographyProps={{
                fontSize: 16,
                fontWeight: 'bold',
                letterSpacing: 0,
              }}
            />
          </ListItemButton>
        ))}
      </List>
    </div>
  )

  return (
    <div className={styles.root}>
      <AppBar position="fixed" id="navBar">
        <Toolbar className={styles.navstyled}>
          <Container maxWidth="xl">
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}
            >
              <Grid>
                <Link href={config.legalPlansMarketingUrl}>
                  <img
                    className={styles.logoImg}
                    src={logo}
                    alt="LegalFix"
                    data-testid="navbar-logo"
                    aria-label="Legalfix Logo"
                  />
                </Link>
              </Grid>
              <Grid role="navigation" aria-label="Main Menu">
                {items.map((item, idx) => (
                  <Link
                    className={styles.navLink}
                    href={item.path}
                    key={idx}
                    data-testid="navbar-menu-item"
                    role="menuitem"
                  >
                    {item.name}
                  </Link>
                ))}
                {avatarMenu}
              </Grid>
            </Grid>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerToggle}
                sx={{ mr: 0 }}
              >
                <MenuIcon />
              </IconButton>
              <Link
                href={config.legalPlansMarketingUrl}
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  justifyContent: 'center',
                }}
              >
                <img
                  className={styles.logoImg}
                  src={logo}
                  alt="LegalFix"
                  data-testid="navbar-logo"
                  aria-label="Legalfix Logo"
                />
              </Link>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={drawerContainer}
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerComponent}
        </Drawer>
      </nav>
      {/* Used for toolbar offset. See https://mui.com/material-ui/react-app-bar/#fixed-placement */}
      <Toolbar />
    </div>
  )
}

export default NavBar
