import AttachmentIcon from '@mui/icons-material/Attachment'
import DeleteIcon from '@mui/icons-material/Delete'
import Grid from '@mui/material/Grid'
import styles from './FileList.module.css'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import log from 'loglevel'
import { truncate } from 'lodash'
import {
  Permission,
  type Document,
} from '../../../../src/@types/generated/graphql'
import { type AttachedFile } from 'utils/useAttachmentGroups'
import User from 'models/User'
import AclEntity from 'models/AclEntity'

interface Props {
  files?: AttachedFile[]
  maxFilesToDisplay?: number
  fileNameTruncateLength?: number
  filesAttachedLabel?: string
  noFilesAttachedLabel?: string
  user: User
  onPopoverClick?: () => void
  onFileClick?: (unresolvedUrl?: string) => void
  onRemoveFile?: (key: string) => void
}

const FilesList = ({
  files,
  user,
  maxFilesToDisplay = 3,
  fileNameTruncateLength = 25,
  filesAttachedLabel = 'Files attached',
  noFilesAttachedLabel = 'No files attached',
  onPopoverClick,
  onFileClick,
  onRemoveFile,
}: Props): JSX.Element => {
  const fileCount = files?.length ?? 0

  return (
    <Grid container aria-label="Task attachment list">
      <Grid container item spacing={2} my={1}>
        <Grid item>
          <Typography
            variant="subtitle1"
            data-testid="filelist-files-attached-lbl"
          >
            {fileCount > 0 ? filesAttachedLabel : noFilesAttachedLabel}
          </Typography>
        </Grid>
        <Grid item>
          {maxFilesToDisplay !== 0 && fileCount > maxFilesToDisplay ? (
            <Chip onClick={onPopoverClick} label={fileCount} size="small" />
          ) : null}
        </Grid>
      </Grid>
      {files
        ?.slice(0, maxFilesToDisplay === 0 ? files.length : maxFilesToDisplay)
        .map((file, idx) => {
          const aclEntity = new AclEntity(file.acl)
          const canDelete = aclEntity.allows(
            user.aclIdentities,
            Permission.DocumentsDelete,
          )
          return (
            <Grid
              key={`filelist-${idx}`}
              container
              direction={'row'}
              data-testid="filelist-attached-files"
            >
              <Grid item>
                <Typography
                  component={'span'}
                  data-testid="filelist-uploaded-file"
                  className={styles.link}
                  onClick={
                    onFileClick !== undefined
                      ? () => {
                          log.warn('file', file)
                          onFileClick(file.fullPath)
                        }
                      : () => {
                          log.info('click failed')
                        }
                  }
                >
                  {' '}
                  <AttachmentIcon />
                  {truncate(file.name, { length: fileNameTruncateLength })}
                </Typography>
                {onRemoveFile !== undefined && canDelete ? (
                  <DeleteIcon
                    sx={{ mx: 2, alignSelf: 'center' }}
                    fontSize="small"
                    onClick={() => {
                      onRemoveFile(file.key)
                    }}
                    aria-label="delete"
                    data-testid="filelist-remove-file-btn"
                    cursor={'pointer'}
                  />
                ) : null}
              </Grid>
            </Grid>
          )
        })}
    </Grid>
  )
}

export default FilesList
