import { gql } from '../@types/generated/gql'
import { useQuery, type QueryResult } from '@apollo/client'

const firmInvite = gql(/* GraphQL */ `
  query firmInviteQuery($inviteRef: Key) {
    firmsUserInvite(inviteRef: $inviteRef) {
      email
      expiresAt
      firm {
        name
        email
      }
      userType
    }
  }
`)

export const useFirmInvite = (options): QueryResult => {
  return useQuery(firmInvite, { ...options, fetchPolicy: 'no-cache' })
}
