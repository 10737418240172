import { useSearchParams } from 'react-router-dom'
import { every } from 'lodash'
import {
  LegalMatterFilterType,
  userStatusMapper,
  type Filter,
} from 'utils/StatusMapper'

const rolesNotFilteringAssignment = ['firms.creator', 'legalMatters.creator']
const assignedLawyerFilter = (
  currentFilter: string,
  userKey: string | null,
  userRoles?: string[],
): string | undefined => {
  const isLawyer = every(
    userRoles ?? [],
    (role) => !rolesNotFilteringAssignment.includes(role),
  )
  if (isLawyer && currentFilter === 'Mine') {
    return userKey ?? undefined
  }
  return undefined
}

export const useLegalMatterFilters = (
  legalMatterFilter: LegalMatterFilterType,
  userRoles: string[] | undefined,
  userKey?: string | null,
) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const filterName = searchParams.get('filter') ?? 'All'
  const firmRef = searchParams.get('firmRef') ?? null

  let statusMapper = userStatusMapper[legalMatterFilter]
  if (
    legalMatterFilter == LegalMatterFilterType.LAWYER_USER &&
    userRoles?.includes('firms.creator')
  ) {
    statusMapper = userStatusMapper[LegalMatterFilterType.ADMIN_LAWYER_USER]
  }

  const filters: Filter =
    statusMapper[filterName]?.filter ?? statusMapper.All.filter

  const handleStatusChange = (value: string): void => {
    searchParams.set('filter', value)
    setSearchParams(searchParams)
  }

  const handleFirmChange = (firmRef: string | null) => {
    if (firmRef != null) {
      searchParams.set('firmRef', firmRef)
    } else {
      searchParams.delete('firmRef')
    }
    setSearchParams(searchParams)
  }

  return {
    assignedLawyerRef: assignedLawyerFilter(
      filterName,
      userKey ?? null,
      userRoles,
    ),
    firmRef,
    filters,
    filterName,
    statusMapper,
    handleStatusChange,
    handleFirmChange,
  }
}
