import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ErrorScreen from 'view/components/organisms/ErrorScreen'
import { UserProfileContext } from '../../providers/UserProfileProvider'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Grid'
import { LegatMatterIntakeKindsDocument } from '../../../@types/generated/graphql'
import { useQuery } from '@apollo/client'
import { LegalMatterListLoading } from '../molecules/LoadingSkeletons'
import { useSubscribedProducts } from 'utils/useSubscribedProducts'
import { kebabCase } from 'lodash'

interface SubcategoryType {
  key: string
  subcategoryName: string
  intakeForm: string
}

interface CategoryIntakeType {
  category: string
  subcategories: SubcategoryType[]
}

const LegalServiceSelection = (): JSX.Element => {
  const navigate = useNavigate()
  const { user } = useContext(UserProfileContext)
  const { subscribedProductsLoading, subscribedProducts } =
    useSubscribedProducts(user.key)

  if (user === null) return <ErrorScreen content="No user found" />

  const { data, loading, error } = useQuery(LegatMatterIntakeKindsDocument, {
    variables: {
      productIds: subscribedProducts.map((product) => product.id),
    },
    skip: subscribedProducts.length === 0,
  })

  if (loading || subscribedProductsLoading) {
    return <LegalMatterListLoading numberOfSkeletons={1} />
  }

  if (error !== undefined) {
    return (
      <ErrorScreen content="Sorry, we can not show results, try again later" />
    )
  }

  let gropedByCategory: CategoryIntakeType[] = []
  if (data?.legalMatterKinds && data?.legalMatterKinds.length > 0) {
    gropedByCategory = Object.values(
      data?.legalMatterKinds?.reduce((acc, item) => {
        if (!acc[item.category]) {
          // Create a new category if it doesn't exist
          acc[item.category] = {
            category: item.category,
            subcategories: [],
          }
        }

        // Add the subcategory to the relevant category
        acc[item.category].subcategories.push({
          subcategoryName: item.name,
          key: item.key,
          intakeForm: item.intakeForm,
        })

        return acc
      }, {}),
    )

    gropedByCategory.sort((a, b) => a.category.localeCompare(b.category))
  }

  return (
    <>
      <Typography
        variant="h1"
        gutterBottom
        data-testid="CreateLegalMatter-pageTitle"
      >
        Please Select Your Requested Legal Service
      </Typography>
      {gropedByCategory.length > 0 &&
        gropedByCategory.map((categoryItem) => {
          if (categoryItem.category === 'N/A') {
            return
          }

          return (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={categoryItem.category}
              >
                {' '}
                {categoryItem.category}
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {categoryItem.subcategories
                    .sort((a, b) =>
                      a.subcategoryName.localeCompare(b.subcategoryName),
                    )
                    .map((subcategory) => {
                      return (
                        <Grid
                          item
                          sx={{ padding: '0px 8px', marginBottom: '16px' }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              navigate(
                                `/legal-matters/${kebabCase(categoryItem.category)}/${subcategory.key}`,
                              )
                            }}
                            data-testid="subcategory-button"
                          >
                            {subcategory.subcategoryName}
                          </Button>
                        </Grid>
                      )
                    })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })}
    </>
  )
}

export default LegalServiceSelection
