import { useContext, useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { UserProfileContext } from 'view/providers/UserProfileProvider'
import useAccessRules from './useAccessRules'
import type User from 'models/User'
import AuthProviderUser from 'models/AuthProviderUser'
import FirmUser from 'models/FirmUser'
import LawyerUser from 'models/LawyerUser'
import SubscriberUser from 'models/SubscriberUser'
import { routes } from 'routes'
import log from 'loglevel'
import AnonymousUser from 'models/AnonymousUser'

const buildRedirectUrl = (pathname: string, search: string): string => {
  if (
    ![
      '/',
      routes.login.path,
      routes.signedOut.path,
      routes.forgotPassword.path,
    ].includes(pathname)
  ) {
    const encodedPathname = encodeURIComponent(pathname)
    const queryParams = new URLSearchParams(search)
    const encodedSearch = queryParams.toString()
    const encoded =
      encodedSearch !== ''
        ? `${encodedPathname}?${encodedSearch}`
        : encodedPathname
    return `?redirect=${encoded}`
  }

  return ''
}

export const getRedirectUrl = (search: string): string | null => {
  if (search.includes('redirect=')) {
    const urlParams = new URLSearchParams(location.search)
    const redirect = urlParams.get('redirect')
    if (redirect != null) {
      const decoded = decodeURIComponent(redirect)
      return decoded
    }
  }
  return null
}

const getUserBasePath = (user: User): string => {
  if (user instanceof LawyerUser) {
    return '/lawyers'
  } else if (user instanceof FirmUser) {
    return '/firms'
  } else if (user instanceof SubscriberUser) {
    return '/subscribers'
  }
  return '/'
}

const isMismatchedSharePath = (
  pathname: string,
  userBasePath: string,
): boolean => {
  if (pathname === '/' || pathname === routes.login.path) {
    return false
  }
  return !pathname.startsWith(userBasePath)
}

export const useDefaultRoute = (user: User): string => {
  const { pathname, search } = useLocation()
  const redirectUrl = getRedirectUrl(search)
  const userBasePath = getUserBasePath(user)

  log.debug('Redirect URL:', redirectUrl)
  if (redirectUrl != null) {
    return redirectUrl
  }

  if (isMismatchedSharePath(pathname, userBasePath)) {
    return pathname.replace(/^\/(lawyers|firms|subscribers)/, userBasePath)
  }

  if (user instanceof AuthProviderUser) {
    return routes.signup.path
  } else if (user instanceof LawyerUser) {
    return routes.lawyerLegalMatterListing.path
  } else if (user instanceof FirmUser) {
    return routes.firmLegalMatterListing.path
  } else if (user instanceof SubscriberUser) {
    if (!user.hasAnActiveSubscription()) {
      return routes.checkoutManageProducts.path
    }
    return routes.subscriberLegalMatterListing.path
  } else if (user instanceof AnonymousUser) {
    // Attach a redirect query param to redirect later
    return routes.login.path + buildRedirectUrl(pathname, search)
  }

  return '/'
}

const useRedirect = (): {
  loading: boolean
  redirect?: string
} => {
  const { pathname } = useLocation()
  const { loading, user } = useContext(UserProfileContext)
  const { userIsOneOf, userHasRole } = useAccessRules(user)
  const defaultPath = useDefaultRoute(user)

  const allRoutes = useMemo(() => Object.values(routes), [])

  return useMemo(() => {
    if (loading) {
      return { loading }
    }

    log.debug(`useRedirect: [${pathname}]`)

    if (pathname === '/') {
      return {
        loading: false,
        redirect: defaultPath,
      }
    }

    const rule = allRoutes.find((route) => {
      return matchPath(route.path, pathname)
    })

    if (rule == null) {
      return { loading: false, redirect: '/error-not-found' }
    }

    if (rule.allowedUserTypes != null && !userIsOneOf(rule.allowedUserTypes)) {
      log.debug('User is not one of the allowed types', pathname)

      return {
        loading: false,
        redirect: defaultPath,
      }
    }

    if (rule.requiresRole != null && !userHasRole(rule.requiresRole)) {
      log.debug('User does not have the required role', pathname)
      return {
        loading: false,
        redirect: defaultPath,
      }
    }

    log.debug('No redirect', pathname)
    return { loading: false }
  }, [loading, pathname, userIsOneOf, userHasRole, allRoutes, defaultPath])
}

export default useRedirect
