import AnonymousUser from './AnonymousUser'
import FirmUser from './FirmUser'
import LawyerUser from './LawyerUser'
import LegalMatter from './LegalMatter'
import AuthProviderUser from './AuthProviderUser'
import SubscriberUser from './SubscriberUser'

const models = {
  LegalMatter,
  AnonymousUser,
  AuthProviderUser,
  SubscriberUser,
  LawyerUser,
  FirmUser,
}

export default models
