import AclIdentity from './AclIdentity'

export default class User {
  key: string | null = null
  roles: string[]
  aclIdentities: AclIdentity[]
  profile: any = null

  constructor(
    aclIdentities: string[],
    roles: string[],
    key: string | null = null,
  ) {
    this.key = key
    this.aclIdentities = aclIdentities.map((o) => AclIdentity.fromString(o))
    this.roles = roles ?? []
  }
}
