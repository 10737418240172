import type { Task, Document } from '../@types/generated/graphql'
import { useState } from 'react'
import { type CloudStorageFile } from './useFirebaseStorage'
import { decodeKey } from './KeyFormatter'
import { nanoid } from 'nanoid'
import log from 'loglevel'

export type AttachedFile = Pick<
  Document,
  'key' | 'name' | 'fullPath' | 'acl'
> & {
  fileObject?: File
}

type AttachmentGroupType = 'LEGALMATTER' | 'TASK'
type DocumentsAttachedToGroup = Record<string, AttachedFile[]>

interface useAttachmentGroupsReturnType {
  attachmentsByGroupId: (
    attachmentGroup: AttachmentGroupType,
    entityId?: string,
  ) => AttachedFile[]
  resolveDocumentKey: (url?: string) => string
  mapDocumentsToAttachmentGroup: (
    legalMatterTasks: Task[],
    legalMatterDocuments: AttachedFile[],
  ) => void
  prepareStorageObject: (
    attachmentGroup: AttachmentGroupType,
    createdBy: string,
    correlationId: string,
    files: AttachedFile[],
    basePath: string,
    fileToRemoveKey?: string,
  ) => CloudStorageFile[]

  updateAttachedDocuments: (
    correlationId: string,
    files?: AttachedFile[],
    fileToRemoveKey?: string,
  ) => void
}

export const useAttachmentGroups = (): useAttachmentGroupsReturnType => {
  const [attachedDocumentsToTask, setAttachedDocumentsToTask] =
    useState<DocumentsAttachedToGroup>({})

  const [attachedDocumentsToLegalMatter, setAttachedDocumentsToLegalMatter] =
    useState<DocumentsAttachedToGroup>({})

  const mapDocumentsToAttachmentGroup = (
    legalMatterTasks: Task[],
    legalMatterDocuments: AttachedFile[],
  ): void => {
    const taskDocuments: DocumentsAttachedToGroup = {}
    legalMatterTasks?.forEach((task) => {
      const documents = task.documents?.map((doc) => ({
        key: doc.key,
        fullPath: doc.fullPath,
        name: doc.name,
      }))
      taskDocuments[task.key] = documents
    })
    setAttachedDocumentsToTask(taskDocuments)

    const legalMatterDocs: DocumentsAttachedToGroup = {}
    const attachmentGroupType: AttachmentGroupType = 'LEGALMATTER'
    legalMatterDocs[attachmentGroupType] = legalMatterDocuments?.map((doc) => ({
      key: doc.key,
      fullPath: doc.fullPath,
      name: doc.name,
    }))
    setAttachedDocumentsToLegalMatter(legalMatterDocs)
  }

  const attachmentsByGroupId = (
    attachmentGroupType: AttachmentGroupType,
    entityId?: string,
  ): AttachedFile[] => {
    if (attachmentGroupType === 'TASK' && entityId !== undefined)
      return attachedDocumentsToTask[entityId]
    else if (attachmentGroupType === 'LEGALMATTER')
      return attachedDocumentsToLegalMatter[attachmentGroupType]
    return []
  }

  const updateAttachedDocuments = (
    correlationId: string,
    files?: AttachedFile[],
  ): void => {
    const { entity } = decodeKey(correlationId)
    if (files !== undefined) {
      if (entity === 'tasks') {
        const attachedFiles = attachmentsByGroupId('TASK', correlationId) ?? []
        files.forEach((file) => {
          if (attachedFiles.find((f) => f.name === file.name) === undefined)
            attachedFiles.push(file)
        })
        setAttachedDocumentsToTask({
          ...attachedDocumentsToTask,
          [correlationId]: [...attachedFiles],
        })
      }
      if (entity === 'legalMatters') {
        const attachedFiles =
          attachmentsByGroupId('LEGALMATTER', correlationId) ?? []
        files.forEach((file) => {
          if (attachedFiles.find((f) => f.name === file.name) === undefined)
            attachedFiles.push(file)
        })
        setAttachedDocumentsToLegalMatter({
          ...attachedDocumentsToLegalMatter,
          [correlationId]: [
            ...(attachedDocumentsToLegalMatter[correlationId] ?? [
              ...attachedFiles,
            ]),
          ],
        })
      }
    }
  }

  const prepareStorageObject = (
    attachmentGroup: AttachmentGroupType,
    createdBy: string,
    correlationId: string,
    files: AttachedFile[],
    basePath: string,
  ): CloudStorageFile[] => {
    const filesToPersist = files.map((file) => {
      const fileKey = nanoid()
      const metadata = {
        contentType: file.fileObject?.type,
        contentDisposition: `attachment; filename=${file.name}`,
        customMetadata: prepareCustomMetadata(
          attachmentGroup,
          createdBy,
          correlationId,
          file.name,
        ),
      }
      const storageObject: CloudStorageFile = {
        key: fileKey,
        correlationKey: correlationId,
        basePath,
        metadata,
        file: file?.fileObject,
      }
      return storageObject
    })
    return filesToPersist
  }

  const prepareCustomMetadata = (
    attachmentGroup: AttachmentGroupType,
    createdBy: string,
    correlationId?: string,
    fileName?: string,
  ): Record<string, unknown> => {
    if (attachmentGroup === 'TASK')
      if (correlationId === undefined && fileName === undefined)
        return {
          task_id: null as unknown as string,
        }
      else {
        return {
          task_id: correlationId,
          created_by: createdBy,
          file_name: fileName,
        }
      }
    else if (attachmentGroup === 'LEGALMATTER')
      if (correlationId === undefined && fileName === undefined)
        return {
          legalMatter_id: null as unknown as string,
        }
      else {
        if (correlationId == null) {
          throw new Error('correlationId is required')
        }
        const { id, entity } = decodeKey(correlationId)
        return {
          legalMatter_id: `${entity}/${id}`,
          created_by: createdBy,
          file_name: fileName,
        }
      }
    return {}
  }

  const resolveDocumentKey = (url?: string): string => {
    const [entity, id, resolvedKey] = url?.split?.('/') ?? []
    log.info(entity, id, resolvedKey)
    return resolvedKey
  }

  return {
    attachmentsByGroupId,
    mapDocumentsToAttachmentGroup,
    prepareStorageObject,
    resolveDocumentKey,
    updateAttachedDocuments,
  }
}
