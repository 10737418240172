import { auth } from 'firebaseConfig'
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { routes } from 'routes'
import { getRedirectUrl } from 'utils/useRedirect'
import log from 'loglevel'
import Alert from '@mui/material/Alert'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const SignInWithEmailLink = (): JSX.Element => {
  const navigate = useNavigate()
  if (isSignInWithEmailLink(auth, window.location.href)) {
    const email = window.localStorage.getItem('lfixSignInEmail')
    if (email == null) {
      return (
        <Alert severity="error">
          <Typography variant="h2">Invalid Sign-in Link</Typography>
          This sign-in email is no longer valid, please try{' '}
          <Link href={routes.login.path}>requesting one again</Link>.
        </Alert>
      )
    }

    signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        window.localStorage.removeItem('lfixSignInEmail')
        const redirectUrl = getRedirectUrl(window.location.search) ?? '/'
        navigate(redirectUrl)
        enqueueSnackbar('Signed in successfully, redirecting you ...', {
          variant: 'success',
        })
      })
      .catch((error) => {
        log.error('Error signing in with email link', error)
        enqueueSnackbar('Sorry, we can not sign you in', { variant: 'error' })
      })
    return <Alert severity="info">Signing in...</Alert>
  } else {
    return <Alert severity="info">Invalid link :(</Alert>
  }
}

export default SignInWithEmailLink
