import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const SignedOut = (): JSX.Element => {
  return (
    <Box
      mt={20}
      justifyContent="center"
      flexGrow={1}
      sx={{ textAlign: 'center', display: 'flex' }}
    >
      <Alert severity="success" icon={false} sx={{ mb: 3 }}>
        <Typography variant="h2">You have been signed out.</Typography>
        <Typography variant="h6">Thank you for using LegalFix</Typography>
      </Alert>
    </Box>
  )
}

export default SignedOut
