import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { analytics } from 'firebaseConfig'
import { logEvent } from 'firebase/analytics'

export const GaTracker = (): null => {
  const location = useLocation()
  const [prevRoute, setPrevRoute] = useState<string | null>(null)

  if (prevRoute !== location.pathname) {
    logEvent(analytics, 'page_view')
    setPrevRoute(location.pathname)
  }
  return null
}

export default GaTracker
