import React, { type PropsWithChildren } from 'react'
import { every, some } from 'lodash'

export interface Props {
  fallback?: React.ReactNode | null
  requires?: boolean
  requiresAll?: boolean[]
  requiresAny?: boolean[]
}

export const Access = ({
  children,
  requires,
  requiresAll,
  requiresAny,
  fallback = null,
}: PropsWithChildren<Props>): JSX.Element => {
  if (requires === true) {
    return <>{children}</>
  } else if (requiresAll != null && every(requiresAll)) {
    return <>{children}</>
  } else if (requiresAny != null && some(requiresAny)) {
    return <>{children}</>
  }
  return <>{fallback}</>
}

export default Access
