import React from 'react'
import Typography, { type TypographyProps } from '@mui/material/Typography'
import Chip from '@mui/material/Chip'

interface NameValueLabelProps<C extends React.ElementType> {
  name?: string | undefined
  value?: string | undefined
  chipValue?: boolean
  separator?: string
  displayDefaultValue?: boolean
  defaultValue?: string
  children?: React.ReactNode
  typographyProps?: TypographyProps<C, { component?: C }>
}

const NameValueLabel = ({
  name,
  value,
  chipValue = false,
  separator = ':',
  displayDefaultValue = true,
  defaultValue = 'N/A',
  typographyProps,
  children,
  ...props
}: NameValueLabelProps<React.ElementType>): JSX.Element => {
  const resolvedValue = resolveNameValueLabel(
    name,
    value,
    separator,
    displayDefaultValue ? defaultValue : undefined,
  )

  return (
    <Typography {...typographyProps} {...props}>
      {!chipValue && resolvedValue && resolvedValue}
      {chipValue && resolveNameChippedValueLabel(name, value, separator)}
      {children ?? null}
    </Typography>
  )
}

export const resolveNameValueLabel = (
  name?: string,
  value?: string,
  separator = ':',
  defaultValue?: string,
): string | undefined => {
  return `${name !== undefined ? name + separator + ' ' : ''} ${
    value ?? defaultValue ?? ''
  }`
}

export const resolveNameChippedValueLabel = (
  name?: string,
  value?: string,
  separator = ':',
): JSX.Element => {
  const resolveName = name !== undefined ? name + separator + ' ' : ''
  return (
    <>
      {resolveName}
      <Chip label={value} />
    </>
  )
}

export default NameValueLabel
