import { gql, useQuery } from '@apollo/client'
import {
  type Scalars,
  StripeSubscriptionStatus,
  type GetSubscribedProductsQuery,
  type GetSubscribedProductsQueryVariables,
} from '../@types/generated/graphql'

const subscribedProductsQuery = gql(/* GraphQL */ `
  query GetSubscribedProducts(
    $userKey: Key!
    $status: StripeSubscriptionStatus
  ) {
    userSubscriptions(userKey: $userKey, status: $status) {
      subscriptionStatus
      products {
        id
        name
        kind
        price {
          id
          lookupKey
          recurringInterval
          unitAmount
        }
        storyblok {
          name
          slug
          content {
            highlights
            full_document
            summary
          }
        }
      }
    }
  }
`)

export const useSubscribedProducts = (userKey: Scalars['Key']) => {
  const { data, loading } = useQuery<
    GetSubscribedProductsQuery,
    GetSubscribedProductsQueryVariables
  >(subscribedProductsQuery, {
    variables: {
      userKey,
      status: StripeSubscriptionStatus.Active,
    },
    fetchPolicy: 'network-only',
  })

  const activeProducts = data?.userSubscriptions
    // Just to be safe
    .filter((sub) => sub.subscriptionStatus === StripeSubscriptionStatus.Active)
    .map((sub) => sub.products)
    .flat()

  return {
    subscribedProductsLoading: loading,
    subscribedProducts: activeProducts ?? [],
  }
}
