import { useCallback, useMemo, useState } from 'react'
import { every } from 'lodash'
import {
  type ProductInterval,
  type ProductDetails,
  type Price,
} from '../@types/generated/graphql'
import { type Product } from './useProducts'

interface ProductNode {
  parentId: string | null
  siblings: string[] | null
  children: string[]
  prices: Price[]
}

interface CartHookReturnType {
  cartProducts: Product[]
  cartTotals: Record<ProductInterval | 'oneTime', number>
  termsToAccept: string[]
  isItemChecked: (productId: string) => boolean
  isItemDisabled: (productId: string) => boolean
  toggleCartItem: (productId: string) => void
  acceptProductTerms: (productId: string) => void
  rejectProductTerms: (productId: string) => void
}

const removeFromObject = (obj: any, keys: string[]): any => {
  return Object.keys(obj).reduce((acc, key) => {
    if (!keys.includes(key)) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

export const useCart = ({
  productCatalog,
  subscribedProducts,
}: {
  productCatalog: Product[]
  subscribedProducts: ProductDetails[]
}): CartHookReturnType => {
  // Contains a map of subscribed products to help out with resolution logic
  const subscribedProductMap: Record<string, ProductDetails> = useMemo(() => {
    return subscribedProducts.reduce((acc, product) => {
      acc[product.id] = product
      return acc
    }, {})
  }, [subscribedProducts])

  // Contains a map of all products to help out with resolution logic
  const productMap: Record<string, ProductNode> = useMemo(() => {
    const _productMap = {}
    productCatalog.forEach((product: Product) => {
      _productMap[product.id] = {
        parentId: null,
        siblings: [],
        children: product.subproducts ?? [],
        prices: product.prices,
      }
      product.subproducts?.forEach((childId) => {
        _productMap[childId] = {
          ..._productMap[childId],
          parentId: product.id,
          siblings: product.subproducts?.filter((id) => id !== childId),
        } satisfies ProductNode
      })
    })
    return _productMap
  }, [productCatalog])

  // Contains the products that have been added to the cart
  const [cartProducts, setCartProducts] = useState<Record<string, Product>>({})

  // Contains acceptance of terms by productId. Items are added to this map
  // when there is an attempt to add them to the cart. After the terms are accepted
  // the item is added to the cart.
  // The boolean signals if the terms have been or not accepted yet
  const [productTermsAcceptance, setProductTermsAcceptance] = useState<
    Record<string, boolean>
  >({})

  // Map of items that should be checked
  const checkedItems: Record<string, boolean> = useMemo(() => {
    const subscribedIds = subscribedProducts.reduce(
      (acc: Record<string, boolean>, product) => {
        acc[product.id] = true
        return acc
      },
      {},
    )
    const cartIds = Object.keys(cartProducts).reduce((acc, productId) => {
      acc[productId] = true
      return acc
    }, {})
    return { ...subscribedIds, ...cartIds }
  }, [cartProducts, subscribedProducts])

  // Map of items that should be disabled
  const disabledItems: Record<string, boolean> = useMemo(() => {
    return subscribedProducts.reduce(
      (acc: Record<string, boolean>, product: ProductDetails) => {
        acc[product.id] = true
        return acc
      },
      {},
    )
  }, [subscribedProducts])

  // Totals for products contained in the cart
  const cartTotals: Record<ProductInterval | 'oneTime', number> =
    useMemo(() => {
      let subscribedParent: ProductDetails | null = null
      Object.keys(cartProducts).forEach((productId) => {
        const cartItemParentId = productMap[productId].parentId
        if (
          cartItemParentId != null &&
          subscribedProductMap[cartItemParentId] != null
        ) {
          subscribedParent = subscribedProductMap[cartItemParentId]
        }
      })

      return Object.keys(cartProducts).reduce<Record<string, number>>(
        (acc, productId) => {
          const product = productMap[productId]
          if (product == null) return acc

          // We do not allow for multiple recurring intervals in the cart
          // when a parent product has been subscribed monthly or / yearly,
          // any child products need to be subscribed to the same interval
          if (subscribedParent?.price?.recurringInterval != null) {
            if (acc[subscribedParent.price.recurringInterval] == null) {
              acc[subscribedParent.price.recurringInterval] = 0
            }
            acc[subscribedParent.price.recurringInterval] +=
              product.prices.find(
                (price) =>
                  price.recurringInterval ===
                  subscribedParent?.price.recurringInterval,
              )?.unitAmount ?? 0
          } else {
            product.prices.forEach((price) => {
              if (price.recurringInterval != null) {
                if (acc[price.recurringInterval] == null) {
                  acc[price.recurringInterval] = 0
                }
                acc[price.recurringInterval] += price.unitAmount
              } else {
                // One time, non-recurring charges
                acc.oneTime += price.unitAmount
              }
            })
          }
          return acc
        },
        { oneTime: 0 },
      )
    }, [cartProducts, productMap, subscribedProductMap])

  // Subscribed product's term have been accepted, we initialize them so that
  // child product additions do not require acceptance of terms of already purchased
  // products
  if (
    !every(
      Object.keys(subscribedProductMap),
      (subsItem) => productTermsAcceptance[subsItem],
    )
  ) {
    Object.keys(subscribedProductMap).forEach((productId) => {
      setProductTermsAcceptance((prev) => ({ ...prev, [productId]: true }))
    })
  }
  const isItemChecked = (productId: string): boolean => {
    return checkedItems[productId] ?? false
  }

  const isItemDisabled = (productId: string): boolean => {
    return disabledItems[productId] ?? false
  }

  const isItemInCart = (productId: string): boolean => {
    return cartProducts[productId] != null
  }

  const termsAccepted = (productId: string): boolean => {
    return productTermsAcceptance[productId]
  }

  const removeItemFromCart = (productId: string): void => {
    const productNode = productMap[productId]
    // Remove any checked subproducts
    setCartProducts(
      removeFromObject(cartProducts, [productId, ...productNode.children]),
    )
  }

  const computeProductsToAdd = (productId: string): string[] => {
    const productsToAdd = [productId]
    const productNode = productMap[productId]
    // Add the parent plan as it is required for child products
    if (
      productNode.parentId != null &&
      subscribedProductMap[productNode.parentId] == null
    ) {
      productsToAdd.push(productNode.parentId)
    }
    return productsToAdd
  }

  const addProductToCart = (productId: string): void => {
    const product = productCatalog.find((p) => p.id === productId)
    if (product == null) {
      throw new Error(`Product with id ${productId} not found`)
    }
    setCartProducts((prev) => ({ ...prev, [productId]: product }))
  }

  // Cart business logic
  const toggleCartItem = (productId: string): void => {
    if (isItemDisabled(productId)) return
    if (isItemInCart(productId)) {
      removeItemFromCart(productId)
    } else {
      // When adding supplements, we may need to add the parent plan as well
      const productsToAdd = computeProductsToAdd(productId)
      productsToAdd.forEach((productId: string) => {
        if (termsAccepted(productId)) {
          addProductToCart(productId)
        } else {
          setProductTermsAcceptance((prev) => ({ ...prev, [productId]: false }))
          addProductToCart(productId)
        }
      })
    }
  }

  return {
    cartProducts: useMemo(() => Object.values(cartProducts), [cartProducts]),
    cartTotals,
    termsToAccept: useMemo(
      () =>
        Object.keys(productTermsAcceptance).filter(
          (productId) => !productTermsAcceptance[productId],
        ),
      [productTermsAcceptance],
    ),
    isItemChecked: useCallback(isItemChecked, [checkedItems]),
    isItemDisabled: useCallback(isItemDisabled, [disabledItems]),
    toggleCartItem,
    acceptProductTerms: (productId: string): void => {
      setProductTermsAcceptance((prev) => ({ ...prev, [productId]: true }))
      addProductToCart(productId)
    },
    rejectProductTerms: (productId: string): void => {
      const productNode = productMap[productId]
      // Remove from term acceptance
      setProductTermsAcceptance(
        removeFromObject(productTermsAcceptance, [productId]),
      )
      // Remove any checked subproducts
      setCartProducts(
        removeFromObject(cartProducts, [productId, ...productNode.children]),
      )
    },
  }
}

export default useCart
