import { gql, useQuery } from '@apollo/client'
import {
  type Scalars,
  type GetUserSubscriptionsQuery,
  StripeSubscriptionStatus,
} from '../@types/generated/graphql'
import log from 'loglevel'

const userSubscriptionsQuery = gql(/* GraphQL */ `
  query GetUserSubscriptions(
    $userKey: Key!
    $status: StripeSubscriptionStatus
  ) {
    userSubscriptions(userKey: $userKey, status: $status) {
      customerId
      subscriptionId
      subscriptionStatus
      lastInvoiceStatus
      lastEventTimestamp
      currentPeriodEnd
      products {
        id
        name
        kind
        price {
          id
          lookupKey
          recurringInterval
          unitAmount
        }
        storyblok {
          name
          slug
          content {
            highlights
            full_document
            summary
          }
        }
      }
    }
  }
`)

export const useSubscriptions = (
  userKey: string | null,
  status?: StripeSubscriptionStatus,
) => {
  const { data, loading } = useQuery<GetUserSubscriptionsQuery>(
    userSubscriptionsQuery,
    {
      variables: {
        userKey,
        status,
      },
      fetchPolicy: 'network-only',
      skip: userKey == null,
    },
  )

  const hasAnyActiveSubscription: boolean =
    data?.userSubscriptions?.some(
      (sub) => sub.subscriptionStatus === StripeSubscriptionStatus.Active,
    ) ?? false

  return {
    subscriptionsLoading: loading,
    subscriptions: data?.userSubscriptions ?? [],
    hasAnyActiveSubscription,
  }
}
