import { StandardTextFieldProps, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

interface FormInputProps extends StandardTextFieldProps {
  name: string
  control: any
}

const FormInputText = ({ name, control, label, ...rest }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          fullWidth
          label={label}
          {...rest}
        />
      )}
    />
  )
}

export default FormInputText
