import React from 'react'
import Grid from '@mui/material/Grid'

interface Props {
  children: React.ReactNode[]
}

const ThreeColumnLayout = ({ children }: Props): JSX.Element => {
  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        {children[0]}
      </Grid>
      <Grid item md={6}>
        {children[1]}
      </Grid>
      <Grid item md={3}>
        {children[2]}
      </Grid>
    </Grid>
  )
}

export default ThreeColumnLayout
