import React from 'react'
import { TaskSkeleton } from '../molecules/LoadingSkeletons'

const TaskLoader = ({
  loading = true,
  children,
}: {
  loading: boolean
  children: React.ReactNode
}): JSX.Element =>
  loading ? (
    <>
      <TaskSkeleton />
      <TaskSkeleton />
    </>
  ) : (
    <>{children}</>
  )

export default TaskLoader
