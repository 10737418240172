import React from 'react'
import {
  type Scalars,
  TaskChargeType,
  TaskStatus,
  TaskListItemFragment,
} from '../../../@types/generated/graphql'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import styles from './TaskListItem.module.css'
import Box from '@mui/material/Box'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import Chip from '@mui/material/Chip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import Grid from '@mui/material/Grid'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'
import ItemBoundingBox from 'view/components/atoms/ItemBoundingBox'
import ListItemBoxHeading from 'view/components/molecules/ListItemBoxHeading'
import NameValueLabel from 'view/components/atoms/NameValueLabel'
import TimeInput from 'view/components/molecules/TimeInput'
import { DateFormatter } from 'utils/dateUtils'
import FilesList from '../molecules/FilesList'
import ChargeforTaskForm from '../molecules/ChargeforTaskForm'
import TruncateText from '../atoms/TruncateText'
import { type AttachedFile } from 'utils/useAttachmentGroups'
import User from 'models/User'

interface Props {
  task: TaskListItemFragment
  buttonText?: string
  isUpdating?: boolean
  serverError?: string[]
  chargeType?: TaskChargeType
  isUpdatingOutOfPlan?: boolean
  attachedFiles?: AttachedFile[]
  fileUploader?: JSX.Element
  trackedTimeError?: string
  disableMenu?: boolean
  inEditMode?: boolean
  editForm?: JSX.Element
  user: User
  onClickMenu?: (event: React.MouseEvent) => void
  onChangeTrackedTime?: (taskKey: Scalars['Key'], minutes: number) => void
  onClickAttatch?: () => void
  onFileListPopoverClick?: () => void
  onFileClick?: (unresolvedUrl?: string) => void
  onRemoveSelectedFile?: (documentKey: string) => void
  onClickDone?: (key: Scalars['Key'], taskStatus: TaskStatus) => void
  onClickMarkOutOfPlanButton?: (key: Scalars['Key'], data) => void
  onClickMarkInPlanFlatButton?: (key: Scalars['Key'], data) => void
  onClickCancelPlanButton?: (key: Scalars['Key']) => void
  onPlanInfoClick?: (key: Scalars['Key']) => void
  onRemoveFromEditMode?: (key: Scalars['Key']) => void
}

const TaskListItem = ({
  task,
  buttonText,
  isUpdating = false,
  serverError,
  chargeType,
  isUpdatingOutOfPlan,
  attachedFiles,
  fileUploader,
  trackedTimeError,
  disableMenu = false,
  user,
  editForm,
  inEditMode = false,
  onChangeTrackedTime,
  onClickMenu,
  onFileListPopoverClick,
  onFileClick,
  onRemoveSelectedFile,
  onClickDone,
  onClickMarkOutOfPlanButton,
  onClickMarkInPlanFlatButton,
  onClickCancelPlanButton,
  onPlanInfoClick,
}: Props): JSX.Element => {
  const formattedUpdateTime = DateFormatter(task.lastUpdatedTimestamp)
  const isMediumOrMore = useMediaQuery(useTheme().breakpoints.up('md'))

  if (inEditMode) {
    return <>{editForm}</>
  }

  return (
    <ItemBoundingBox variant="outlineBox" data-testid="task-item">
      <ListItemBoxHeading
        title={task.name}
        subtitle={
          formattedUpdateTime != null
            ? `Created: ${formattedUpdateTime}`
            : undefined
        }
        midContent={
          task?.chargeType === TaskChargeType.OutOfPlan ? (
            <Chip
              icon={<ErrorOutlineIcon />}
              color="warning"
              label="Out of plan with fee"
              variant="outlined"
              onClick={
                onPlanInfoClick !== undefined
                  ? () => {
                      onPlanInfoClick(task.key)
                    }
                  : undefined
              }
              data-testid="tasklistitem-outofplan-indicator"
              aria-label="Task is Out of Plan with a fee"
            />
          ) : (
            task.chargeType === TaskChargeType.InPlanFlat && (
              <Chip
                icon={<ErrorOutlineIcon />}
                color="warning"
                label="In plan with flat fee"
                variant="outlined"
                onClick={
                  onPlanInfoClick !== undefined
                    ? () => {
                        onPlanInfoClick(task.key)
                      }
                    : undefined
                }
                data-testid="tasklistitem-inplan-indicator"
                aria-label="Task is In Plan with a Flat Fee"
              />
            )
          )
        }
        onClickMenu={onClickMenu}
        disableMenu={disableMenu}
      />
      <hr style={{ marginTop: 16, marginBottom: 16 }} />
      <Box>
        {task.description != null ? (
          <Box mb={2} data-testid="tasklistitem-description-container">
            <NameValueLabel
              data-testid="tasklistitem-description"
              typographyProps={{ paragraph: true }}
              displayDefaultValue={false}
            >
              <TruncateText
                text={task.description}
                truncateLengthCharacters={200}
              />
            </NameValueLabel>
          </Box>
        ) : null}
        {task.status !== TaskStatus.Completed &&
          chargeType === TaskChargeType.OutOfPlan &&
          onClickMarkOutOfPlanButton !== undefined &&
          onClickCancelPlanButton !== undefined && (
            <Grid
              container
              item
              my={2}
              xs={12}
              sm={12}
              justifyContent={'center'}
            >
              <ChargeforTaskForm
                isSubmitting={isUpdatingOutOfPlan}
                onClickButton={(data) => {
                  onClickMarkOutOfPlanButton(task.key, data)
                }}
                onClickCancelButton={() => {
                  onClickCancelPlanButton(task.key)
                }}
              />
            </Grid>
          )}
        {task.status !== TaskStatus.Completed &&
          chargeType === TaskChargeType.InPlanFlat &&
          onClickMarkInPlanFlatButton !== undefined &&
          onClickCancelPlanButton !== undefined && (
            <Grid
              container
              item
              my={2}
              xs={12}
              sm={12}
              justifyContent={'center'}
            >
              <ChargeforTaskForm
                isSubmitting={isUpdatingOutOfPlan}
                onClickButton={(data) => {
                  onClickMarkInPlanFlatButton(task.key, data)
                }}
                onClickCancelButton={() => {
                  onClickCancelPlanButton(task.key)
                }}
              />
            </Grid>
          )}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12} md={6}>
            {attachedFiles !== undefined ? (
              <FilesList
                files={attachedFiles}
                user={user}
                fileNameTruncateLength={40}
                onPopoverClick={onFileListPopoverClick}
                onFileClick={onFileClick}
                onRemoveFile={
                  task.status !== TaskStatus.Completed &&
                  onRemoveSelectedFile !== undefined
                    ? (documentKey) => {
                        onRemoveSelectedFile?.(documentKey)
                      }
                    : undefined
                }
              />
            ) : null}
            {task.status !== TaskStatus.Completed && fileUploader}
          </Grid>
          <Grid item xs={12} md={6}>
            {task.trackedMinutes !== undefined ? (
              <Box
                display="flex"
                justifyContent={isMediumOrMore ? 'flex-end' : 'flex-start'}
              >
                <TimeInput
                  label="Time Tracked"
                  minutes={
                    task?.trackedMinutes !== null ? task.trackedMinutes : 0
                  }
                  onChange={
                    onChangeTrackedTime !== undefined &&
                    task.status !== TaskStatus.Completed
                      ? (minutes) => {
                          onChangeTrackedTime(task.key, minutes)
                        }
                      : undefined
                  }
                  trackedTimeError={trackedTimeError}
                />
              </Box>
            ) : null}
          </Grid>
          <Grid container direction={'row'} my={2}>
            {serverError?.map((e) => (
              <Grid item>
                <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                  {e}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Grid item className={styles.doneBox}>
            {task.status !== TaskStatus.Completed &&
            onClickDone !== undefined ? (
              <LoadingButton
                loading={isUpdating}
                variant="contained"
                color="primary"
                data-testid="tasklistitem-done-btn"
                onClick={() => {
                  if (onClickDone !== undefined) {
                    onClickDone(task?.key, TaskStatus.Completed)
                  }
                }}
              >
                {buttonText}
              </LoadingButton>
            ) : null}
          </Grid>
          {task.status === TaskStatus.Completed && (
            <Grid item>
              <div className={styles.taskDone}>
                <CheckCircleOutlineIcon
                  className={styles.doneIcon}
                  color="success"
                  data-testid="tasklistitem-done-icon"
                />
                Done
              </div>
            </Grid>
          )}
        </Grid>
      </Box>
    </ItemBoundingBox>
  )
}

export default TaskListItem
