import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  connectAuthEmulator,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  type AuthError,
  createUserWithEmailAndPassword,
  IdTokenResult,
} from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import log from 'loglevel'
import config from 'config'
import { AuthResponseStatus, type AuthResponse } from './@types/Auth'
import { getDatabase, connectDatabaseEmulator } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'

export interface UserCredentials {
  email: string
  password: string
}

const app = initializeApp(config.firebaseConfig)
export const cloudStorage = getStorage(app)
export const firestoreDb = getFirestore(app)
export const firebaseDb = getDatabase(app)
export const analytics = getAnalytics(app)

const googleAuthProvider = new GoogleAuthProvider()

export const authErrorHandler = (e: AuthError): AuthResponse => {
  let response: AuthResponse
  log.warn('authErrorHandler', e)
  switch (e.code) {
    case 'auth/invalid-email':
      response = {
        status: AuthResponseStatus.invalidEmail,
        message:
          'The provided Gmail account does not seem like a valid email address.',
      }
      break
    case 'auth/wrong-password':
      response = {
        status: AuthResponseStatus.wrongPassword,
        message: 'Wrong password, please try authenticating again.',
      }
      break
    case 'auth/user-not-found':
      response = {
        status: AuthResponseStatus.userNotFound,
        message: 'No such Google user was found.',
      }
      break
    case 'auth/user-token-expired':
      response = {
        status: AuthResponseStatus.userTokenExpired,
        message: 'Your user token has expired, please try signing in again.',
      }
      break
    case 'auth/user-disabled':
      response = {
        status: AuthResponseStatus.userDisabled,
        message: 'This Google account seems to be currently disabled.',
      }
      break
    case 'auth/email-already-exists':
      response = {
        status: AuthResponseStatus.emailAlreadyExists,
        message: 'Your email address appears to be malformed.',
      }
      break
    case 'auth/operation-not-allowed':
      response = {
        status: AuthResponseStatus.operationNotAllowed,
        message: 'Your email address appears to be malformed.',
      }
      break
    case 'auth/popup-blocked':
      response = {
        status: AuthResponseStatus.popUpBlocked,
        message:
          'Please ensure that you allow this site to open popup windows.',
      }
      break
    case 'auth/popup-closed-by-user':
      response = {
        status: AuthResponseStatus.popUpClosedByUser,
        message:
          'You have closed the popup menu without signing in. Please try again.',
      }
      break
    case 'auth/timeout':
      response = {
        status: AuthResponseStatus.authTimeOut,
        message: 'Authentication timeout. Please try again.',
      }
      break
    case 'auth/too-many-requests':
      response = {
        status: AuthResponseStatus.userTokenExpired,
        message: 'Your email address appears to be malformed.',
      }
      break
    case 'auth/invalid-credential':
      response = {
        status: AuthResponseStatus.invalidCredentials,
        message: 'Wrong password.',
      }
      break
    default:
      response = {
        status: AuthResponseStatus.undefined,
        message: 'Your email address appears to be malformed.',
      }
  }
  return response
}

export const auth = getAuth(app)

export const createAccountWithEmailAndPassword = async (
  userCredentials: UserCredentials,
): Promise<AuthResponse> => {
  try {
    await createUserWithEmailAndPassword(
      auth,
      userCredentials.email,
      userCredentials.password,
    )
    return {
      status: AuthResponseStatus.successful,
      message: 'Account created successfully.',
    }
  } catch (error) {
    return authErrorHandler(error as AuthError)
  }
}

export const signInWithGoogle = async (): Promise<AuthResponse> => {
  try {
    const signedInUser = await signInWithPopup(auth, googleAuthProvider)
    log.debug('User signed in', signedInUser)
    return {
      status: AuthResponseStatus.successful,
      message: 'Successful request.',
    }
  } catch (error) {
    log.debug(error)
    return authErrorHandler(error as AuthError)
  }
}

export const signInWithEmailAndPass = async (
  userCredentials: UserCredentials,
): Promise<AuthResponse> => {
  try {
    await signInWithEmailAndPassword(
      auth,
      userCredentials.email,
      userCredentials.password,
    )
    return {
      status: AuthResponseStatus.successful,
      message: 'Successful request.',
    }
  } catch (error) {
    // TODO: enqueueSnackbar to display error and rethrow the error code to the child
    return authErrorHandler(error as AuthError)
  }
}

export const signOut = async (): Promise<void> => {
  try {
    await auth.signOut()
  } catch (error) {
    log.debug(error)
  }
}

export const sendResetPasswordEmail = async (
  email: string,
): Promise<AuthResponse> => {
  try {
    await sendPasswordResetEmail(auth, email)
    return {
      status: AuthResponseStatus.successful,
      message: 'Successful request.',
    }
  } catch (error) {
    // TODO: enqueueSnackbar to display error and rethrow the error code to the child
    return authErrorHandler(error as AuthError)
  }
}

export const refreshUserToken = (): Promise<string | Error> => {
  return new Promise((resolve, reject) => {
    const retVal = auth.currentUser?.getIdToken(true)
    if (retVal != null) {
      resolve(retVal)
    } else {
      reject(new Error('No user token could be resolved'))
    }
  })
}

export const fetchSignInMethods = async (email: string): Promise<string[]> => {
  let response
  try {
    response = await fetchSignInMethodsForEmail(auth, email)
    // TODO check for empty string and return an error, when this happens is because no email is registred
    log.warn('try', response)
  } catch (error) {
    log.warn('catch', error)
    response = error
  }
  return response
}

if (config.firebaseConfig.useEmulator) {
  connectAuthEmulator(auth, 'http://127.0.0.1:9197')
  connectFirestoreEmulator(firestoreDb, '127.0.0.1', 9198)
  connectDatabaseEmulator(firebaseDb, '127.0.0.1', 9196)
}

// @ts-expect-error - Global only in testing scenarios
if (window.Cypress != null) {
  // @ts-expect-error - Global only in testing scenarios
  window.model = {
    signInWithEmailAndPass,
    signOut,
  }
}
