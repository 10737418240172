import * as React from 'react'
import { type FieldProps } from '@rjsf/utils'
import { type FC } from 'react'
import { IMaskInput } from 'react-imask'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/OutlinedInput'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="(#00) 000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } })
        }}
        overwrite
      />
    )
  },
)

const RJSFPhoneField: FC<FieldProps<string>> = ({
  schema,
  hideError,
  readonly,
  classNames,
  id,
  onChange,
  formData,
  required,
}) => {
  const value = formData
  const { title } = schema
  const showErrors = hideError !== undefined && !hideError
  const isRequired = required !== undefined && required
  const showFieldError = showErrors && isRequired && value === ''

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const text = event.target.value
    if (text !== undefined && text.length > 0) {
      onChange(text)
    }
  }

  return (
    <FormControl
      id={id}
      required={required}
      fullWidth
      error={showFieldError ?? false}
      className={classNames}
    >
      <InputLabel htmlFor="formatted-phone-input">{title}</InputLabel>
      <Input
        label={title}
        value={value}
        disabled={readonly}
        error={showFieldError ?? false}
        placeholder="(555) 555-5555"
        onChange={handleTextChange}
        name="textmask"
        id="formatted-phone-input"
        inputComponent={TextMaskCustom as any}
      />
    </FormControl>
  )
}

export default RJSFPhoneField
