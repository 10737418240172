import React from 'react'
import {
  JsonFormOperation,
  type JsonFormType,
} from '../../../@types/generated/graphql'
import {
  type JsonFormRequest,
  type JsonFormData,
  type JsonFormError,
  type IntakeMutationProps,
} from '../../../@types/JsonSchemaForm'
import JsonSchemaForm from '../organisms/JsonSchemaForm'
import { gql } from '../../../@types/generated/gql'
import { useQuery } from '@apollo/client'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

const jsonFormsQuery = gql(/* GraphQL */ `
  query JsonForms(
    $formType: JSONFormType
    $operation: JSONFormOperation!
    $step: Int!
    $currentStepData: JSON
    $nextStepData: JSON
    $intakeDataRef: Key
    $presetData: JSON
    $presetUiSchema: JSON
  ) {
    jsonForms(
      formType: $formType
      operation: $operation
      step: $step
      currentStepData: $currentStepData
      nextStepData: $nextStepData
      intakeDataRef: $intakeDataRef
      presetData: $presetData
      presetUiSchema: $presetUiSchema
    ) {
      code
      success
      message
      form
      step
      totalSteps
      validationErrors {
        code
        fieldErrors {
          name
          errors {
            code
            message
          }
        }
      }
      uiSchema
      defaultData
    }
  }
`)

export interface IntakeFormProps {
  formType?: JsonFormType
  submissionSuccess?: boolean
  submissionErrors?: JsonFormError
  submitting?: boolean
  onSubmit?: (props: IntakeMutationProps) => void
  intakeDataRef?: string
  readonly?: boolean
  presetData?: any
  presetUiSchema?: any
}

const IntakeFormStep = ({
  onSubmit,
  submissionSuccess,
  submissionErrors,
  submitting,
  formType,
  readonly = false,
  intakeDataRef,
  presetData,
  presetUiSchema,
}: IntakeFormProps): JSX.Element => {
  const {
    data: formData,
    loading: formLoading,
    refetch: getFormSchema,
  } = useQuery(jsonFormsQuery, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      formType,
      operation: JsonFormOperation.Generate,
      step: 0,
      intakeDataRef,
      presetData,
      presetUiSchema,
    },
  })
  const getJsonForm = async ({
    step,
    operation,
    currentStepData,
    nextStepData,
  }: JsonFormRequest): Promise<void> => {
    if (formType !== undefined || intakeDataRef !== undefined) {
      await getFormSchema({
        formType,
        step: step ?? 0,
        operation: operation ?? JsonFormOperation.Generate,
        currentStepData,
        nextStepData,
      })
    }
  }

  const submitFormData = (formData): void => {
    if (formType === undefined) return
    onSubmit?.({
      formType,
      formData,
    })
  }

  return submissionSuccess !== undefined && submissionSuccess ? (
    <Alert severity="success">
      <Typography>
        Your legal services has been requested successfully. Redirecting you to
        the legal matter listing ...
      </Typography>
    </Alert>
  ) : (
    <JsonSchemaForm
      form={formData?.jsonForms}
      onFetch={getJsonForm}
      submissionErrors={submissionErrors}
      isSubmitting={submitting}
      isLoading={formLoading}
      onSubmit={(data: JsonFormData) => {
        submitFormData(Object.keys(data).map((obj, idx) => data[idx].formData))
      }}
      readonly={readonly}
    />
  )
}

export default IntakeFormStep
