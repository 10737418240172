import log from 'loglevel'
import { filter } from 'lodash'
import {
  CreateTaskMutation,
  DeleteTaskMutation,
  TaskConnection,
  TaskListItemFragment,
  UpdateTaskChargesMutation,
  UpdateTaskStatusMutation,
  UpdateTaskTrackedTimeMutation,
  TaskUpdateDetailsMutation,
} from '../@types/generated/graphql'
import { gql } from '../@types/generated/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'
import {
  edgesInclude,
  getCacheId,
  makeEmptyData,
  parseAliasedFieldName,
} from './useApolloClient'

const createTaskMutation = gql(`
  mutation createTask(
    $legalMatterKey: Key!
    $name: String!
    $description: String!
    $assignedToKey: Key!
  ) {
    taskCreate(
      legalMatterKey: $legalMatterKey
      name: $name
      description: $description
      assignedToKey: $assignedToKey
    ) {
      ... on TaskMutationResponse {
        code
        success
        message
        task {
          ...TaskListItem
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const deleteTaskMutation = gql(`
  mutation deleteTask($taskKey: Key!, $removeDocuments: Boolean!) {
    taskDelete(taskKey: $taskKey, removeDocuments: $removeDocuments) {
      code
      message
      objectRef
      success
    }
  }
`)

const updateTaskStatusMutation = gql(/* GraphQL */ `
  mutation updateTaskStatus($taskKey: Key!, $status: TaskStatus!) {
    taskStatusUpdate(taskKey: $taskKey, status: $status) {
      ... on TaskMutationResponse {
        code
        message
        success
        task {
          ...TaskListItem
        }
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const updateTaskChargesMutation = gql(/* GraphQL */ `
  mutation updateTaskCharges(
    $taskKey: Key!
    $chargeType: TaskChargeType
    $chargeReason: String
    $chargeAmount: Float
  ) {
    taskUpdateCharging(
      taskKey: $taskKey
      chargeType: $chargeType
      chargeReason: $chargeReason
      chargeAmount: $chargeAmount
    ) {
      ... on TaskMutationResponse {
        code
        message
        success
        task {
          ...TaskListItem
        }
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const updateTaskTrackedTimeMutation = gql(`
  mutation updateTaskTrackedTime($taskKey: Key!, $trackedMinutes: Int!) {
    taskTrackedTimeUpdate(taskKey: $taskKey, trackedMinutes: $trackedMinutes) {
      ... on TaskMutationResponse {
        code
        message
        success
        task {
          ...TaskListItem
        }
      }
      ... on MutationError {
        code
        message
        success
      }
    }
  }
`)

const updateTaskDetailsMutation = gql(`
mutation TaskUpdateDetails($taskKey: Key!, $assignedToKey: Key, $description: String, $name: String) {
  taskUpdateDetails(taskKey: $taskKey, assignedToKey: $assignedToKey, description: $description, name: $name) {
    ... on TaskMutationResponse {
      code
      message
      success
      task {
        ...TaskListItem
      }
    }
    ... on MutationError {
      message
      code
      success
      userErrors {
        code
        fieldErrors {
          name
          errors {
            message
            code
          }
        }
      }
    }
  }
}
`)

interface TasksHook {
  createTask: (
    mutationOpts:
      | MutationFunctionOptions<
          CreateTaskMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CreateTaskMutation>>
  deleteTask: (
    mutationOpts:
      | MutationFunctionOptions<
          DeleteTaskMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<DeleteTaskMutation>>
  updateTaskCharges: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateTaskChargesMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateTaskChargesMutation>>
  updateTaskStatus: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateTaskStatusMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateTaskStatusMutation>>
  updateTaskDetails: (
    mutationOpts:
      | MutationFunctionOptions<
          TaskUpdateDetailsMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<TaskUpdateDetailsMutation>>
  updateTaskTrackedTime: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateTaskTrackedTimeMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateTaskTrackedTimeMutation>>
  taskLoading: boolean
  taskError: any
}

const useTasks = (legalMatterKey: string | undefined): TasksHook => {
  const [createTask, { loading: creatingTask, error: createTaskError }] =
    useMutation<CreateTaskMutation>(createTaskMutation, {
      update(cache, { data }) {
        if (
          legalMatterKey != null &&
          data?.taskCreate?.__typename === 'TaskMutationResponse'
        ) {
          const task = data.taskCreate.task as TaskListItemFragment
          cache.modify({
            id: getCacheId('LegalMatter', legalMatterKey),
            fields: {
              tasks(
                existingTaskConnection = makeEmptyData(),
                { storeFieldName, toReference },
              ) {
                const fieldName = parseAliasedFieldName(storeFieldName)
                const taskRef = toReference(task)
                if (
                  taskRef != null &&
                  Array.isArray(fieldName?.assignedToIn) &&
                  fieldName.assignedToIn?.includes(task.assignedToRef) &&
                  !edgesInclude(existingTaskConnection.edges, taskRef)
                ) {
                  log.info('existing', existingTaskConnection)
                  return {
                    ...existingTaskConnection,
                    edges: [
                      ...existingTaskConnection.edges,
                      { __typename: 'TaskEdge', node: taskRef },
                    ],
                  } as TaskConnection
                } else if (
                  taskRef != null &&
                  Array.isArray(fieldName.assignedToNotIn) &&
                  !fieldName.assignedToNotIn.includes(task.assignedToRef) &&
                  !edgesInclude(existingTaskConnection.edges, taskRef)
                ) {
                  return {
                    ...existingTaskConnection,
                    edges: [
                      ...existingTaskConnection.edges,
                      { __typename: 'TaskEdge', node: taskRef },
                    ],
                  } as TaskConnection
                } else {
                  return existingTaskConnection
                }
              },
            },
          })
        }
      },
    })

  const [deleteTask, { loading: deletingTask, error: deleteTaskError }] =
    useMutation<DeleteTaskMutation>(deleteTaskMutation, {
      update(cache, { data }) {
        if (legalMatterKey != null && data?.taskDelete.success) {
          cache.modify({
            id: getCacheId('LegalMatter', legalMatterKey),
            fields: {
              tasks(existingTaskConnection = {}) {
                return {
                  ...existingTaskConnection,
                  edges: filter(
                    existingTaskConnection.edges,
                    (edge) =>
                      edge.node.__ref !=
                      getCacheId('Task', data.taskDelete.objectRef),
                  ),
                } as TaskConnection
              },
            },
          })
          cache.evict({ id: getCacheId('Task', data.taskDelete.objectRef) })
        }
      },
    })

  const [
    updateTaskCharges,
    { loading: updatingCharges, error: chargesUpdateError },
  ] = useMutation<UpdateTaskChargesMutation>(updateTaskChargesMutation)

  const [
    updateTaskStatus,
    { loading: updatingStatus, error: statusUpdateError },
  ] = useMutation<UpdateTaskStatusMutation>(updateTaskStatusMutation)

  const [
    updateTaskTrackedTime,
    { loading: updatingTimeStatus, error: timeUpdateError },
  ] = useMutation<UpdateTaskTrackedTimeMutation>(updateTaskTrackedTimeMutation)

  const [
    updateTaskDetails,
    { loading: updatingTask, error: updateTaskDetailsError },
  ] = useMutation<TaskUpdateDetailsMutation>(updateTaskDetailsMutation)

  const taskLoading =
    creatingTask ||
    deletingTask ||
    updatingCharges ||
    updatingStatus ||
    updatingTask ||
    updatingTimeStatus
  const taskError =
    createTaskError ??
    deleteTaskError ??
    chargesUpdateError ??
    statusUpdateError ??
    updateTaskDetailsError ??
    timeUpdateError

  return {
    createTask,
    deleteTask,
    updateTaskCharges,
    updateTaskStatus,
    updateTaskTrackedTime,
    updateTaskDetails,
    taskLoading,
    taskError,
  }
}

export default useTasks
