import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

interface LegalMatterRequestActionProps {
  requestMessage?: string
  textButtonOptionA?: string
  textButtonOptionB?: string
  onClickOptionAccept?: () => void
  onClickOptionDecline?: () => void
}

const LegalMatterRequestAction = ({
  requestMessage,
  textButtonOptionA,
  textButtonOptionB,
  onClickOptionAccept,
  onClickOptionDecline,
}: LegalMatterRequestActionProps): JSX.Element => {
  const showMessage = requestMessage !== undefined
  const buttonGridColumns = showMessage ? 6 : 12
  const contentJustification = showMessage ? 'flex-start' : 'center'

  return (
    <>
      <Box
        sx={{
          width: '97%',
          position: 'fixed',
          bottom: 0,
          backgroundColor: 'primary.main',
          padding: 3,
          left: 0,
          zIndex: 2,
        }}
      >
        <Grid container data-testid="legalMatterRequestAction-container">
          {showMessage && (
            <Grid item xs={6}>
              <Typography
                variant="h3"
                sx={{ color: '#fff' }}
                data-testid="legalMatterRequestAction-text"
              >
                {requestMessage}
              </Typography>
            </Grid>
          )}
          {textButtonOptionB !== undefined &&
          textButtonOptionA !== undefined ? (
            <Grid
              item
              xs={buttonGridColumns}
              container
              justifyContent={contentJustification}
            >
              <Button
                disableElevation
                variant="outlined"
                color="secondary"
                data-testid="legalMatterRequestAction-buttonOptionA"
                onClick={onClickOptionAccept}
                sx={{ marginRight: 2 }}
              >
                {textButtonOptionA}
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="error"
                data-testid="legalMatterRequestAction-buttonOptionB"
                onClick={onClickOptionDecline}
              >
                {textButtonOptionB}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </>
  )
}

export default LegalMatterRequestAction
