/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { type FieldProps } from '@rjsf/utils'
import { type FC, type SyntheticEvent } from 'react'
import { gql } from '../../../@types/generated'
import { type Label } from '../../../@types/generated/graphql'
import { useLazyQuery } from '@apollo/client'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'
import FormControl from '@mui/material/FormControl'

export const labelsQuery = gql(/* GraphQL */ `
  query Labels($category: String!) {
    labels(category: $category) {
      key
      category
      value
    }
  }
`)

const RJSFLabelField: FC<FieldProps<Label[]>> = ({
  schema,
  hideError,
  readonly,
  classNames,
  id,
  onChange,
  formData,
  required,
}) => {
  const labels = formData
  const [labelsOpen, setLabelsOpen] = React.useState(false)
  const [getLabels, { loading: labelsLoading, data: labelsData }] =
    useLazyQuery(labelsQuery)

  const { title, category } = schema
  const showErrors = hideError !== undefined && !hideError
  const isRequired = required !== undefined && required
  const showError = showErrors && isRequired && labels?.length === 0

  const labelOptions = labelsData?.labels ?? []

  const handleChange = (
    _event: SyntheticEvent,
    values: Label[] | null,
  ): void => {
    if (values == null) return

    onChange(values)
  }

  return (
    <FormControl
      id={id}
      required={required}
      fullWidth
      error={showError ?? false}
      className={classNames}
    >
      <Autocomplete
        id="label-autocomplete"
        open={labelsOpen}
        disabled={readonly}
        multiple
        disablePortal
        onOpen={() => {
          setLabelsOpen(true)
          void (async () => {
            await getLabels({
              variables: {
                category,
              },
            })
          })()
        }}
        getOptionLabel={(option) => option.value}
        isOptionEqualToValue={(option, value) => option.key === value.key}
        onClose={() => {
          setLabelsOpen(false)
        }}
        onChange={handleChange}
        value={labels ?? []}
        options={labelOptions}
        loading={labelsOpen || labelsLoading}
        clearOnBlur
        handleHomeEndKeys
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            disabled={readonly}
            error={showError ?? false}
            variant="outlined"
            label={title ?? 'Labels'}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {labelsOpen && labelsLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </FormControl>
  )
}

export default RJSFLabelField
