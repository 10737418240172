import Alert from '@mui/material/Alert'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export interface ConfirmationDialogProps {
  title?: JSX.Element
  content: JSX.Element
  actions?: JSX.Element
  open: boolean
  errorMessage?: string
  fullWidth?: boolean
  onClose?: () => void
}

const ConfirmationDialog = ({
  title,
  content,
  actions,
  open,
  errorMessage,
  fullWidth = false,
  onClose,
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      data-testid="confirm-dialog"
      sx={{ m: 0, p: 2, '.MuiPaper-root': { m: 1 } }}
      PaperProps={{ style: { padding: 34 } }}
      fullWidth={fullWidth}
    >
      <DialogTitle
        sx={{ m: 0, p: 0 }}
        id="alert-dialog-title"
        component="div"
        data-testid="confirm-dialog-title"
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mb: 4, p: 0 }}>
        <DialogContentText component="div" data-testid="confirm-dialog-content">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
      {errorMessage !== undefined && (
        <Alert
          severity="error"
          variant="outlined"
          data-testid="dialog-error-message"
        >
          {errorMessage}
        </Alert>
      )}
    </Dialog>
  )
}

export default ConfirmationDialog
