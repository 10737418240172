import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles'
import { type ReactNode } from 'react'

import theme from './theme'

interface Props {
  children: ReactNode
}

export const ThemeRegistry = ({ children }: Props): JSX.Element => {
  return <CssVarsProvider theme={theme}>{children}</CssVarsProvider>
}
