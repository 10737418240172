import { useState } from 'react'
import Collapse from '@mui/material/Collapse'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography, { TypographyProps } from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

interface SelectableListItemProps {
  uuid: string
  title: string
  caption: string
  selected?: boolean
  disabled?: boolean
  expanded?: boolean
  linkItems?: string[]
  chipContent?: string
  disablePadding?: boolean
  onItemClick: (uuid: string) => void
  onLinkItemClick?: (name: string, productId: string) => void
  children?: React.ReactNode
  titleTypographyProps?: TypographyProps
}

const BaseItem = ({
  uuid,
  title,
  caption,
  selected,
  disabled,
  expanded,
  linkItems,
  chipContent,
  disablePadding = false,
  children,
  onItemClick,
  onLinkItemClick,
  titleTypographyProps,
}: SelectableListItemProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(expanded ?? false)

  return (
    <>
      <ListItem
        key={uuid}
        disablePadding={disablePadding}
        secondaryAction={
          children != null && (isExpanded ? <ExpandLess /> : <ExpandMore />)
        }
      >
        <ListItemButton
          onClick={() => {
            if (children != null) {
              setIsExpanded(!isExpanded)
            } else {
              onItemClick(uuid)
            }
          }}
        >
          <ListItemIcon>
            <Checkbox
              disabled={disabled}
              checked={selected}
              onClick={(ev) => {
                onItemClick(uuid)
                ev.stopPropagation()
              }}
              tabIndex={-1}
              disableRipple
              inputProps={{
                'aria-labelledby': uuid,
              }}
              sx={{
                '& .MuiSvgIcon-root': {
                  height: 24,
                  width: 24,
                  color: 'primary.dark',
                },
                '&.Mui-disabled': {
                  '& .MuiSvgIcon-root': {
                    color: 'grey.400',
                  },
                },
              }}
            />
          </ListItemIcon>
          <ListItemText
            id={uuid}
            primary={<Typography {...titleTypographyProps}>{title}</Typography>}
            primaryTypographyProps={{
              component: 'div',
            }}
            secondary={
              <>
                <Grid container>
                  <Grid item sm={6}>
                    <Typography variant="subtitle1" color="primary" mr={2}>
                      {caption}
                    </Typography>
                  </Grid>
                  <Grid item sm={5}>
                    {chipContent !== undefined && (
                      <Chip
                        label={chipContent}
                        size="small"
                        color={'primary'}
                        variant="filled"
                        style={{
                          fontSize: '0.8em',
                          padding: '0.2em 0.5em',
                          fontWeight: 600,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                {linkItems?.map((linkItem) => (
                  <Link
                    key={linkItem}
                    variant="caption"
                    onClick={(ev) => {
                      if (onLinkItemClick != null) {
                        onLinkItemClick(linkItem, uuid)
                      }
                      ev.stopPropagation()
                    }}
                    sx={{ pr: 2 }}
                  >
                    {linkItem}
                  </Link>
                ))}
              </>
            }
            secondaryTypographyProps={{
              component: 'div',
            }}
          />
        </ListItemButton>
      </ListItem>
      {children != null && (
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <List disablePadding style={{ paddingLeft: 30 }}>
            {children}
          </List>
        </Collapse>
      )}
    </>
  )
}

const SelectableListItem = ({
  uuid,
  title,
  caption,
  expanded,
  selected = false,
  disabled = false,
  chipContent,
  linkItems,
  children,
  titleTypographyProps = { variant: 'h5' },
  onItemClick,
  onLinkItemClick,
}: SelectableListItemProps): JSX.Element => {
  // Coallesce empty arrays to null
  const _children =
    Array.isArray(children) && children.length === 0 ? null : children

  return (
    <BaseItem
      uuid={uuid}
      title={title}
      caption={caption}
      selected={selected}
      disabled={disabled}
      expanded={expanded}
      linkItems={linkItems}
      chipContent={chipContent}
      onItemClick={onItemClick}
      onLinkItemClick={onLinkItemClick}
      disablePadding={true}
      titleTypographyProps={titleTypographyProps}
      children={_children}
    />
  )
}

export default SelectableListItem
